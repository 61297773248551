import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var ModelEditVM = function () {
  var self = this;
  if (RazorShared.isDebug) console.log('ModelEditVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.$focusOnReturn = null;
  self.okCallback = function () {};
  self.MergeIDList = [];
  self.SelectedMergeInto = 0;
  self.AddAnother = ko.observable(false);
  self.selectedManufacturerID = ko.observable(null);
  self.SelectedFrequencyBlocks = ko.observableArray([]);
  self.AvailFrequencyBlocks = ko.observableArray([]);
  self.RequestAppUserID = null;
  self.AddEditText = '';

  self.RepeaterPair = ko.observable(false);

  self.FrequencyBlockIDs = ko.pureComputed(function () {
    var selectedBlocksString = '';
    if (self.SelectedFrequencyBlocks() != undefined && self.SelectedFrequencyBlocks().length > 0)
      selectedBlocksString = $.map(self.SelectedFrequencyBlocks(), function (obj) {
        return obj.ID;
      }).join(',');
    return selectedBlocksString;
  });

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;
    if (params.addAnother) self.AddAnother(params.addAnother);
    if (params.requestAppUserID) self.RequestAppUserID = params.requestAppUserID;

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);

    if (params.mergeIDList) self.MergeIDList = params.mergeIDList;

    if (self.MergeMode()) {
      self.ShowMerge();
      self.$container.find('#sectMergeList').show();
    } else {
      self.$container.find('#sectMergeList').hide();
    }

    self.load(ModelEditVM.jsonData);
  };

  self.load = function (jsonData) {
    if (!jsonData || !jsonData.Model) return;

    self.selectedManufacturerID(jsonData.Model.ManufacturerID || 0);

    self.RepeaterPair(jsonData.Model.RepeaterPair || false);

    jsonData.AvailFrequencyBlocks.forEach(function (b) {
      self.AvailFrequencyBlocks().push(b);
    });

    if (jsonData.Model != undefined && jsonData.Model.FrequencyBlockIDs != null && jsonData.Model.FrequencyBlockIDs.length > 0) {
      jsonData.Model.FrequencyBlockIDs.split(',').forEach(function (id) {
        var block = self.AvailFrequencyBlocks().find(function (item) {
          return item.ID == id;
        });
        if (block) self.SelectedFrequencyBlocks().push(block);
      });
    }

    if (!self.MergeMode()) self.AddEditText = jsonData.Model && jsonData.Model.ModelID ? 'Edit Model: ' + jsonData.Model.Name : 'Add Model';

    ko.applyBindings(self, self.$container[0]);

    if (self.selectedManufacturerID()) $('div#divModel').addClass('addAnother');

    if (!self.MergeMode()) self.$container.find('#ManufacturerID').parent().find('button.dropdown-toggle')[0].focus();
  };

  self.MergeMode = function () {
    return $.isArray(self.MergeIDList) && self.MergeIDList.length > 0;
  };

  self.SelectModel = function (modelID, control) {
    if (control) {
      self.$container.find('#divMerge article').removeClass('selected');
      if (control) {
        $(control).addClass('selected');
      }
    }
    self.SelectedMergeInto = modelID;
    self.ShowAddEdit(modelID);
  };

  self.ShowAddEdit = function (modelID) {
    var loadFail = false;
    self.$container
      .find('#sectAddEdit')
      .load(RazorShared.baseUrl + 'EquipmentModel/MergeModelsInto/' + (modelID ? modelID : ''), function (response, status, xhr) {
        if (status == 'error') {
          loadFail = true;
          Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
        } else {
          self.$container.find('#Name').focus();
        }
      });
    if (!loadFail) {
      var v = self.$container.find('#divModel');
      //if (v == null || v == undefined) { console.log('Cannot find addedit div'); }
      self.$container.find('#divModel').attr('display', 'block');
      Common.BlockUI();
    }
  };

  self.LoadMerge = function (jsonData) {
    if (!jsonData || !jsonData.Model) return;

    self.AvailFrequencyBlocks([]);
    jsonData.AvailFrequencyBlocks.forEach(function (b) {
      self.AvailFrequencyBlocks().push(b);
    });

    self.SelectedFrequencyBlocks([]);
    if (jsonData.Model != undefined && jsonData.Model.FrequencyBlockIDs != undefined && jsonData.Model.FrequencyBlockIDs.length > 0) {
      jsonData.Model.FrequencyBlockIDs.split(',').forEach(function (id) {
        var block = self.AvailFrequencyBlocks().find(function (item) {
          return item.ID == id;
        });
        if (block) self.SelectedFrequencyBlocks().push(block);
      });
    }

    ko.applyBindings(self, document.getElementById('divMergeModel'));
    $('#btnModelOK').prop('disabled', false);
    $.unblockUI();
  };

  self.Merge = function () {
    var $mergeModelForm = self.$container.find('#MergeModelForm');
    if (!$mergeModelForm.validate().form()) return;

    if (self.MergeMode()) {
      if (!self.SelectedMergeInto) {
        Common.Dialog('Merge Models', 'Please select a model');
        return;
      }
      Common.DialogConfirmation(
        'Confirmation',
        'Are you sure you want to merge these models into the selected one?',
        null,
        function () {
          self.MergeModels($mergeModelForm);
        },
        null
      );
    }
  };

  self.Save = function () {
    if (!self.$form.validate().form()) return;

    $.post(
      RazorShared.baseUrl + 'EquipmentModel/SaveModel',
      self.$form.serialize() + (self.RequestAppUserID > 0 ? '&requestAppUserID=' + self.RequestAppUserID : ''),
      function (data) {
        if (!data.success) {
          Common.Dialog('Error saving model', null, data.error);
        } else {
          self.$container.modal('hide');
          self.okCallback(data.Model);

          if (self.AddAnother())
            DialogMgr.ModelAdd(self.okCallback, self.selectedManufacturerID(), self.RequestAppUserID > 0 ? self.RequestAppUserID : null, true);
        }
      }
    ).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Model', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.MergeModels = function ($mergeModelForm) {
    var mergeIntoIndex = self.MergeIDList.indexOf(self.SelectedMergeInto.toString());
    if (mergeIntoIndex >= 0) {
      self.MergeIDList.splice(mergeIntoIndex, 1);
    }

    var postdata = $mergeModelForm.serialize() + '&' + $.param({ mergeIDs: self.MergeIDList }, true); // sloppy but it works...
    $.post(RazorShared.baseUrl + 'EquipmentModel/MergeModels', postdata, function (data) {
      if (!data.success) {
        Common.Dialog('Error merging models', null, data.error);
      } else {
        self.$container.modal('hide');
        self.okCallback();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Merge Models', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.AddManufacturer = function (event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.ManufacturerAddEdit(0, self.ManufacturerAdded, self.RequestAppUserID > 0 ? self.RequestAppUserID : null);
  };

  self.ManufacturerAdded = function (manuf) {
    if (manuf && manuf.ManufacturerID > 0) {
      var $fld = self.$form.find('#ManufacturerID');

      if ($fld.find('option[value="' + manuf.ManufacturerID + '"]').length == 0) {
        var newOpt = document.createElement('option');
        $(newOpt).attr('value', manuf.ManufacturerID);
        $(newOpt).text(manuf.Name);
        $fld.append(newOpt);
      }

      $fld.val(manuf.ManufacturerID);

      if ($fld.hasClass('selectpicker')) $fld.selectpicker('render');

      self.$focusOnReturn = self.$form.find('#Name');
    }
  };

  self.AddFrequencyBlock = function (event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.FrequencyBlockAdd(self.FrequencyBlockAdded, 'M', self.$form.find('#Name').val());
  };

  self.FrequencyBlockAdded = function (freqBlk) {
    // okCallback with object { ID, Name }
    if (freqBlk && freqBlk.ID > 0) {
      self.AvailFrequencyBlocks.push(freqBlk);
      self.SelectedFrequencyBlocks.push(freqBlk);

      var $fld = self.$form.find('#FrequencyBlockID');

      if ($fld.hasClass('selectpicker')) $fld.selectpicker('render');

      self.$focusOnReturn = self.$form.find('#SecureFrequencyType').parent().find('button.dropdown-toggle');
    }
  };

  self.ViewFrequencyBlock = function (event) {
    var blockList = self.SelectedFrequencyBlocks().map(function (b) {
      return b.ID;
    });
    if (blockList.length > 0) {
      self.$focusOnReturn = $(event.target);
      DialogMgr.ShowFrequencyListForBlocks(blockList);
    }
  };

  self.returnFocus = function () {
    if (self.$focusOnReturn) self.$focusOnReturn.focus();
    else self.$container.find('.modal-content').focus();
    self.$focusOnReturn = null;
  };

  self.ShowMerge = function () {
    self.$container
      .find('#sectMergeList')
      .load(
        RazorShared.baseUrl + 'EquipmentModel/GetModelMergeList',
        $.param({ modelIDs: self.MergeIDList }, true),
        function (response, status, xhr) {
          if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
        }
      );
  };
};

$(function () {
  $('button[data-dismiss]').click(function () {
    $('.modal-backdrop').remove();
    return;
  });
});

export default ModelEditVM;
