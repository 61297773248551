import Cookies from 'js-cookie';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var CompanyMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 50;
  self.loadedCount = 0;
  self.cookieName = 'efcCompanySearch';
  var searchCookie = Cookies.getJSON(self.cookieName);
  self.CompanySearch = new CompanySearch(searchCookie || jsonData.CompanySearch, self.defaultListCount);
  self.CompanySearchHold = new CompanySearch(searchCookie || jsonData.CompanySearch, self.defaultListCount);

  self.Countries = ko.observableArray(jsonData.Countries || []);
  self.States = ko.observableArray(jsonData.States || []);
  self.Statuses = ko.observableArray(jsonData.CompanyStatuses || []);
  self.SelCompanyIDs = ko.observableArray([]);

  self.SetSort = function (sort) {
    if (self.CompanySearch.SortBy == sort) {
      self.CompanySearch.SortDesc = !self.CompanySearch.SortDesc;
    } else {
      self.CompanySearch.SortBy = sort;
      self.CompanySearch.SortDesc = false;
    }
    self.LoadList();
  };

  self.LoadList = function (callback) {
    $('#loadMessage').show();
    $('#sectCompanyList').load(RazorShared.baseUrl + 'CompanyMgmt/GetCompanyList', ko.toJS(self.CompanySearch), function (response, status, xhr) {
      if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
      else {
        self.loadedCount = $('#numLoaded').val();
        self.SelectMergeCheckbox();
      }
      $('#loadMessage').hide();
    });

    Cookies.set(self.cookieName, ko.toJS(self.CompanySearch), { expires: 30 });
  };

  self.ApplyFilters = function () {
    self.SelCompanyIDs.removeAll();
    var temp = self.CompanySearch;
    self.CompanySearch = new CompanySearch(ko.toJS(self.CompanySearchHold), self.defaultListCount);
    self.CompanySearch.SortBy = temp.SortBy;
    self.CompanySearch.SortDesc = temp.SortDesc;
    self.LoadList();
  };

  self.ListMore = function () {
    if (self.CompanySearch.RetrieveCount > self.loadedCount)
      // already loaded everything
      return;
    self.CompanySearch.RetrieveCount += self.defaultListCount;
    self.LoadList();
  };

  self.ClearSearch = function () {
    self.CompanySearch.ClearSearch(self.defaultListCount);
    self.CompanySearchHold.ClearSearch(self.defaultListCount);
    self.LoadList();
  };

  self.EditCompany = function (companyID, name) {
    var title = 'Edit Company' + (name ? ': ' + name : '');
    DialogMgr.CompanyAddEdit(companyID, title, self.LoadList);
  };

  self.AddCompany = function () {
    var title = 'Add Company';
    DialogMgr.CompanyAddEdit(0, title, self.LoadList);
  };

  self.SelectedCompanysChanged = function (cb) {
    if ($(cb).prop('checked')) self.SelCompanyIDs.push(cb.attributes['data-companyid'].value);
    else self.SelCompanyIDs.remove(cb.attributes['data-companyid'].value);
  };

  self.SelectMergeCheckbox = function () {
    if (self.SelCompanyIDs() && self.SelCompanyIDs().length > 0) {
      var selCompanyCB = $('#divCompanyList').find('input[type=checkbox].merge');

      $('#divCompanyList input.merge').each(function () {
        var cid = $(this)[0].attributes['data-companyid'].value;
        if (jQuery.inArray(cid, self.SelCompanyIDs()) !== -1) $(this).prop('checked', true);
      });
    }
  };

  self.MergeCompanies = function () {
    var title = 'Merge Companies';

    if (self.SelCompanyIDs() && self.SelCompanyIDs().length < 2) {
      Common.Dialog('Merge Companies', 'Please select 2 or more companies to merge');
      return;
    }
    DialogMgr.CompanyAddEdit(0, title, self.MergedComplete, self.SelCompanyIDs());
  };

  self.MergedComplete = function () {
    self.SelCompanyIDs.removeAll();
    self.LoadList();
  };

  self.ActivateCompany = function (companyID) {
    self.SetStatus(companyID, 'ActivateCompany');
  };

  self.DeactivateCompany = function (companyID) {
    self.SetStatus(companyID, 'DeactivateCompany');
  };

  self.SetStatus = function (companyID, statusFunc) {
    $.post(RazorShared.baseUrl + 'CompanyMgmt/' + statusFunc + '/' + companyID, function (data) {
      if (!data.success) {
        Common.Dialog('Error changing status', data.error);
      } else {
        self.LoadList();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Change Status', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.setupSearchToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayToggled';
    var cookie = Cookies.get(toggleCookieName);
    if (cookie) $fld.click();

    $fld.on('displayToggled', function () {
      if ($(this).hasClass('toggled')) Cookies.set(toggleCookieName, 'toggled');
      else Cookies.remove(toggleCookieName);
    });
  };

  self.setCountryChangeTrigger = function ($fld) {
    self.CompanySearchHold.Countries.subscribe(function (newValues) {
      if (newValues.length == 0) $fld.val(null);
      else if (newValues.indexOf(RazorShared.CountryID_USA) >= 0) $fld.val(RazorShared.CountryID_USA);
      else $fld.val(1);

      $fld.change();
    });
  };

  self.UpdateAllSelecListSelectedItemSort = function () {
    Common.ShowSelectedItemsOnTop('#searchCountryID');
    Common.ShowSelectedItemsOnTop('#searchStateCode');
  };
};

var CompanySearch = function (jsonData, defaultCount) {
  var self = this;

  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
  self.RetrieveCount = defaultCount;
  self.RetrieveStartAt = jsonData.RetrieveStartAt || null;

  self.NameLike = ko.observable(jsonData.NameLike || null);
  self.AddressLike = ko.observable(jsonData.AddressLike || null);
  self.CityLike = ko.observable(jsonData.CityLike || null);
  self.Countries = ko.observableArray(jsonData.Countries || []);
  self.States = ko.observableArray(jsonData.States || []);
  self.ZipCodeStart = ko.observable(jsonData.ZipCodeStart || null);
  self.Statuses = ko.observableArray(jsonData.Statuses || [1]);

  self.ClearSearch = function (defaultCount) {
    if (defaultCount == undefined) return;
    self.NameLike(null);
    self.AddressLike(null);
    self.CityLike(null);
    self.Countries([]);
    self.States([]);
    self.ZipCodeStart(null);
    self.Statuses([1]);
    self.RetrieveCount = defaultCount;
    self.RetrieveStartAt = null;
  };
};

export { CompanyMgmtVM, CompanySearch };
