import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var StaticFrequencySetAddEditVM = function (jsonData) {
  var self = this;
  if (RazorShared.isDebug) console.log('StaticFrequencySetAddEditVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};

  self.StaticFrequencySet = ko.observable(null);
  self.AvailAreas = ko.observableArray([]);
  self.AvailLocations = ko.observableArray([]);
  self.SelectedArea = ko.observable(null);
  self.$activityTokenField = $('');
  self.AddFrequency = ko.observable(false);
  self.RemoveFromFutureActivities = ko.observable(false);
  self.selectedActivityIDs = ko.observable();
  self.AddEditText = '';
  self.DuplicatingSet = ko.observable(false);
  self.ShowAlertForCoordination = ko.observable(false);

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) {
      self.okCallback = params.okCallback;
    }

    self.AddEditText = params.clone
      ? 'Duplicate Static Set & Frequencies'
      : StaticFrequencySetAddEditVM.jsonData.StaticFrequencySet.StaticFrequencySetID
      ? 'Edit Static Frequency Set '
      : 'Add Static Frequency Set';

    self.DuplicatingSet(params.clone);
    self.load(StaticFrequencySetAddEditVM.jsonData);
  };

  self.load = function (jsonData) {
    self.AvailAreas((jsonData.AvailAreas || []).sort(Common.StringSortFunc('Name')));
    self.AvailLocations((jsonData.AvailLocations || []).sort(Common.StringSortFunc('Name')));
    self.ShowAlertForCoordination(jsonData.ShowAlertForCoordination);

    self.StaticFrequencySet = new StaticFrequencySetVM(jsonData.StaticFrequencySet);

    if (self.StaticFrequencySet.StaticFrequencySetID == 0 && !self.DuplicatingSet()) self.AddFrequency(true);

    if (self.StaticFrequencySet.Location) self.SelectedArea(self.StaticFrequencySet.Location.AreaID);

    ko.applyBindings(self, self.$container[0]);

    self.SelectedArea.subscribe(function (areaID) {
      self.reloadAvailLocations(areaID);
    });
    self.StaticFrequencySet.LocationID.subscribe(function (locationID) {
      self.validateSetName(locationID);
    });
  };

  self.reloadAvailLocations = function (areaID) {
    if (!areaID) {
      self.StaticFrequencySet.LocationID(null);
      self.AvailLocations.removeAll();
      return;
    }

    $.get(RazorShared.baseUrl + 'StaticFrequency/GetLocationsForArea', { areaID: areaID }, function (data) {
      if (data.success) {
        self.AvailLocations(data.AvailLocations.sort(Common.StringSortFunc('Name')));
        if (self.AvailLocations().length == 1) self.StaticFrequencySet.LocationID(self.AvailLocations()[0].ID);
      } else {
        Common.Dialog('Error finding locations', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Locations', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.validateSetName = function (locationID) {
    if (self.StaticFrequencySet.Name() && self.StaticFrequencySet.Name().length > 1)
      $('#StaticFrequencySetForm').validate().element('input[id="Name"]');
  };

  self.Save = function () {
    var $staticFrequencySetForm = self.$container.find('#StaticFrequencySetForm');
    if (!$staticFrequencySetForm.validate().form()) return;

    var postdata = $staticFrequencySetForm.serialize();

    Common.BlockUI();
    $.post(RazorShared.baseUrl + 'StaticFrequency/SaveStaticFrequencySet', postdata, function (data) {
      if (!data.success) {
        Common.Dialog('Error saving frequency set', null, data.error);
      } else {
        self.$container.modal('hide');
        self.okCallback();

        if (self.AddFrequency()) {
          DialogMgr.StaticFrequencyAdd(data.StaticFrequencySet.StaticFrequencySetID, RazorShared.StaticFrequencySetMgmt.LoadList);
        }
      }
    })
      .fail(function (XMLHttpRequest, textStatus, errorThrown) {
        Common.Dialog('Save Model', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
      })
      .always(function () {
        $.unblockUI();
      });
  };
};

var StaticFrequencySetVM = function (jsonData) {
  var self = this;

  self.StaticFrequencySetID = jsonData.StaticFrequencySetID || 0;
  self.Name = ko.observable(jsonData.Name || '');
  self.LocationID = ko.observable(jsonData.LocationID || 0);
  self.Location = jsonData.Location;
  self.RequestStatusID = jsonData.RequestStatusID;
  self.ItemStatusID = ko.observable(jsonData.ItemStatusID || 0);
  self.AlertForCoordination = ko.observable(jsonData.AlertForCoordination || false);
  self.ActivityIDs = ko.observable((jsonData.ActivityIDs || '').replace(' ', ''));
  self.Activities = jsonData.Activities;
};

export default StaticFrequencySetAddEditVM;
