import { AsYouType } from 'libphonenumber-js';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var UserAccountEditVM = function () {
  var self = this;

  if (RazorShared.isDebug) console.log('UserAccountEditVM locator');

  self.$container = $(document);
  self.$form = $('');

  self.AvailCompanies = ko.observableArray([]);
  self.selectedCompanyID = ko.observable();
  self.SubmitAttempt = ko.observable(false);

  self.NonUSPhone = ko.observable(false);
  self.NonUSMobile = ko.observable(false);

  self.defaultCountryISOCode = ko.observable('US');

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (UserAccountEditVM.companyData) {
      self.AvailCompanies(UserAccountEditVM.companyData.sort(Common.StringSortFunc('Name')) || []);
    }

    self.selectedCompanyID(UserAccountEditVM.jsonData.CompanyID);
    self.NonUSMobile(UserAccountEditVM.jsonData.NonUSMobile);
    self.NonUSPhone(UserAccountEditVM.jsonData.NonUSPhone);

    ko.applyBindings(self, self.$container[0]);

    $('#Mobile').keyup(function () {
      var val_old = $(this).val();
      var countryCode = self.defaultCountryISOCode(); //$("#companyCountryCode").val() || self.defaultCountryISOCode();
      if ((countryCode != self.defaultCountryISOCode() || self.NonUSMobile()) && !val_old.startsWith('+')) {
        val_old = '+' + val_old;
      }
      var newString = new AsYouType(countryCode).input(val_old);
      $(this).focus().val('').val(newString);
    });

    $('#Phone').keyup(function () {
      var val_old = $(this).val();
      var countryCode = self.defaultCountryISOCode(); //$("#companyCountryCode").val() || self.defaultCountryISOCode();
      if ((countryCode != self.defaultCountryISOCode() || self.NonUSPhone()) && !val_old.startsWith('+')) {
        val_old = '+' + val_old;
      }
      var newString = new AsYouType(countryCode).input(val_old);
      $(this).focus().val('').val(newString);
    });
  };

  self.CompanySearch = function (data, event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.CompanySearch(self.CompanySelected, true);
  };

  self.CompanySelected = function (company) {
    if (company) {
      if (
        !self.AvailCompanies().find(function (c) {
          return c.ID == company.CompanyID;
        })
      )
        self.AvailCompanies.push({ ID: company.CompanyID, Name: company.Name });
      self.selectedCompanyID(company.CompanyID);
      $('#Address1').val(company.Address1);
      $('#Address2').val(company.Address2);
      $('#CountryID').val(company.CountryID);
      $('#CountryID').trigger('chosen:updated');
      $('#City').val(company.City);
      $('#StateCode').val(company.StateCode);
      $('#StateCode').trigger('chosen:updated');
      $('#ZipCode').val(company.ZipCode);
    }
    self.$focusOnReturn = $('#AppUserCompanyID');
  };

  self.UpdatePhoneFormat = function (nonUS, val_old) {
    //var countryCode = $("#companyCountryCode").val() || self.defaultCountryISOCode();

    if (!val_old || val_old.length == 0) return;

    let newString = val_old;
    var startsWithPlus = val_old.startsWith('+');
    if (nonUS) {
      if (!startsWithPlus) val_old = '+' + val_old;
      newString = new AsYouType().input(val_old);
    } else {
      if (startsWithPlus) val_old = val_old.substring(1);
      newString = new AsYouType('US').input(val_old);
    }

    return newString;
  };

  self.FormatMobile = function (nonUSMobile) {
    console.log('NonUSMobile: ' + (nonUSMobile ? 'nonUS' : 'US'));

    var currentMobile = $('#Mobile').val();

    if (currentMobile && currentMobile.length > 0) $('#Mobile').val(self.UpdatePhoneFormat(nonUSMobile, currentMobile));
  };

  self.FormatPhone = function (nonUSPhone) {
    console.log('NonUSPhone: ' + (nonUSPhone ? 'nonUS' : 'US'));

    var currentPhone = $('#Phone').val();

    if (currentPhone && currentPhone.length > 0) $('#Phone').val(self.UpdatePhoneFormat(nonUSPhone, currentPhone));
  };
};

export default UserAccountEditVM;
