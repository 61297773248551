import { Common } from '../modules/Common.js';
import DialogMgr from '../modules/DialogMgr.js';
import UserPwdResetVM from '../modules/UserPwdReset.js';

$(function () {
  var showReset = $('#hdnReset').val();
  if (showReset == 1) {
    DialogMgr.LoadModal(RazorShared.baseUrl + 'UserMgmt/ForcePasswordReset/', null, UserPwdResetVM, null);
  }

  $('#Login').click(function () {
    if ($('#Username').val().trim() == '') {
      $('#errorMessage').text('Username is required');
      return false;
    }
    if ($('#Password').val().trim() == '') {
      $('#errorMessage').text('Password is required');
      return false;
    }
  });

  $('#Email').keyup(function (e) {
    if (e.keyCode == 13) $('#FindEmail').trigger('click');
  });

  $('#FindEmail').click(function () {
    //lookup email
    if ($('#Email').val() == '') {
      $('#EmailError').text('Please enter your email address');
      return;
    }

    $.post(RazorShared.baseUrl + 'Login/FindEmail/', { email: $('#Email').val() }, function (data) {
      if (!data.success) {
        $('#EmailError').text(data.error);
      } else {
        if (data.isADAccount) {
          Common.Dialog('Password Reset', data.ADContactMessage);
        } else {
          $('section.active').removeClass('active').addClass('inactive').next().removeClass('inactive').addClass('active');

          switch (data.questionNumber) {
            case 1:
              $('#Question1').text(data.questions);
              $('#divQ1').show();
              $('#Answer1').focus();
              break;
            case 2:
              $('#Question2').text(data.questions);
              $('#divQ2').show();
              $('#Answer2').focus();
              break;
            case 3:
              $('#Question3').text(data.questions);
              $('#divQ3').show();
              $('#Answer3').focus();
              break;
            default:
          }
        }
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $('#EmailError').text(textStatus + '/' + errorThrown);
    });
  });

  $('#ForgotUsername').click(function () {
    if (missingInput()) return false;

    var params = {
      email: $('#Email').val(),
      answer1: $('#Answer1').val(),
      answer2: $('#Answer2').val(),
      answer3: $('#Answer3').val()
    };

    $.post(RazorShared.baseUrl + 'Login/ForgotUsername', params, function (data) {
      if (!data.success) {
        $('#QuestionError').text(data.error);
      } else {
        $('#SuccessMessage').text('An email has been sent to your account with your username');
        $('section.active').removeClass('active').addClass('inactive').next().removeClass('inactive').addClass('active');
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $('#QuestionError').text(textStatus + '/' + errorThrown);
    });
  });

  $('#ResetPassword').click(function () {
    if (missingInput()) return false;

    var params = {
      email: $('#Email').val(),
      answer1: $('#Answer1').val(),
      answer2: $('#Answer2').val(),
      answer3: $('#Answer3').val()
    };

    $.post(RazorShared.baseUrl + 'Login/ResetPassword', params, function (data) {
      if (!data.success) {
        $('#QuestionError').text(data.error);
      } else {
        $('#SuccessMessage').text('An email has been sent to your account with your username and new password');
        $('section.active').removeClass('active').addClass('inactive').next().removeClass('inactive').addClass('active');
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $('#QuestionError').text(textStatus + '/' + errorThrown);
    });
  });
});

function missingInput() {
  if ($('#Email').val() == '') {
    $('#QuestionError').text('Please search for your email first');
    $('#Email').focus();
    return true;
  }

  if (
    ($('#divQ1').is(':visible') && $('#Answer1').val().trim() == '') ||
    ($('#divQ2').is(':visible') && $('#Answer2').val().trim() == '') ||
    ($('#divQ3').is(':visible') && $('#Answer3').val().trim() == '')
  ) {
    $('#QuestionError').text('Please answer security question');
    return true;
  }

  return false;
}
