import * as signalR from '@microsoft/signalr';

var AssignFrequencyNotificationHubVM = function () {
  let self = this;
  const connection = new signalR.HubConnectionBuilder().withUrl('/AssignFrequencyNotification').withAutomaticReconnect().build();

  connection
    .start()
    .then(function () {
      connection.invoke('openAssignFrequencyNotification').catch(function (err) {
        return console.error(err.toString());
      });
    })
    .catch(function (err) {
      return console.error(err.toString());
    });

  connection.onreconnecting(() => {
    console.log('SignalR reconnecting');
  });

  connection.onreconnected(() => {
    console.log('SignalR reconnected');
    RazorShared.AssignFrequencies.Join();
  });

  //Messages from server
  connection.on('notifyOpened', function () {
    console.log('opened');
    RazorShared.AssignFrequencies.Join();
  });

  connection.on('updateAssignmentChange', function (eventID, requestID, equipmentID, frequency) {
    if (typeof RazorShared.AssignFrequencies != 'undefined')
      RazorShared.AssignFrequencies.updateAssignmentChange(eventID, requestID, equipmentID, frequency);
  });

  connection.on('updateAssignmentChanges', function (eventID, requestID, equipmentID, requestStatusID, frequencies) {
    if (typeof RazorShared.AssignFrequencies != 'undefined')
      RazorShared.AssignFrequencies.updateAssignmentChanges(eventID, requestID, equipmentID, requestStatusID, frequencies);
  });

  connection.on('updateRequestClosed', function (eventID, requestID, requestStatusID, frequencies) {
    if (typeof RazorShared.AssignFrequencies != 'undefined')
      RazorShared.AssignFrequencies.updateRequestClosed(eventID, requestID, requestStatusID, frequencies);
  });

  connection.on('updateEquipmentAdded', function (eventID, requestID, equipmentID, requestStatusID, frequencies) {
    if (typeof RazorShared.AssignFrequencies != 'undefined')
      RazorShared.AssignFrequencies.updateEquipmentAdded(eventID, requestID, equipmentID, requestStatusID, frequencies);
  });

  connection.on('spectrumScanUpdated', function (eventID, minFrequency, maxFrequency) {
    if (typeof RazorShared.AssignFrequencies != 'undefined') RazorShared.AssignFrequencies.spectrumScanUpdated(eventID, minFrequency, maxFrequency);
  });

  connection.on('spectrumScanRemovedForLocation', function (eventID) {
    if (typeof RazorShared.AssignFrequencies != 'undefined') RazorShared.AssignFrequencies.spectrumScanRemovedForLocation(eventID);
  });

  connection.on('redirectIfRequestRemoved', function (requestIDs) {
    if (typeof RazorShared.AssignFrequencies != 'undefined') RazorShared.AssignFrequencies.redirectIfRequestRemoved(requestIDs);
  });

  connection.on('updateRequestCheckInStatus', function (requestID, requestStatusID) {
    if (typeof RazorShared.AssignFrequencies != 'undefined') RazorShared.AssignFrequencies.updateRequestCheckInStatus(requestID, requestStatusID);
  });

  //Messages to server
  self.joinGroup = function (eventID) {
    connection.invoke('joinGroup', eventID).catch(function (err) {
      return console.error(err);
    });
  };

  self.updateAssignment = function (eventID, requestID, equipmentID, frequency, notifyGroupOnly) {
    connection.invoke('updateAssignment', eventID, requestID, equipmentID, frequency, notifyGroupOnly).catch(function (err) {
      return console.error(err.toString());
    });
  };

  self.updateAssignments = function (eventID, requestID, equipmentID, requestStatusID, frequencies, notifyGroupOnly) {
    connection.invoke('updateAssignments', eventID, requestID, equipmentID, requestStatusID, frequencies, notifyGroupOnly).catch(function (err) {
      return console.error(err.toString());
    });
  };

  self.updateRequestClosed = function (eventID, requestID, requestStatusID, frequencies) {
    connection.invoke('updateRequestClosed', eventID, requestID, requestStatusID, frequencies).catch(function (err) {
      return console.error(err.toString());
    });
  };

  self.updateEquipmentAdded = function (eventID, requestID, equipmentID, requestStatusID, frequencies) {
    connection.invoke('updateEquipmentAdded', eventID, requestID, equipmentID, requestStatusID, frequencies).catch(function (err) {
      return console.error(err.toString());
    });
  };
};

export default AssignFrequencyNotificationHubVM;
