import moment from 'moment/min/moment-with-locales';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var EventAddEditVM = function () {
  var self = this;

  if (RazorShared.isDebug) console.log('EventAddEditVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};

  self.Event = ko.observable(null);

  self.AvailAreas = ko.observableArray([]);
  self.AvailVenues = ko.observableArray([]);
  self.Statuses = ko.observableArray([]);

  self.SelectedArea = ko.observable(null);
  self.SelectedStatus = ko.observable(null);
  self.AddEditText = '';

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }
    if (params.okCallback) self.okCallback = params.okCallback;

    self.load(EventAddEditVM.jsonData);
  };

  self.EnableAddActivity = ko.pureComputed(function () {
    return self.Event.PrimaryVenueAreaID() > 0 && self.Event.MainEventDate() != null;
  });

  self.load = function (jsonData) {
    self.AddEditText = jsonData.Event && jsonData.Event.EventID ? 'Edit Event: ' + jsonData.Event.Name : 'Add Event';

    self.AvailAreas(jsonData.AvailAreas.sort(Common.StringSortFunc('Name')) || []);
    self.AvailVenues(jsonData.AvailVenues.sort(Common.StringSortFunc('Name')) || []);
    self.Statuses(jsonData.EventStatuses || []);

    self.Event = new EventVM(jsonData.Event);
    self.SelectedArea(self.Event.PrimaryVenueAreaID);

    ko.applyBindings(self, self.$container[0]);

    self.Event.PrimaryVenueAreaID.subscribe(function (areaID) {
      self.reloadAvailVenues(areaID);
    });
  };

  self.reloadAvailVenues = function (areaID) {
    if (!areaID) {
      self.Event.PrimaryVenueID(null);
      self.AvailVenues.removeAll();
      return;
    }

    $.get(RazorShared.baseUrl + 'LocationMgmt/GetVenuesForArea', { areaID: areaID }, function (data) {
      if (data.success) {
        self.AvailVenues(data.AvailVenues.sort(Common.StringSortFunc('Name')));
        if (self.AvailVenues().length == 1) self.Event.PrimaryVenueID(self.AvailVenues()[0].ID);
      } else {
        Common.Dialog('Error finding venues', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Venues', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.AddActivity = function () {
    DialogMgr.AddActivity(
      {
        areaID: self.Event.PrimaryVenueAreaID(),
        eventID: self.Event.EventID(),
        eventDate: self.Event.MainEventDate()
      },
      self.ActivityAdded
    );
  };

  self.EditActivity = function (activity) {
    DialogMgr.EditActivity(self.Event.PrimaryVenueAreaID(), ko.toJS(activity), self.ActivityUpdated);
  };

  self.DuplicateActivity = function (activity) {
    var title = 'Duplicate Activity' + (name ? ': ' + name : '');
    DialogMgr.DuplicateActivity(self.Event.PrimaryVenueAreaID(), title, ko.toJS(activity), self.ActivityAdded);
  };

  self.ActivityAdded = function (newActivity) {
    if (newActivity) {
      var newidx = self.Event.Activities().length;
      self.Event.Activities.push(new ActivityVM(newActivity, newidx));
    }
  };

  self.ActivityUpdated = function (updatedActivity) {
    var currentActivity;
    if (updatedActivity.ActivityID != 0)
      currentActivity = self.Event.Activities().find(function (a) {
        return a.ActivityID == updatedActivity.ActivityID;
      });
    else
      currentActivity = self.Event.Activities().find(function (a) {
        return a._activityTrack == updatedActivity._activityTrack;
      });

    self.Event.Activities.replace(currentActivity, new ActivityVM(updatedActivity));
  };

  self.Save = function () {
    var $eventForm = self.$container.find('#EventForm');
    if (!$eventForm.validate().form()) return;

    $.post(RazorShared.baseUrl + 'LocationMgmt/SaveEvent', ko.toJS(self.Event), function (data) {
      //$eventForm.serialize()
      if (!data.success) {
        Common.Dialog('Error saving event', null, data.error);
      } else {
        if (self.Event.EventID() == 0) {
          self.Event.EventID(data.Model.EventID);
        }
        self.$container.modal('hide');
        self.okCallback();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Model', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

var EventVM = function (jsonData) {
  var self = this;

  if (jsonData) {
    self.EventID = ko.observable(jsonData.EventID);
    self.Name = ko.observable(jsonData.Name);
    self.PrimaryVenueID = ko.observable(jsonData.PrimaryVenueID);
    self.PrimaryVenueAreaID = ko.observable(0);
    if (jsonData.PrimaryVenue != null) self.PrimaryVenueAreaID(jsonData.PrimaryVenue.AreaID || 0);
    self.MainEventDate = ko.observable(jsonData.MainEventDate || null);
    self.EventStatusID = ko.observable(jsonData.EventStatusID);
    self.CoordinatorEmail = ko.observable(jsonData.CoordinatorEmail);
    self.Description = ko.observable(jsonData.Description);
    self.EventInfo = ko.observable(jsonData.EventInfo);
    self.Activities = ko.observableArray([]);
    if (jsonData.Activities)
      self.Activities(
        jsonData.Activities.sort(Common.NumberAscSortFunc('ActivityID')).map(function (a, idx) {
          return new ActivityVM(a, idx);
        })
      );
  }
};

var ActivityVM = function (jsonData, _tracker) {
  var self = this;
  var endDate = null;

  self.ActivityID = jsonData.ActivityID || 0;
  self._activityTrack = _tracker || jsonData._activityTrack || 0;
  self.EventID = jsonData.EventID || 0;
  self.Name = ko.observable(jsonData.Name);
  self.LocationID = ko.observable(jsonData.LocationID);
  self.LocationName = ko.observable(jsonData.LocationName);
  self.ItemStatusID = ko.observable(jsonData.ItemStatusID || 1);
  self.ItemStatusDescription = ko.observable(jsonData.ItemStatusDescription);
  self.StartDateTime = ko.observable(jsonData.StartDateTime || jsonData.EventDate || null);
  if (jsonData.EventDate != undefined) {
    endDate = new Date(jsonData.EventDate);
    endDate.setHours(23);
    endDate.setMinutes(59);
  }
  self.EndDateTime = ko.observable(jsonData.EndDateTime || endDate || null);
  self.AllowPartTime = ko.observable(jsonData.AllowPartTime);
  self.GameDrivenTime = ko.observable(jsonData.GameDrivenTime || false);
  self.StartGameClock = ko.observable(jsonData.StartGameClock);
  self.EndGameClock = ko.observable(jsonData.EndGameClock);
  self.StartQtrID = ko.observable(jsonData.StartQtrID);
  self.EndQtrID = ko.observable(jsonData.EndQtrID);
  self.StaticFrequencySetIDs = ko.observable((jsonData.StaticFrequencySetIDs || '').replace(' ', ''));
  self.StaticFrequencySets = jsonData.StaticFrequencySets;

  self.StartGameTimeDisplay = ko.pureComputed(function () {
    return self.FormatDate(self.StartGameClock(), self.StartQtrID());
  });

  self.EndGameTimeDisplay = ko.pureComputed(function () {
    return self.FormatDate(self.EndGameClock(), self.EndQtrID());
  });

  self.FormatDate = function (clock, gameQtr) {
    if (!clock || !gameQtr) return '';
    var qtr = self.AvailQuarters().find(function (q) {
      return q.ID == gameQtr;
    });
    if (qtr) return qtr.Name + ' ' + moment().startOf('day').add(moment.duration(clock)).format('mm:ss');
    else return 'Not specified';
  };

  self.AvailQuarters = ko.pureComputed(function () {
    return [
      {
        ID: '',
        Name: 'Select'
      },
      {
        ID: 14,
        Name: 'Q1'
      },
      {
        ID: 13,
        Name: 'Q2'
      },
      {
        ID: 12,
        Name: 'Halftime'
      },
      {
        ID: 11,
        Name: 'Q3'
      },
      {
        ID: 10,
        Name: 'Q4'
      },
      {
        ID: 1,
        Name: 'Post'
      }
    ];
  });
};

export { EventAddEditVM, ActivityVM };
