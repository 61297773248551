import Cookies from 'js-cookie';
import { JSPost } from './Common.js';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var AreaMgmtVM = function (jsonData) {
  var self = this;

  self.cookieName = 'efcEventSearch';
  var searchCookie = Cookies.getJSON(self.cookieName);
  self.AreaSearch = new AreaSearchVM(searchCookie || jsonData.AreaSearch);
  self.AreaSearchHold = new AreaSearchVM(searchCookie || jsonData.AreaSearch);

  self.LoadList = function (callback) {
    $('#loadMessage').show();
    $('#sectAreaList').load(RazorShared.baseUrl + 'LocationMgmt/GetAreaList', ko.toJS(self.AreaSearch), function (response, status, xhr) {
      if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
      else self.loadedCount = $('#numLoaded').val();
      $('#loadMessage').hide();
    });
    Cookies.set(self.cookieName, ko.toJS(self.AreaSearch), { expires: 30 });
  };

  self.ApplyFilters = function () {
    self.AreaSearch.NameLike(self.AreaSearchHold.NameLike());
    self.LoadList();
  };

  self.SetSort = function (sort) {
    if (self.AreaSearch.SortBy == sort) {
      self.AreaSearch.SortDesc = !self.AreaSearch.SortDesc;
    } else {
      self.AreaSearch.SortBy = sort;
      self.AreaSearch.SortDesc = false;
    }
    self.LoadList();
  };

  self.ClearSearch = function () {
    self.AreaSearch.NameLike(null);
    self.AreaSearchHold.NameLike(null);
    self.LoadList();
  };

  self.AddArea = function () {
    DialogMgr.AreaAddEdit(0, self.LoadList);
  };

  self.EditArea = function (areaID) {
    DialogMgr.AreaAddEdit(areaID, self.LoadList);
  };
};

var AreaSearchVM = function (jsonData) {
  var self = this;

  self.NameLike = ko.observable(jsonData.NameLike || null);
  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
};

export default AreaMgmtVM;
