import Cookies from 'js-cookie';
import moment from 'moment/min/moment-with-locales';
import { AsYouType } from 'libphonenumber-js';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var UserMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 50;
  self.loadedCount = 0;
  self.cookieName = 'efcUserSearch';
  var searchCookie = Cookies.getJSON(self.cookieName);
  if (searchCookie) searchCookie.RetrieveStartAt = 1;
  self.UserSearch = new UserSearch(searchCookie || jsonData.AppUserSearch, self.defaultListCount);
  self.UserSearchHold = new UserSearch(searchCookie || jsonData.AppUserSearch, self.defaultListCount);

  self.Statuses = ko.observableArray(jsonData.AppUserStatuses || []);
  self.Roles = ko.observableArray(jsonData.AppRoles || []);

  var currentXhr;
  self.LoadList = function () {
    if (self.UserSearch.EndRegDateInValid() || self.UserSearch.StartRegDateInValid()) return;
    $('#loadMessage').show();
    var currentXhr = $.ajax({
      type: 'POST',
      url: RazorShared.baseUrl + 'UserMgmt/GetUserList',
      data: ko.toJS(self.UserSearch),
      success: function (htmlData) {
        let numLoaded = 0;
        if (self.UserSearch.RetrieveStartAt == 1) {
          $('#sectUserList').empty();
          $('#sectUserList').append($(htmlData).filter('div.users'));
          numLoaded = $('#sectUserList').find('#numLoaded').val();
        } else {
          $('#divUserList').append($(htmlData).filter('article'));
          numLoaded = $(htmlData).filter('#numLoaded').val();
        }
        self.loadedCount += Number(numLoaded);

        $('#divUserList').hide();
        $('.mobile').each(function (n) {
          var number = $(this).children('span.mobilenumber');
          if (number) {
            var formattedNumber = self.FormatPhone($(this).hasClass('nonUS'), number.text());
            if (formattedNumber && formattedNumber.length > 0) number.text(formattedNumber);
          }
        });
        $('#divUserList').show();
        $('#loadMessage').hide();
      },
      error: function (xhr, textStatus, errorThrown) {
        Common.Dialog('Search', 'An error has occurred: ' + errorThrown, null, xhr.status);
      }
    });
    Cookies.set(self.cookieName, ko.toJS(self.UserSearch), { expires: 30 });
  };

  var usrSearchLoaded = JSON.parse(Cookies.get("efcUserSearchCollapse") || "{}");   
  self.SearchCollapsed = ko.observable(!usrSearchLoaded.loaded ? true : usrSearchLoaded.collapsed || false);   
  self.SetSearchCollapsed = function (collapsed) {
     self.SearchCollapsed(collapsed);           
     usrSearchLoaded.loaded = true;
     usrSearchLoaded.collapsed = collapsed;
     Cookies.set("efcUserSearchCollapse", JSON.stringify(usrSearchLoaded));
  }

  self.ApplyFilters = function () {
    var temp = self.UserSearch;
    //self.UserSearch = ko.mapping.fromJS(self.UserSearchHold);
    self.UserSearch = new UserSearch(ko.toJS(self.UserSearchHold), self.defaultListCount);
    self.UserSearch.SortBy = temp.SortBy;
    self.UserSearch.SortDesc = temp.SortDesc;
    self.loadedCount = 0;
    self.LoadList();
  };

  self.SetSort = function (sort) {
    if (self.UserSearch.SortBy == sort) {
      self.UserSearch.SortDesc = !self.UserSearch.SortDesc;
    } else {
      self.UserSearch.SortBy = sort;
      self.UserSearch.SortDesc = false;
    }
    self.UserSearch.RetrieveStartAt = 1;
    self.UserSearch.RetrieveCount = self.defaultListCount;
    self.LoadList();
  };

  self.ListMore = function () {
    if (self.UserSearch.RetrieveCount > self.loadedCount)
      // already loaded everything
      return;
    self.UserSearch.RetrieveCount += self.defaultListCount;
    self.UserSearch.RetrieveStartAt += self.defaultListCount;
    self.LoadList();
  };

  self.ClearSearch = function () {
    self.UserSearch.ClearSearch(self.defaultListCount);
    self.UserSearchHold.ClearSearch(self.defaultListCount);
    self.LoadList();
  };

  self.EditUser = function (appUserID, fullName) {
    DialogMgr.UserAddEdit(appUserID, fullName, self.LoadList);
  };

  self.AddUser = function () {
    DialogMgr.UserAddEdit(0, null, self.LoadList);
  };

  self.ActivateUser = function (appUserID) {
    $.post(RazorShared.baseUrl + 'UserMgmt/ActivateUser/' + appUserID, function (data) {
      if (!data.success) {
        Common.Dialog('Error activating user', data.error);
      } else {
        self.LoadList();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Edit User', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.DeactivateUser = function (appUserID) {
    $.post(RazorShared.baseUrl + 'UserMgmt/DeactivateUser/' + appUserID, function (data) {
      if (!data.success) {
        Common.Dialog('Error deactivating user', data.error);
      } else {
        self.LoadList();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Edit User', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.SendLink = function (appUserID) {
    $.post(RazorShared.baseUrl + 'UserMgmt/SendValidationLink/' + appUserID, function (data) {
      if (!data.success) {
        Common.Dialog('Error sending validation link', data.error);
      } else {
        Common.Dialog('Send Validation Link', 'Success');
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Edit User', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.ChangePassword = function (appUserID, fullName) {
    $('#hdnAppUserID').val(appUserID);

    var title = $('#divPassword .modal-dialog').find('.modal-title');
    title.text('Set Password for ' + fullName);

    $('#setNewPassword').val('');
    $('#setConfirmPassword').val('');

    $('#divPassword').modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    $('#divPassword .modal-dialog').draggable({ handle: '.modal-header' });

    setTimeout(function () {
      $('#newPassword').focus();
    }, 500);
  };

  self.SaveChangePassword = function () {
    var appUserID = $('#hdnAppUserID').val();
    var newPassword = $('#setNewPassword').val();
    var confirmPassword = $('#setConfirmPassword').val();
    var resetPassword = $('#resetPassword').is(':checked');

    if (newPassword.trim() == '' || newPassword != confirmPassword) {
      Common.Dialog('Set Password', 'Both fields must be entered and match');
      return;
    }

    if (newPassword.trim().length < 5) {
      Common.Dialog('Set Password', 'Password must be minimum 5 characters long.');
      return;
    }

    if (newPassword.trim().length > 20) {
      Common.Dialog('Set Password', 'Password cannot be more than 20 characters long.');
      return;
    }

    $.post(RazorShared.baseUrl + 'UserMgmt/ChangePassword/' + appUserID, { newPassword: newPassword, resetPassword: resetPassword }, function (data) {
      if (!data.success) {
        Common.Dialog('Error changing password', data.error);
      } else {
        $('#divPassword').modal('hide');
        //Common.Dialog('Change Password', 'Success');
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Edit User', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.setupSearchToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayToggled';
    var cookie = Cookies.get(toggleCookieName);
    if (cookie) $fld.click();

    $fld.on('displayToggled', function () {
      if ($(this).hasClass('toggled')) Cookies.set(toggleCookieName, 'toggled');
      else Cookies.remove(toggleCookieName);
    });
  };

  self.FormatPhone = function (nonUS, val_orig) {
    if (!val_orig || val_orig.length == 0) return;

    let newString = val_orig;
    var startsWithPlus = val_orig.startsWith('+');
    if (nonUS) {
      if (!startsWithPlus) val_orig = '+' + val_orig;
      newString = new AsYouType().input(val_orig);
    } else {
      if (startsWithPlus) val_orig = val_orig.substring(1);
      newString = new AsYouType('US').input(val_orig);
    }

    return newString;
  };
};

var UserSearch = function (jsonData, defaultCount) {
  var self = this;

  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
  self.RetrieveCount = defaultCount;
  self.RetrieveStartAt = jsonData.RetrieveStartAt || 1;

  self.NameLike = ko.observable(jsonData.NameLike || null);
  self.UsernameLike = ko.observable(jsonData.UsernameLike || null);
  self.EmailLike = ko.observable(jsonData.EmailLike || null);
  self.CompanyLike = ko.observable(jsonData.CompanyLike || null);
  self.StartRegDate = ko.observable(jsonData.StartRegDate || null);
  self.EndRegDate = ko.observable(jsonData.EndRegDate || null);
  self.Roles = ko.observableArray(jsonData.Roles || []);
  self.Statuses = ko.observableArray(jsonData.Statuses || [1, 3]);

  var minSqlDate = moment('1753-01-01');
  var maxSqlDate = moment('9999-12-30'); //30 because stored procedure adds a day

  self.StartRegDateInValid = ko.pureComputed(function () {
    return moment(self.StartRegDate()).isBefore(minSqlDate) || moment(self.StartRegDate()).isAfter(maxSqlDate);
  });
  self.EndRegDateInValid = ko.pureComputed(function () {
    return moment(self.EndRegDate()).isBefore(minSqlDate) || moment(self.EndRegDate()).isAfter(maxSqlDate);
  });

  self.ClearSearch = function (defaultCount) {
    if (defaultCount == undefined) return;
    self.NameLike(null);
    self.UsernameLike(null);
    self.EmailLike(null);
    self.CompanyLike(null);
    self.StartRegDate(null);
    self.EndRegDate(null);
    self.Roles([]);
    self.Statuses([1, 3]);
    self.RetrieveCount = defaultCount;
    self.RetrieveStartAt = 1;
  };
};

export default UserMgmtVM;
