import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var WalkInEquipmentMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 50;
  self.loadedCount = 0;

  self.LoadList = function (callback) {
    $('#sectWalkInEquipmentList').load(RazorShared.baseUrl + 'WalkInEquipment/GetWalkInEquipmentList', null, function (response, status, xhr) {
      if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
      else self.loadedCount = $('#numLoaded').val();
    });
  };

  self.EditWalkInEquipment = function (id) {
    DialogMgr.EditWalkInEquipment(id, self.LoadList);
  };
};

export default WalkInEquipmentMgmtVM;
