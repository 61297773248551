import { JSPost } from './Common.js';
import Cookies from 'js-cookie';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var ManufacturerMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 50;
  self.loadedCount = 0;
  self.cookieName = 'efcManufacturerSearch';
  var searchCookie = Cookies.getJSON(self.cookieName);
  self.ManufacturerSearch = new ManufacturerSearch(searchCookie || jsonData.ManufSearch, self.defaultListCount);
  self.ManufacturerSearchHold = new ManufacturerSearch(searchCookie || jsonData.ManufSearch, self.defaultListCount);

  self.Manufacturers = ko.observableArray(jsonData.Manufacturers || []);
  self.Statuses = ko.observableArray(jsonData.Statuses || []);
  self.SelManufIDs = ko.observableArray([]);

  self.SetSort = function (sort) {
    if (self.ManufacturerSearch.SortBy == sort) {
      self.ManufacturerSearch.SortDesc = !self.ManufacturerSearch.SortDesc;
    } else {
      self.ManufacturerSearch.SortBy = sort;
      self.ManufacturerSearch.SortDesc = false;
    }
    self.LoadList();
  };

  self.LoadList = function () {
    $('#loadMessage').show();
    $('#sectManufacturerList').load(
      RazorShared.baseUrl + 'EquipmentModel/GetManufacturerList',
      ko.toJS(self.ManufacturerSearch),
      function (response, status, xhr) {
        if (status == 'error')
          Common.Dialog('Manufacturer Management', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
        else {
          self.loadedCount = $('#numLoaded').val();
          self.SelectMergeCheckbox();
        }
        $('#loadMessage').hide();
      }
    );
    Cookies.set(self.cookieName, ko.toJS(self.ManufacturerSearch), {
      expires: 30
    });
  };

  self.ApplyFilters = function () {
    self.SelManufIDs.removeAll();
    var temp = self.ManufacturerSearch;
    self.ManufacturerSearch = new ManufacturerSearch(ko.toJS(self.ManufacturerSearchHold), self.defaultListCount);
    self.ManufacturerSearch.SortBy = temp.SortBy;
    self.ManufacturerSearch.SortDesc = temp.SortDesc;
    self.LoadList();
  };

  self.ListMore = function () {
    if (self.ManufacturerSearch.RetrieveCount > self.loadedCount)
      // already loaded everything
      return;
    self.ManufacturerSearch.RetrieveCount += self.defaultListCount;
    self.LoadList();
  };

  self.ClearSearch = function () {
    self.ManufacturerSearchHold.ClearSearch(self.defaultListCount);
    self.ManufacturerSearch.ClearSearch(self.defaultListCount);
    self.SelManufIDs.removeAll();
    self.LoadList();
  };

  self.AddManufacturer = function () {
    DialogMgr.ManufacturerAddEdit(0, self.LoadList);
  };

  self.EditManufacturer = function (manufID) {
    DialogMgr.ManufacturerAddEdit(manufID, self.LoadList);
  };

  self.SelManufsChanged = function (cb) {
    if ($(cb).prop('checked')) self.SelManufIDs.push(cb.attributes['data-manufacturerid'].value);
    else self.SelManufIDs.remove(cb.attributes['data-manufacturerid'].value);
  };

  self.SelectMergeCheckbox = function () {
    if (self.SelManufIDs() && self.SelManufIDs().length > 0) {
      var selManufCB = $('#divModelList').find('input[type=checkbox].merge');

      $('#divManufacturerList input.merge').each(function () {
        var cid = $(this)[0].attributes['data-manufacturerid'].value;
        if (jQuery.inArray(cid, self.SelManufIDs()) !== -1) $(this).prop('checked', true);
      });
    }
  };

  self.MergeManufacturers = function () {
    var title = 'Merge Manufacturers';

    if (self.SelManufIDs() && self.SelManufIDs().length < 2) {
      Common.Dialog('Merge Manufacturers', 'Please select 2 or more manufacturers to merge');
      return;
    }
    DialogMgr.MergeManufacturers(title, self.MergedComplete, self.SelManufIDs());
  };

  self.MergedComplete = function () {
    self.SelManufIDs.removeAll();
    self.LoadList();
  };

  self.ActivateManufacturer = function (id) {
    $.post(RazorShared.baseUrl + 'EquipmentModel/ActivateManufacturer/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error activating Manufacturer', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Activate Manufacturer', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.DeactivateManufacturer = function (id) {
    $.post(RazorShared.baseUrl + 'EquipmentModel/DeactivateManufacturer/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error deactivating Manufacturer', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Deactivate Manufacturer', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.ViewFilteredModels = function (id, status) {
    var params = { manufacturerID: id || 0, itemStatus: status || 0 };
    JSPost(RazorShared.baseUrl + 'EquipmentModel/Models/', params);
  };

  self.AddModel = function (id) {
    if (id != null && id != 0) DialogMgr.ModelAdd(self.LoadList, id);
  };

  self.setupSearchToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayToggled';
    var cookie = Cookies.get(toggleCookieName);
    if (cookie) $fld.click();

    $fld.on('displayToggled', function () {
      if ($(this).hasClass('toggled')) Cookies.set(toggleCookieName, 'toggled');
      else Cookies.remove(toggleCookieName);
    });
  };
};

var ManufacturerSearch = function (jsonData, defaultCount) {
  var self = this;

  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
  self.RetrieveCount = defaultCount;
  self.RetrieveStartAt = jsonData.RetrieveStartAt || null;

  self.NameLike = ko.observable(jsonData.NameLike || null);
  self.ManufacturerIDs = ko.observableArray(jsonData.ManufacturerIDs || []);
  self.Statuses = ko.observableArray(jsonData.Statuses || [1, 3]);

  self.ClearSearch = function (defaultCount) {
    if (defaultCount == undefined) return;
    self.NameLike(null);
    self.ManufacturerIDs([]);
    self.Statuses([1, 3]);
    self.RetrieveCount = defaultCount;
    self.RetrieveStartAt = null;
  };
};

export default ManufacturerMgmtVM;
