import moment from 'moment/min/moment-with-locales';
import { Common } from './Common.js';

var AssignToActivitiesVM = function (jsonData) {
  var self = this;
  if (RazorShared.isDebug) console.log('AssignToActivitiesVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};

  self.StaticFrequencySetID = null;
  self.StaticFrequencySetName = null;
  self.AreaName = null;
  self.VenueName = null;
  self.StaticFrequencyCount = null;

  self.AssignedActivities = null;
  self.AllActivitiesForSet = null;
  self.ActivitiesToAssign = ko.observableArray([]);
  self.ActivitiesToRemove = ko.observableArray([]);
  self.ShowPastActivities = ko.observable(false);

  self.SelectAllActivities = ko.observable(false);
  self.ActivityList = ko.pureComputed(function () {
    if (self.ShowPastActivities()) return self.AllActivitiesForSet;
    else {
      return self.AllActivitiesForSet.filter(function (a) {
        return a.PastEvent == false;
      });
    }
  }, self);
  self.UnassignedActivities = ko.pureComputed(function () {
    return self.AllActivitiesForSet.filter(function (a) {
      return $.inArray(a.ActivityID, self.AssignedActivities) == -1 || $.inArray(a.ActivityID, self.ActivitiesToRemove()) >= 0;
    });
  }, self);
  self.anyActivitiesAvailable = ko.pureComputed(function () {
    return self.AllActivitiesForSet.some(function (a) {
      return a.PastEvent == false;
    });
  });

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) {
      self.okCallback = params.okCallback;
    }

    self.load(AssignToActivitiesVM.jsonData);
  };

  self.load = function (jsonData) {
    self.StaticFrequencySetID = jsonData.StaticFrequencySetID;
    self.StaticFrequencySetName = jsonData.StaticFrequencySetName;
    self.AreaName = jsonData.AreaName;
    self.VenueName = jsonData.VenueName;
    self.StaticFrequencyCount = jsonData.StaticFrequencyCount;

    self.AssignedActivities = jsonData.AssignedActivities || [];
    self.AllActivitiesForSet =
      jsonData.AllActivitiesForSet.map(function (x) {
        return new ActivitiesForSetVM(x);
      }) || [];
    self.SetContacts = jsonData.SetContacts || [];
    self.EmailContacts = ko.observableArray([]);

    ko.applyBindings(self, self.$container[0]);

    self.ActivitiesToAssign.subscribe(function (item) {
      self.UndoRemove(item);
    });
  };

  self.toggleShowPastActivities = function () {
    self.ShowPastActivities(!self.ShowPastActivities());
  };

  self.ActivityUnassigned = function (activityID) {
    return $.inArray(activityID, self.AssignedActivities) == -1 || $.inArray(activityID, self.ActivitiesToRemove()) >= 0;
  };

  self.UndoRemove = function (item) {
    item.forEach(function (x) {
      var indexA = self.ActivitiesToAssign.indexOf(x);
      var indexR = self.ActivitiesToRemove.indexOf(x);
      if (indexA !== -1 && indexR !== -1) {
        self.ActivitiesToAssign.splice(indexA, 1);
        self.ActivitiesToRemove.splice(indexR, 1);
      }
    });
  };

  self.selectAllCheck = ko.pureComputed({
    read: function () {
      return self.ActivitiesToAssign().length == self.UnassignedActivities().length;
    },
    write: function (val) {
      if (val) {
        self.ActivitiesToAssign(
          self.UnassignedActivities().map(function (item) {
            return item.ActivityID;
          }) || []
        );
      } else self.ActivitiesToAssign.removeAll();
    },
    owner: self
  });

  self.RemoveRequest = function (activity) {
    if ($.inArray(activity.ActivityID, self.ActivitiesToRemove()) == -1) self.ActivitiesToRemove.push(activity.ActivityID);
  };

  self.Assign = function () {
    if (self.ActivitiesToAssign().length == 0 && self.ActivitiesToRemove().length == 0) {
      Common.Dialog('Select Activity', 'Please select one or more activity to assign static frequency set to.');
      return;
    }

    var params = {
      StaticFrequencySetID: self.StaticFrequencySetID,
      ActivitiesToAssign: self.ActivitiesToAssign(),
      ActivitiesToRemove: self.ActivitiesToRemove(),
      EmailContacts: self.EmailContacts()
    };

    Common.BlockUI();
    $.post(RazorShared.baseUrl + 'Request/CreateRequestsFromStatic', params, function (data) {
      if (data.success) {
        $.unblockUI();
        self.$container.modal('hide');
        self.okCallback();
      } else {
        self.$container.modal('hide');
        $.unblockUI();
        Common.Dialog('Error assigning to Activities', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $.unblockUI();
      Common.Dialog('Error assigning to Activities', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

var ActivitiesForSetVM = function (data) {
  var self = this;

  self.ActivityID = data.ActivityID;
  self.Activity = data;

  self.ActivityStart = null;
  self.ActivityEnd = null;

  self.PastEvent = data.PastEvent;

  self.StartGameTimeDisplay = ko.pureComputed(function () {
    return self.FormatDate(self.Activity.StartGameClock, self.Activity.StartQtrID);
  });

  self.EndGameTimeDisplay = ko.pureComputed(function () {
    return self.FormatDate(self.Activity.EndGameClock, self.Activity.EndQtrID);
  });

  self.FormatDate = function (clock, gameQtr) {
    if (!clock || !gameQtr) return '';
    var qtr = self.AvailQuarters().find(function (q) {
      return q.ID == gameQtr;
    });
    if (qtr) return qtr.Name + ' ' + moment().startOf('day').add(moment.duration(clock)).format('mm:ss');
    else return 'Not specified';
  };

  self.AvailQuarters = ko.pureComputed(function () {
    return [
      {
        ID: '',
        Name: 'Select'
      },
      {
        ID: 14,
        Name: 'Q1'
      },
      {
        ID: 13,
        Name: 'Q2'
      },
      {
        ID: 12,
        Name: 'Halftime'
      },
      {
        ID: 11,
        Name: 'Q3'
      },
      {
        ID: 10,
        Name: 'Q4'
      },
      {
        ID: 1,
        Name: 'Post'
      }
    ];
  });
};

export default AssignToActivitiesVM;
