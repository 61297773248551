import Cookies from 'js-cookie';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var ModelMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 50;
  self.loadedCount = 0;
  self.cookieName = 'efcModelSearch';
  var searchCookie = Cookies.getJSON(self.cookieName);

  self.ModelSearch = new ModelSearch(searchCookie || jsonData.ModelSearch, self.defaultListCount);
  self.ModelSearchHold = new ModelSearch(searchCookie || jsonData.ModelSearch, self.defaultListCount);

  if (jsonData.ModelSearch != null && jsonData.ModelSearch.ManufacturerIDs != null) {
    self.ModelSearch.ManufacturerIDs(jsonData.ModelSearch.ManufacturerIDs);
    self.ModelSearch.Statuses(jsonData.ModelSearch.Statuses || [1, 3]);

    self.ModelSearchHold.ManufacturerIDs(jsonData.ModelSearch.ManufacturerIDs);
    self.ModelSearchHold.Statuses(jsonData.ModelSearch.Statuses || [1, 3]);
  }

  self.Manufacturers = ko.observableArray((jsonData.Manufacturers || []).sort(Common.StringSortFunc('Name')));
  self.Statuses = ko.observableArray(jsonData.Statuses || []);
  self.SecureFrequencyTypes = ko.observableArray(jsonData.SecureFrequencyTypes || []);
  self.SignalTypes = ko.observableArray(jsonData.SignalTypes || []);
  self.SelModelIDs = ko.observableArray([]);

  self.SetSort = function (sort) {
    if (self.ModelSearch.SortBy == sort) {
      self.ModelSearch.SortDesc = !self.ModelSearch.SortDesc;
    } else {
      self.ModelSearch.SortBy = sort;
      self.ModelSearch.SortDesc = false;
    }
    self.LoadList();
  };

  self.LoadList = function () {
    $('#loadMessage').show();
    $('#sectModelList').load(RazorShared.baseUrl + 'EquipmentModel/GetModelList', ko.toJS(self.ModelSearch), function (response, status, xhr) {
      if (status == 'error') Common.Dialog('Model Management', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
      else {
        self.loadedCount = $('#numLoaded').val();
        self.SelectMergeCheckbox();
      }
      $('#loadMessage').hide();
    });
    Cookies.set(self.cookieName, ko.toJS(self.ModelSearch), { expires: 30 });
  };

  self.ApplyFilters = function () {
    self.SelModelIDs.removeAll();
    var temp = self.ModelSearch;
    self.ModelSearch = new ModelSearch(ko.toJS(self.ModelSearchHold), self.defaultListCount);
    self.ModelSearch.SortBy = temp.SortBy;
    self.ModelSearch.SortDesc = temp.SortDesc;
    self.LoadList();
  };

  self.ListMore = function () {
    if (self.ModelSearch.RetrieveCount > self.loadedCount)
      // already loaded everything
      return;
    self.ModelSearch.RetrieveCount += self.defaultListCount;
    self.LoadList();
  };

  self.ClearSearch = function () {
    self.ModelSearch.ClearSearch(self.defaultListCount);
    self.ModelSearchHold.ClearSearch(self.defaultListCount);
    self.SelModelIDs.removeAll();
    self.LoadList();
  };

  self.AddModel = function () {
    var manuf = 0;
    if (self.ModelSearch.ManufacturerIDs().length == 1) manuf = self.ModelSearch.ManufacturerIDs()[0];
    DialogMgr.ModelAdd(self.LoadList, manuf);
  };

  self.EditFreqBlock = function (id) {
    DialogMgr.FrequencyBlockEdit(id, self.LoadList);
  };

  self.SelModelsChanged = function (cb) {
    if ($(cb).prop('checked')) self.SelModelIDs.push(cb.attributes['data-modelid'].value);
    else self.SelModelIDs.remove(cb.attributes['data-modelid'].value);
  };

  self.SelectMergeCheckbox = function () {
    if (self.SelModelIDs() && self.SelModelIDs().length > 0) {
      var selModelCB = $('#divModelList').find('input[type=checkbox].merge');

      $('#divModelList input.merge').each(function () {
        var cid = $(this)[0].attributes['data-modelid'].value;
        if (jQuery.inArray(cid, self.SelModelIDs()) !== -1) $(this).prop('checked', true);
      });
    }
  };

  self.MergeModels = function () {
    if (self.SelModelIDs() && self.SelModelIDs().length < 2) {
      Common.Dialog('Merge Models', 'Please select 2 or more models to merge');
      return;
    }

    $.post(RazorShared.baseUrl + 'EquipmentModel/ValidateModelsForMerge', { mergeIDs: self.SelModelIDs() }, function (data) {
      if (data.DifferentManufacturer) {
        Common.Dialog('Merge Models', 'Cannot merge models from different manufacturers.');
        return;
      } else if (data.AnyMerged) {
        Common.Dialog('Merge Models', 'Selection contains already merged models.');
        return;
      } else {
        DialogMgr.MergeModels(self.MergedComplete, self.SelModelIDs());
      }
    });
  };

  self.MergedComplete = function () {
    self.SelModelIDs.removeAll();
    self.LoadList();
  };

  self.ViewManufacturer = function (manufID, manufName) {
    DialogMgr.ManufacturerAddEdit(manufID, self.LoadList);
  };

  self.EditModel = function (id) {
    DialogMgr.ModelEdit(id, self.LoadList);
  };

  self.ActivateModel = function (id) {
    $.post(RazorShared.baseUrl + 'EquipmentModel/ActivateModel/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error activating Model', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Activate Model', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.DeactivateModel = function (id) {
    $.post(RazorShared.baseUrl + 'EquipmentModel/DeactivateModel/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error deactivating Model', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Deactivate Model', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.CloneModel = function (id) {
    DialogMgr.ModelDuplicate(id, 'Duplicate Model ' + name, self.LoadList);
  };

  self.setupSearchToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayToggled';
    var cookie = Cookies.get(toggleCookieName);
    if (cookie) $fld.click();

    $fld.on('displayToggled', function () {
      if ($(this).hasClass('toggled')) Cookies.set(toggleCookieName, 'toggled');
      else Cookies.remove(toggleCookieName);
    });
  };
};

var ModelSearch = function (jsonData, defaultCount) {
  var self = this;

  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
  self.RetrieveCount = defaultCount;
  self.RetrieveStartAt = jsonData.RetrieveStartAt || null;

  self.NameLike = ko.observable(jsonData.NameLike || null);
  self.ManufacturerIDs = ko.observableArray(jsonData.ManufacturerIDs || []);
  self.Statuses = ko.observableArray(jsonData.Statuses || [1, 3]);
  self.SecureFrequencyTypes = ko.observableArray(jsonData.SecureFrequencyTypes || []);
  self.SignalTypes = ko.observableArray(jsonData.SignalTypes || []);
  self.RepeaterPairsOnly = ko.observable(jsonData.RepeaterPairsOnly || false);
  self.RepeaterSplit = ko.observable(jsonData.RepeaterSplit || null);

  self.ClearSearch = function (defaultCount) {
    if (defaultCount == undefined) return;
    self.NameLike(null);
    self.ManufacturerIDs([]);
    self.Statuses([1, 3]);
    self.SecureFrequencyTypes([]);
    self.SignalTypes([]);
    //self.SignalTypes.removeAll();
    self.RepeaterPairsOnly(false);
    self.RepeaterSplit(null);
    self.RetrieveCount = defaultCount;
    self.RetrieveStartAt = null;
  };
};

export default ModelMgmtVM;
