import 'jquery-ui';
import 'jquery-ui/ui/version';
import 'jquery-ui/ui/data';
import 'jquery-ui/ui/disable-selection';
import 'jquery-ui/ui/focusable';
import 'jquery-ui/ui/form';
import 'jquery-ui/ui/ie';
import 'jquery-ui/ui/keycode';
import 'jquery-ui/ui/labels';
import 'jquery-ui/ui/jquery-patch.js';
import 'jquery-ui/ui/plugin';
import 'jquery-ui/ui/safe-active-element';
import 'jquery-ui/ui/safe-blur';
import 'jquery-ui/ui/scroll-parent';
import 'jquery-ui/ui/tabbable';
import 'jquery-ui/ui/unique-id';
import 'jquery-ui/ui/position';

import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/menu';
import datetimepicker from 'eonasdan-bootstrap-datetimepicker';

$.fn.datetimepicker = datetimepicker;
