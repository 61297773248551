import { AsYouType } from 'libphonenumber-js';
import { defaultCountryCode, updatePhoneFormat } from '../modules/GlobalFunctions.js';

$(function () {
  $('#newCompanySection').hide();

  $('select.securityQuestion').on('change', function () {
    $('.q1 select').valid();
    $('.q2 select').valid();
    $('.q3 select').valid();
  });

  $('select.countryid').on('change', function () {
    if ($('#newCompanySection').is(':visible') && $('select.countryid').val() == $('#countryID_USA').val()) $('#StateCodeRequired').val(true);
    else $('#StateCodeRequired').val(false);

    $('.usa select').valid();
  });

  $('#NonUSMobile').change(function () {
    console.log('in');
    var nonUS = this.checked;
    var origNumber = $('#Mobile').val();
    var formattedNumber = updatePhoneFormat(origNumber, nonUS);
    $('#Mobile').val(formattedNumber);
  });

  $('#NonUSPhone').change(function () {
    console.log('in, phone');
    var nonUS = this.checked;
    var origNumber = $('#Phone').val();
    var formattedNumber = updatePhoneFormat(origNumber, nonUS);
    $('#Phone').val(formattedNumber);
  });

  $('#Mobile').keyup(function () {
    var val_old = $(this).val();
    var nonUS = $('#NonUSMobile')[0].checked;

    var countryCode = nonUS ? '' : defaultCountryCode;
    if (nonUS && !val_old.startsWith('+')) {
      val_old = '+' + val_old;
    }
    var newString = new AsYouType(countryCode).input(val_old);
    $(this).focus().val('').val(newString);
  });

  $('#Phone').keyup(function () {
    var val_old = $(this).val();
    var nonUS = $('#NonUSPhone')[0].checked;

    var countryCode = nonUS ? '' : defaultCountryCode;
    if (nonUS && !val_old.startsWith('+')) {
      val_old = '+' + val_old;
    }
    var newString = new AsYouType(countryCode).input(val_old);
    $(this).focus().val('').val(newString);
  });
});
