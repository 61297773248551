import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';
import { CompanySearch } from './CompanyMgmt.js';

var CompanySearchVM = function () {
  var self = this;
  if (RazorShared.isDebug) console.log('CompanySearchVM locator');

  self.$container = $(document);
  self.ResultCallback = function () {};
  self.returnCompanyObj = false;
  self.CompanySearch = new CompanySearchBoxSearch();

  //self.Countries = ko.observableArray([]);
  self.States = ko.observableArray([]);

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) self.$container = $dlgcontainer;
    if (params.okCallback) self.ResultCallback = params.okCallback;
    if (params.returnCompanyObj) self.returnCompanyObj = params.returnCompanyObj;
    //self.Countries(CompanySearch.jsonData.Countries || []);
    self.States(CompanySearch.jsonData.States || []);

    ko.applyBindings(self, self.$container[0]);
    self.LoadList();
  };

  self.SetSort = function (sort) {
    if (self.CompanySearch.SortBy == sort) {
      self.CompanySearch.SortDesc = !self.CompanySearch.SortDesc;
    } else {
      self.CompanySearch.SortBy = sort;
      self.CompanySearch.SortDesc = false;
    }
    self.LoadList();
  };

  self.LoadList = function () {
    self.$container
      .find('#sectCompanySearchResults')
      .load(RazorShared.baseUrl + 'CompanyMgmt/GetCompaniesFromSearch', ko.toJS(self.CompanySearch), function (response, status, xhr) {
        if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
      });
  };

  self.ClearSearch = function () {
    self.CompanySearch.ClearSearch();
    self.LoadList();
  };

  self.SelectCompany = function (company) {
    if (self.returnCompanyObj) self.ResultCallback(company);
    else self.ResultCallback(company.CompanyID, company.Name);

    if (self.$container !== $(document)) self.$container.modal('hide');
  };

  self.AddCompany = function () {
    DialogMgr.CompanyAddEdit(0, 'Add Company', self.SelectCompany, null, self.returnCompanyObj);
  };
};

var CompanySearchBoxSearch = function () {
  var self = this;

  self.SortBy = 0;
  self.SortDesc = false;

  self.NameLike = ko.observable(null);
  self.AddressLike = ko.observable(null);
  self.CityLike = ko.observable(null);
  //self.Countries = ko.observableArray([]);
  self.States = ko.observableArray([]);
  self.ZipCodeStart = ko.observable(null);
  self.Statuses = [1]; // active only

  self.ClearSearch = function (defaultCount) {
    if (defaultCount == undefined) return;
    self.NameLike(null);
    self.AddressLike(null);
    self.CityLike(null);
    //self.Countries([]);
    self.States([]);
    self.ZipCodeStart(null);
  };
};

export default CompanySearchVM;
