import { JSPost } from './Common.js';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var ManufacturerEditVM = function () {
  var self = this;
  if (RazorShared.isDebug) console.log('ManufacturerEditVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};
  self.AddAnother = ko.observable(false);
  self.RequestAppUserID = null;

  self.SelectedMergeInto = 0;
  self.MergeIDList = [];
  self.AddEditText = '';

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) {
      self.okCallback = params.okCallback;
    }
    if (params.addAnother) self.AddAnother(params.addAnother);
    if (params.requestAppUserID) self.RequestAppUserID = params.requestAppUserID;

    if (params.mergeIDList) self.MergeIDList = params.mergeIDList;

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);

    if (self.MergeMode()) {
      self.ShowMerge();
      self.$container.find('#sectMergeList').show();
    } else {
      self.$container.find('#sectMergeList').hide();
    }

    self.load(ManufacturerEditVM.jsonData);
  };

  self.load = function (jsonData) {
    if (!jsonData) return;

    if (!self.MergeMode())
      self.AddEditText =
        jsonData.Manufacturer && jsonData.Manufacturer.ManufacturerID ? 'Edit Manufacturer: ' + jsonData.Manufacturer.Name : 'Add Manufacturer';

    ko.applyBindings(self, self.$container[0]);
  };

  self.MergeMode = function () {
    return $.isArray(self.MergeIDList) && self.MergeIDList.length > 0;
  };

  self.ShowMerge = function () {
    self.$container
      .find('#sectMergeList')
      .load(
        RazorShared.baseUrl + 'EquipmentModel/GetManufacturerMergeList',
        $.param({ manufacturerIDs: self.MergeIDList }, true),
        function (response, status, xhr) {
          if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
        }
      );
  };

  self.SelectManufacturer = function (manufacturerID, control) {
    if (control) {
      self.$container.find('#divMerge article').removeClass('selected');
      if (control) {
        $(control).addClass('selected');
      }
    }
    self.SelectedMergeInto = manufacturerID;
  };

  self.Save = function () {
    if (!self.$form.validate().form()) return;

    $.post(
      RazorShared.baseUrl + 'EquipmentModel/SaveManufacturer',
      self.$form.serialize() + (self.RequestAppUserID > 0 ? '&requestAppUserID=' + self.RequestAppUserID : ''),
      function (data) {
        if (!data.success) {
          Common.Dialog('Error saving Manufacturer', null, data.error);
        } else {
          self.$container.modal('hide');
          self.okCallback(data.Manufacturer);

          if (self.AddAnother()) DialogMgr.ManufacturerAddEdit(0, self.okCallback, self.RequestAppUserID > 0 ? self.RequestAppUserID : null, true);
        }
      }
    ).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Manufacturer', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.Merge = function () {
    if (!self.SelectedMergeInto) {
      Common.Dialog('Merge Manufacturers', 'Please select a manufacturer');
      return;
    }
    Common.DialogConfirmation(
      'Confirmation',
      'Are you sure you want to merge these manufacturers into the selected one?',
      null,
      function () {
        self.MergeManufacturer();
      },
      null
    );
  };

  self.MergeManufacturer = function () {
    var mergeIntoIndex = self.MergeIDList.indexOf(self.SelectedMergeInto.toString());
    if (mergeIntoIndex >= 0) {
      self.MergeIDList.splice(mergeIntoIndex, 1);
    }

    $.post(
      RazorShared.baseUrl + 'EquipmentModel/MergeManufacturers',
      { mergeIntoID: self.SelectedMergeInto, mergeIDs: self.MergeIDList },
      function (data) {
        if (!data.success) {
          Common.Dialog('Error merging manufacturers', null, data.error);
          return;
        } else {
          self.$container.modal('hide');

          Common.DialogConfirmation(
            'Confirmation',
            'Do you want to merge models for manufacturers merged?',
            null,
            function () {
              var params = { manufacturerID: self.SelectedMergeInto };
              JSPost(RazorShared.baseUrl + 'EquipmentModel/Models/', params);
            },
            function () {
              $('#myModal').modal('hide');
              $('.modal-backdrop').remove();
              self.okCallback();
            }
          );
        }
      }
    ).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Merge Manufacturer', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

export default ManufacturerEditVM;
