import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { Common } from './Common.js';

var defaultCountryCode = 'US';

function submitForm(elem) {
  var $usa = $('.usa');
  var isDisabled = $usa.attr('disabled');
  $usa.attr('disabled', false);

  var selCompanyID = $('#SelectedCompany').val();
  if (!selCompanyID || selCompanyID == '') {
    $('#CompanyID').val(0);
    $('.usa select').valid();
  } else $('#CompanyID').val(selCompanyID);

  var validMobile = true;
  var validPhone = true;
  var nonUSMobile = $('#NonUSMobile')[0].checked;
  var nonUSPhone = $('#NonUSPhone')[0].checked;
  var mobileNum = $('#Mobile').val();
  var phoneNum = $('#Phone').val();

  if (!nonUSMobile && mobileNum && mobileNum.length > 0) {
    var parseMobile = parsePhoneNumberFromString(mobileNum, defaultCountryCode);
    validMobile = parseMobile && parseMobile.isValid();
  }
  if (!validMobile) {
    $('#mobileInvalid').show();
    $('#regMobile').addClass('errorReg');
  } else {
    $('#mobileInvalid').hide();
    $('#regMobile').removeClass('errorReg');
  }

  if (!nonUSPhone && phoneNum && phoneNum.length > 0) {
    var parsePhone = parsePhoneNumberFromString(phoneNum, defaultCountryCode);
    validPhone = parsePhone && parsePhone.isValid();
  }
  if (!validPhone) {
    $('#phoneInvalid').show();
    $('#regLand').addClass('errorReg');
  } else {
    $('#phoneInvalid').hide();
    $('#regLand').removeClass('errorReg');
  }

  if ($('form').validate().form() && validMobile && validPhone) {
    Common.BlockUI('Submitting... Please wait.');
    //elem.form.submit();
  }

  if (isDisabled) $usa.attr('disabled', true);
}

function enterNewCompany() {
  $('#newCompanySection').show();
  $('#selectCompanySection').hide();
  $('#SelectedCompany').val('default').selectpicker('refresh');
}

function selectCompany() {
  $('#newCompanySection').hide();
  $('#selectCompanySection').show();
}

function updatePhoneFormat(val_old, nonUS) {
  if (!val_old || val_old.length == 0) return;

  let newString = val_old;
  var startsWithPlus = val_old.startsWith('+');
  var countryCode = nonUS ? '' : defaultCountryCode;

  if (nonUS) {
    if (!startsWithPlus) val_old = '+' + val_old;
    newString = new AsYouType(countryCode).input(val_old);
  } else {
    if (startsWithPlus) val_old = val_old.substring(1);
    newString = new AsYouType(countryCode).input(val_old);
  }

  return newString;
}

function frequencyReportPDFSetup() {
  $(document).ready(function () {
    if ($('#reportFormat input[type="radio"]:checked').val() == 2) {
      $('.pdfFieldRow').hide();
    } else {
      $('.pdfFieldRow').show();
    }
    if ($('#reportFormat input[type="radio"]:checked').val()) $('#btnPrint').prop('disabled', false);

    $('#reportFormat input[type="radio"]').click(function () {
      if ($('#reportFormat input[type="radio"]:checked').val() == 2) {
        $('.pdfFieldRow').hide();
      } else {
        $('.pdfFieldRow').show();
      }

      if ($('#reportFormat input[type="radio"]:checked').val()) $('#btnPrint').prop('disabled', false);
      else $('#btnPrint').prop('disabled', true);
    });
  });
}

export { defaultCountryCode, submitForm, enterNewCompany, selectCompany, updatePhoneFormat, frequencyReportPDFSetup };
