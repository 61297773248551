import { Common } from './Common.js';

var StaticFrequencyContactAddEditVM = function (jsonData) {
  var self = this;
  if (RazorShared.isDebug) console.log('StaticFrequencyContactAddEditVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};

  self.StaticFrequencyContact = ko.observable(null);

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) {
      self.okCallback = params.okCallback;
    }

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);

    self.load(StaticFrequencyContactAddEditVM.jsonData);
  };

  self.load = function (jsonData) {
    self.StaticFrequencyContact = new StaticFrequencyContactDetailVM(jsonData.StaticFrequencyContact);

    ko.applyBindings(self, self.$container[0]);
  };

  self.SaveContact = function () {
    var $staticFrequencyForm = self.$container.find('#StaticFrequencyContactForm');
    if (!$staticFrequencyForm.validate().form()) return;

    $.post(RazorShared.baseUrl + 'StaticFrequency/SaveStaticFrequencyContact', ko.toJS(self.StaticFrequencyContact), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving frequency', null, data.error);
      } else {
        self.$container.modal('hide');
        self.okCallback(data.Model);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Model', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

var StaticFrequencyContactDetailVM = function (jsonData) {
  var self = this;

  self.StaticFrequencyContactID = jsonData.StaticFrequencyContactID || 0;
  self.AppUserID = ko.observable(jsonData.AppUserID || 0);
  self.CompanyName = ko.observable(jsonData.CompanyName || '');
  self.FirstName = ko.observable(jsonData.FirstName || '');
  self.LastName = ko.observable(jsonData.LastName || '');
  self.Phone = ko.observable(jsonData.Phone || '');
  self.OfficePhone = ko.observable(jsonData.OfficePhone || '');
  self.Email = ko.observable(jsonData.Email || '');
  self.Address1 = ko.observable(jsonData.Address1 || '');
  self.Address2 = ko.observable(jsonData.Address2 || '');
  self.City = ko.observable(jsonData.City || '');
  self.StateCode = ko.observable(jsonData.StateCode || '');
  self.ZipCode = ko.observable(jsonData.ZipCode || '');
  self.ItemStatusID = ko.observable(jsonData.ItemStatusID || 0);
  self.ItemStatus = ko.observable(jsonData.ItemStatus || null);
  self.CreatedBy = ko.observable(jsonData.CreatedBy || 0);
};

export default StaticFrequencyContactAddEditVM;
