/* eslint-disable no-prototype-builtins */
import { RequestNoteVM, RequestNoteListVM } from './RequestViewModels.js';
import Cookies from 'js-cookie';
import { Common, JSPost, EncodeHTML, DecodeHTML } from './Common.js';
import { FrequencyChartVM, FrequencyChartColors } from './FrequencyChart.js';
import DialogMgr from './DialogMgr.js';
import {
  AssignAreaFrequenciesVM,
  EquipmentRequestType,
  AssignEventFrequenciesVM,
  StaticSetAssignment,
  EquipmentAssignment,
  FrequencyDataForGraph,
  CandidateFrequency,
  ContinuityDivider,
  FrequencyDataDetailed,
  AssignFrequenciesConst,
  FrequencyUseConflictType,
  InterModOptions
} from './AssignFrequenciesEntities.js';

//var AssignFrequenciesConst = {
//   EquipPowerGraphFactor: 1.0,
//   TVPowerGraphFactor: 0.1,
//   PendingCountGraphFactor: 10,
//   MinGraphHeight: 15,
//   MaxGraphHeight: 95,
//};

var AssignFrequenciesVM = function (data) {
  var self = this;
  if (RazorShared.isDebug) Common.LogConsole('AssignFrequenciesVM locator');

  self.EquipmentRequestType = data.Type;
  self.CanApplyInterMod = true;
  self.intermodOptionsCookie = null;
  self.ShowingImportScanOptions = ko.observable(false);
  self.ShowingManageScanOptions = ko.observable(false);
  self.ShowSpectrumData = ko.observable(false);
  self.CanShowEventFrequenciesChart = ko.pureComputed(function () {
    return data.Type == EquipmentRequestType.Request;
  });

  self.ShowEventChart = function () {
    if (!self.CanShowEventFrequenciesChart) return;

    var winName = 'EventFrequencies';
    var winURL = RazorShared.baseUrl + 'EventFrequencies/';
    var windowoption = 'resizable=yes,height=500,width=1500,location=0,menubar=0,scrollbars=1';
    var params = { id: self.ScreenVM.EventID };

    var form = document.getElementById('frmEventFrequencies');
    if (form) document.body.removeChild(form);

    form = document.createElement('form');
    form.setAttribute('id', 'frmEventFrequencies');
    form.setAttribute('method', 'post');
    form.setAttribute('action', winURL);
    form.setAttribute('target', winName);

    for (let i in params) {
      if (params.hasOwnProperty(i)) {
        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = i;
        input.id = i;
        input.value = params[i];
        form.appendChild(input);
      }
    }
    document.body.appendChild(form);
    window.open('', winName, windowoption);
    form.target = winName;
    form.submit();
    //document.body.removeChild(form);

    //JSPost(RazorShared.baseUrl + 'EventFrequencies/', { id: self.ScreenVM.EventID }, true);
  };

  switch (data.Type) {
    case EquipmentRequestType.Request:
      self.ScreenVM = new AssignEventFrequenciesVM(data);
      self.ControllerInfo = {
        ControllerName: 'AssignFrequencies',
        PageDataAjaxMethod: 'AssignRequestData',
        CanFilterEquipmentList: false,
        EquipEditFunc: DialogMgr.RequestEquipmentEdit,
        EquipViewFunc: DialogMgr.RequestEquipmentView,
        EquipAddFunc: DialogMgr.RequestEquipmentAdd,
        NoteControllerName: 'Request'
      };
      self.intermodOptionsCookieName = 'efcAssignFrequenciesIntermodOptions';
      self.intermodOptionsCookie = Cookies.getJSON(self.intermodOptionsCookieName);
      break;
    case EquipmentRequestType.WalkIn:
      self.ScreenVM = new AssignEventFrequenciesVM(data);
      self.ControllerInfo = {
        ControllerName: 'AssignWalkIns',
        PageDataAjaxMethod: 'AssignEventWalkInData',
        CanFilterEquipmentList: true,
        EquipEditFunc: DialogMgr.EditWalkInEquipment,
        EquipViewFunc: DialogMgr.EditWalkInEquipment,
        EquipAddFunc: DialogMgr.AddWalkInEquipment
      };
      break;
    case EquipmentRequestType.Static:
      self.ScreenVM = new AssignAreaFrequenciesVM(data);
      self.ControllerInfo = {
        ControllerName: 'AssignStaticFrequencies',
        PageDataAjaxMethod: 'AssignStaticSetData',
        CanFilterEquipmentList: false,
        EquipEditFunc: DialogMgr.StaticFrequencyEdit,
        EquipViewFunc: DialogMgr.StaticFrequencyEdit
      };
      self.intermodOptionsCookieName = 'efcAssignStaticFrequenciesIntermodOptions';
      self.intermodOptionsCookie = Cookies.getJSON(self.intermodOptionsCookieName);
      break;
  }

  self.InterModOptions = new InterModOptions(self.intermodOptionsCookie);
  self.ShowingIntermodWidget = ko.observable(false);
  self.SetIntermodOptionsFromCache = true;
  self.AvailIdsForIntermodCompare = data.AvailIdsForIntermodCompare || [];
  self.EnableIntermod = ko.pureComputed(function () {
    return (
      self.InterModOptions.Show2ndOrder() ||
      self.InterModOptions.Show3rdOrder() ||
      self.InterModOptions.Show5thOrder() ||
      self.InterModOptions.Show7thOrder()
    );
  });

  self.SelectedPageItem = ko.observable(null);
  self.PageItemCount = ko.pureComputed(function () {
    return self.ScreenVM.PageItemIDs.length;
  });
  self.SelectedPageItemID = ko.pureComputed(function () {
    return self.SelectedPageItem() ? self.SelectedPageItem().ItemID : -1;
  });
  self.SelectedPageItemIdx = ko.pureComputed(function () {
    return self.SelectedPageItem() ? self.SelectedPageItem().ItemIdx : -1;
  });
  self.SelectedPageItemNum = ko.pureComputed(function () {
    return self.SelectedPageItemIdx() + 1;
  });
  self.FindPageItemIdx = function (pageItemID) {
    return self.ScreenVM.PageItemIDs.findIndex(function (id) {
      return id == pageItemID;
    });
  };
  self.SelectedPageItemIsCheckedIn = ko.pureComputed(function () {
    return self.SelectedPageItem() ? self.SelectedPageItem().IsCheckedIn() : false;
  });
  self.ShowCheckIn = ko.pureComputed(function () {
    return self.SelectedPageItem() ? self.SelectedPageItem().IsCheckedIn() || self.SelectedPageItem().CanCheckIn() : false;
  });

  self.SelectedEquipment = ko.observable(null);
  self.SelectedEquipmentID = ko.pureComputed(function () {
    return self.SelectedEquipment() ? self.SelectedEquipment().EquipmentID : -1;
  });
  self.FindEquipment = function (equipmentID) {
    if (!self.SelectedPageItem()) return null;
    if (!equipmentID && self.SelectedEquipment()) return self.SelectedEquipment();
    return self
      .SelectedPageItem()
      .Equipments()
      .find(function (eq) {
        return eq.EquipmentID == equipmentID;
      });
  };
  self.FindEquipmentIdx = function (equipmentID) {
    if (!self.SelectedPageItem()) return null;
    return self
      .SelectedPageItem()
      .Equipments()
      .findIndex(function (eq) {
        return eq.EquipmentID == equipmentID;
      });
  };
  self.DefaultPriorityBandwidth = 0;

  self.AllActivities = [];
  self.DisplayedActivities = ko.pureComputed(function () {
    return self.AllActivities.filter(function (a) {
      return a.ShowInList();
    });
  });
  self.SelectedActivities = ko.pureComputed(function () {
    return self.AllActivities.filter(function (a) {
      return a.Selected();
    });
  });
  self.FindActivity = function (activityID) {
    return self.AllActivities.find(function (a) {
      return a.ActivityID == activityID;
    });
  };
  self.DefaultActivityID = ko.observable(-1);

  self.AllStaticSets = [];
  self.DisplayedStaticSets = ko.pureComputed(function () {
    return self.AllStaticSets.filter(function (a) {
      return a.ShowInList();
    });
  });
  self.CurrentStaticSet = ko.pureComputed(function () {
    return self.AllStaticSets.filter(function (a) {
      return a.LocationID > 0 && a.ShowInList() == false;
    });
  });

  self.ImportScanToLocations = ko.observableArray([]);

  self.AvailImportScanLocations = ko.pureComputed(function () {
    if (self.EquipmentRequestType == EquipmentRequestType.Request) {
      var displayedActivities = self.DisplayedActivities() || [];
      //var distinctDisplayedLocations = Array.from(new Set(displayedActivities.map(a => a.LocationID))).map(function (id) { return { ID: id, Name: displayedActivities.find(l => l.LocationID === id).LocationName } });
      var distinctDisplayedLocations = Array.from(
        new Set(
          displayedActivities.map(function (a) {
            return a.LocationID;
          })
        )
      ).map(function (id) {
        return {
          ID: id,
          Name: displayedActivities.find(function (l) {
            return l.LocationID === id;
          }).LocationName
        };
      });
      return distinctDisplayedLocations;
    } else if (self.EquipmentRequestType == EquipmentRequestType.Static) {
      return (self.CurrentStaticSet() || []).map(function (a) {
        return { ID: a.LocationID, Name: a.LocationName };
      });
    }
  });

  self.SelectedScanLocations = ko.observableArray([]);
  self.EnableApplyScanLocations = ko.observable(false);
  self.ScanColors = data.AvailScanColors;
  self.DistinctActivityLocations = ko.pureComputed(function () {
    if (self.EquipmentRequestType == EquipmentRequestType.Request) {
      var displayedActivities = self.DisplayedActivities().sort(Common.StringSortFunc('LocationName')) || [];
      //var distinctDisplayedLocations = Array.from(new Set(displayedActivities.map(a => a.LocationID))).map(function (id, idx) {
      var distinctDisplayedLocations = Array.from(
        new Set(
          displayedActivities.map(function (a) {
            return a.LocationID;
          })
        )
      ).map(function (id, idx) {
        //return { ID: id, Name: displayedActivities.find(l => l.LocationID === id).LocationName, ChartColor: self.ScanColors[idx] }
        return {
          ID: id,
          Name: displayedActivities.find(function (l) {
            return l.LocationID === id;
          }).LocationName,
          ChartColor: self.ScanColors[idx]
        };
      });
      return distinctDisplayedLocations;
    } else if (self.EquipmentRequestType == EquipmentRequestType.Static) {
      return (self.CurrentStaticSet() || []).map(function (a) {
        return { ID: a.LocationID, Name: a.LocationName };
      });
    }
  });

  self.UpdateScanLocations = function (locationid) {
    var idx = self.SelectedScanLocations().indexOf(locationid);
    if (idx >= 0) {
      self.SelectedScanLocations.remove(locationid);
      $('#scanLocationsDropDown')
        .find('[data-loc="' + locationid + '"]')
        .removeClass('active');
    } else {
      self.SelectedScanLocations().push(locationid);
      $('#scanLocationsDropDown')
        .find('[data-loc="' + locationid + '"]')
        .addClass('active');
    }
    self.EnableApplyScanLocations(self.SelectedScanLocations().length > 0);
  };

  self.UpdateImportLocation = function (locationid) {
    var idx = self.ImportScanToLocations().indexOf(locationid);
    if (idx >= 0) {
      self.ImportScanToLocations.remove(locationid);
      $('#importScanDropdown')
        .find('[data-loc="' + locationid + '"]')
        .removeClass('active');
    } else {
      self.ImportScanToLocations.push(locationid);
      $('#importScanDropdown')
        .find('[data-loc="' + locationid + '"]')
        .addClass('active');
    }
  };

  self.ScanData = ko.observableArray([]);
  self.ToggleShowScan = function () {
    self.ShowSpectrumData(!self.ShowSpectrumData());
  };

  self.HasSpectrumData = ko.pureComputed(function () {
    return self.ScanData() && self.ScanData().length > 0;
  });

  self.ShowSpectrumBalloonText = ko.pureComputed(function () {
    if (self.HasSpectrumData()) return 'Click to see spectrum scan data';
    else return 'No spectrum data available for selected frequency range';
  });

  self.ShowSpectrumData.subscribe(function (value) {
    Common.LogConsole('ShowSpectrumData=' + value);
    self.RefreshSpectrum();
  });

  self.RefreshSpectrum = function () {
    if (self.chart) self.chart.saveZoom();
    setTimeout(function () {
      self.RefreshFrequencies(true);
    }, 10); //use timeout to immediately show checkbox change
  };

  self.Frequencies = ko.observableArray([]);

  self.CandidateFrequencies = ko.observable([]);
  self.$candidatemodal = null;
  self.ShowCandidateLink = ko.pureComputed(function () {
    if (!self.SelectedEquipment() || self.EquipmentRequestType != 1) return false;
    else return !self.SelectedEquipment().AssignmentCompleted();
  });

  self.lastAjaxMethod = null;
  self.resetSelectedFreqBlockSegment = function () {
    return self.lastAjaxMethod != 'AssignFrequency' && self.lastAjaxMethod != 'UnassignFrequency' && !self.ShowSpectrumData();
  };

  self.LoadInitial = function () {
    var pageItemID = self.ScreenVM.InitialPageItemID;
    if (pageItemID <= 0 && self.ScreenVM.PageItemIDs && self.ScreenVM.PageItemIDs.length > 0) pageItemID = self.ScreenVM.PageItemIDs[0];
    self.LoadMasterConflicts(self.ScreenVM);

    // Set intermod options from cache for assignment processor - initial page load only
    if (self.SetIntermodOptionsFromCache && self.IntermodOptions) {
      if (self.IntermodOptions.ApplyIntermod())
        // Need these two if statements to be separate
        self.SetProcessInterModOptions();
    }
    self.SetIntermodOptionsFromCache = false;

    self.LoadPageItem(pageItemID, self.ScreenVM.InitialEquipmentID, self.ScreenVM.InitialFilteredEquipmentIDs);
  };

  // "Page Items" are Requests, Walk-In Set for Event, Static Sets
  self.LoadPageItem = function (pageItemID, equipmentID, filteredEquipmentIDs, selectFreqBlock, selectFreqSegment) {
    self.ScanData([]);
    $('#loadingFrequencies').hide();

    var pageItemIdx = self.FindPageItemIdx(pageItemID);

    if (pageItemIdx < 0) {
      Common.Dialog('Error retrieving data', 'Cannot identify requested item.');
      return;
    }

    if (self.chart) self.chart.saveZoom();

    var params = {};
    if (self.ControllerInfo.CanFilterEquipmentList && filteredEquipmentIDs && filteredEquipmentIDs.length > 0)
      params.filteredEquipIDs = filteredEquipmentIDs;

    Common.BlockUI();
    $.get(
      RazorShared.baseUrl + self.ControllerInfo.ControllerName + '/' + self.ControllerInfo.PageDataAjaxMethod + '/' + pageItemID,
      params,
      function (data) {
        $.unblockUI();
        if (data.success) self.PageItemLoaded(data.pagedata, pageItemIdx, equipmentID || 0, selectFreqBlock, selectFreqSegment);
        else Common.Dialog('Error retrieving data', null, data.error);
      }
    ).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $.unblockUI();
      Common.Dialog('Error retrieving data', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.PageItemLoaded = function (pagedata, pageItemIdx, equipmentID, selectFreqBlock, selectFreqSegment) {
    var pageVM = self.ScreenVM.GetPageVM(pagedata, pageItemIdx);

    self.SelectedEquipment(null); // hide selection while loading
    self.SelectedPageItem(pageVM);
    self.SetupPageItemConflicts(pageVM);

    self.GetRemainingPageData();

    self.ClearFrequencies();
    self.SelectEquipment(equipmentID || 0);
    if (self.NoteListContainer) {
      self.NoteListContainer.LoadNotes(pageVM);
      self.NoteListContainer.UpdateNotesEquipmentID(self.SelectedEquipmentID());
    }
    if (selectFreqBlock && selectFreqBlock > 0) {
      if (selectFreqSegment && selectFreqSegment > 0) {
        self.AssignFrequencyBlockSelector.SegmentSelectedAfterReset = selectFreqSegment;
        self.AssignFrequencyBlockSelector.SegmentListReset(true);
      }
      self.AssignFrequencyBlockSelector.SelectedFrequencyBlock(selectFreqBlock);
    }
  };

  self.GetRemainingPageData = function () {
    var ajaxParams = {};
    ajaxParams.id = self.SelectedPageItemID();
    var equipIDs = (self.SelectedPageItem().Equipments() || [])
      .filter(function (e) {
        return !e.IsProcessed;
      })
      .map(function (e) {
        return e.EquipmentID;
      });

    if (!equipIDs || equipIDs.length == 0) return;

    self.SelectedPageItem().DynamicEquipLoadedCount == 0;
    $('#loadingFrequencies').show();

    for (let i = 0; i < equipIDs.length; i++) {
      ajaxParams.equipIDs = equipIDs[i];

      $.post(RazorShared.baseUrl + self.ControllerInfo.ControllerName + '/GetRemainingEquipData/', ajaxParams, function (data) {
        if (data.success) {
          if (
            (self.ScreenVM.Type == EquipmentRequestType.Request && self.SelectedPageItem().RequestID == ajaxParams.id) ||
            (self.ScreenVM.Type == EquipmentRequestType.Static && self.SelectedPageItem().StaticFrequencySetID == ajaxParams.id)
          ) {
            self.SelectedPageItem().UpdateEquipmentData(data.updatedEquipData, equipIDs.length);
          }
        } else Common.Dialog('Error retrieving data', null, data.error);
      }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
        Common.Dialog('Error retrieving data', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
      });
    }
  };

  self.UpdateRequestStatus = function (pageItemID, requestStatusID) {
    if (self.SelectedPageItemID() == pageItemID) self.SelectedPageItem().RequestStatusID(requestStatusID);
  };

  $('#keyTrigger').on('click', () => {
    if ($('.freq.freqRow').hasClass('active')) {
      return;
    }
    setTimeout(() => {
      $('#keyTrigger').attr('data-balloon', null);
         $('.freq.freqRow').attr('style', `top: ${$('#keyTrigger').offset().top + $('#keyTrigger').height() + 2}px`).addClass('active');
    });
  });

  $('body.assignFrequency').on('click', function (e) {
    $('.freq.freqRow').removeClass('active');
    $('#keyTrigger').attr('data-balloon', 'Frequency Key');
  });

  self.CheckedInChanged = function () {
    var isCheckedIn = self.SelectedPageItemIsCheckedIn();
    if (isCheckedIn) self.UncheckinRequest(self.SelectedPageItemID());
    else self.CheckinRequest(self.SelectedPageItemID());
  };

  self.CheckinRequest = function (requestID) {
    $.post(RazorShared.baseUrl + 'Request/CheckinRequest/' + requestID, function (data) {
      if (data.success) {
        self.UpdateRequestStatus(requestID, data.requestStatusId);
      } //self.LoadList();
      else Common.Dialog('Error Checking-In Request', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Checking-In Request', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.UncheckinRequest = function (requestID) {
    $.post(RazorShared.baseUrl + 'Request/UncheckinRequest/' + requestID, function (data) {
      if (data.success) {
        self.UpdateRequestStatus(requestID, data.requestStatusId);
      } else Common.Dialog('Error Unchecking-In Request', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Unchecking-In Request', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.PreviousPage = function () {
    if (self.SelectedPageItemIdx() > 0) {
      sessionStorage.setItem('currentIdx', self.SelectedPageItemIdx() - 1);
      self.LoadPageItem(self.ScreenVM.PageItemIDs[self.SelectedPageItemIdx() - 1]);
    }
  };

  self.NextPage = function () {
    if (self.SelectedPageItemIdx() < self.PageItemCount() - 1) {
      sessionStorage.setItem('currentIdx', self.SelectedPageItemIdx() + 1);
      self.LoadPageItem(self.ScreenVM.PageItemIDs[self.SelectedPageItemIdx() + 1]);
    }
  };

  // Request Notes
  if (self.ControllerInfo.NoteControllerName) {
    self.NoteListContainer = new NoteListContainer(self.ControllerInfo.NoteControllerName);
  } else {
    self.NoteListContainer = null;
  }

  // Conflict Items -- Activities or StaticSets
  if (self.EquipmentRequestType == EquipmentRequestType.Request || self.EquipmentRequestType == EquipmentRequestType.WalkIn) {
    self.LoadMasterConflicts = function (screenVM) {
      var currBitFlag = 1;
      self.AllActivities = [];

      if (!screenVM || !screenVM.Activities) return;

      screenVM.Activities.forEach(function (s) {
        s.BitFlag = currBitFlag;
        self.AllActivities.push(s);
        currBitFlag <<= 1;
      });
    };

    self.SetupPageItemConflicts = function (pageVM) {}; // nothing for now...

    self.SetupEquipmentItemConflicts = function (equip) {
      var reqList = (equip && equip.RequestedActivityIDs) || [];

      self.AllActivities.forEach(function (a) {
        a.ShowInList(
          reqList.findIndex(function (id) {
            return a.ActivityID == id;
          }) >= 0
        );
        a.Selected(a.ShowInList());
      });
    };

    self.FutureEvents = ko.observable(false);

    self.MakeSelectionMask = function (conflictIDs) {
      var bitmask = 0;
      if (conflictIDs) {
        conflictIDs.forEach(function (id) {
          var act = self.AllActivities.find(function (a) {
            return a.ActivityID == id;
          });
          if (act) bitmask += act.BitFlag;
        });
      }
      return bitmask;
    };

    self.SelectionMask = ko.pureComputed(function () {
      var mask = 0;
      self.SelectedActivities().forEach(function (a) {
        mask += a.BitFlag;
      });
      return mask;
    });
  } else if (self.EquipmentRequestType == EquipmentRequestType.Static) {
    self.LoadMasterConflicts = function (screenVM) {
      var currBitFlag = 1;
      self.AllStaticSets = [];

      var futureEventsSet = new StaticSetAssignment({
        StaticFrequencySetID: -1,
        Name: 'Future Events at Location'
      });
      futureEventsSet.BitFlag = currBitFlag;
      self.AllStaticSets.push(futureEventsSet);
      self._futureEventSet(futureEventsSet);
      currBitFlag <<= 1;

      if (!screenVM || !screenVM.StaticFrequencySets) return;

      screenVM.StaticFrequencySets.forEach(function (s) {
        s.BitFlag = currBitFlag;
        self.AllStaticSets.push(s);
        currBitFlag <<= 1;
      });
    };

    self.SetupPageItemConflicts = function (pageVM) {
      self.AllStaticSets.forEach(function (s) {
        if (s.StaticFrequencySetID > 0) {
          s.ShowInList(s.StaticFrequencySetID != pageVM.StaticFrequencySetID);
          s.Selected(true);
        }
      });
    };

    self.SetupEquipmentItemConflicts = function (equip) {}; // nothing for now...

    self._futureEventSet = ko.observable(null);
    self.FutureEvents = ko.pureComputed({
      read: function () {
        return self._futureEventSet() && self._futureEventSet().Selected();
      },
      write: function (value) {
        if (self._futureEventSet()) self._futureEventSet().Selected(value);
      }
    });

    self.MakeSelectionMask = function (conflictIDs) {
      var bitmask = 0;
      if (conflictIDs) {
        conflictIDs.forEach(function (id) {
          var set = self.AllStaticSets.find(function (s) {
            return s.StaticFrequencySetID == id;
          });
          if (set) bitmask += set.BitFlag;
        });
      }
      return bitmask;
    };

    self.SelectionMask = ko.pureComputed(function () {
      var mask = 0;
      self.AllStaticSets.forEach(function (a) {
        if (a.Selected()) mask += a.BitFlag;
      });
      return mask;
    });
  } else {
    self.LoadMasterConflicts = function (screenVM) {};
    self.SetupPageItemConflicts = function (pageVM) {};
    self.SetupEquipmentItemConflicts = function (equip) {};
    self.FutureEvents = ko.observable(false);
    self.MakeSelectionMask = function (conflictIDs) {
      return 0;
    };
    self.SelectionMask = ko.pureComputed(function () {
      return 0;
    });
  }

  // Equipment
  self.SelectEquipment = function (equipmentID, selectedCallback, callbackParam2, callbackParam3) {
    Common.LogConsole('SelectEquipment');

    if (self.chart) self.chart.saveZoom();

    if (equipmentID > 0 && self.SelectedEquipmentID() == equipmentID) {
      // don't reprocess unnecessarily
      if (typeof selectedCallback === 'function') selectedCallback(equipmentID, callbackParam2, callbackParam3);
      return;
    }

    if (self.SelectedPageItem().Equipments().length == 0) {
      self.SelectedEquipment(null);
      return;
    }

    var equip;
    if (equipmentID > 0) {
      equip = self.FindEquipment(equipmentID);
    } else if (self.SelectedPageItem() && self.SelectedPageItem().Equipments().length > 0) {
      equip = self.SelectedPageItem().Equipments()[0];
    }

    if (!equip) {
      Common.Dialog('Error retrieving data', 'Cannot identify equipment');
      return;
    }

    self.SelectedEquipment(equip);
    self.SetupEquipmentItemConflicts(equip);

    self.AssignFrequencyBlockSelector.LoadBlocksForEquipment(equip, self.resetSelectedFreqBlockSegment());
    self.LoadEquipmentFrequencyData(equip, selectedCallback, callbackParam2, callbackParam3);

    if (self.NoteListContainer) self.NoteListContainer.UpdateNotesEquipmentID(equip.EquipmentID);

    var dropDownFlag;

    //DJM 7.16.19 for EFC-1263
    //Changed ID to class instead, references to "this" instead of ID, just to make it more generic
    //Also remediated EFC-1263 by removing 'position:fixed' from css class and adding it into JS style bindings here.
    $('.detailsDrop').on('show.bs.dropdown', function () {
      //if ($(document).height() <= 680 && !dropDownFlag) {
      if ($('.table.equipment').height() <= 300 && !dropDownFlag) {
        var offset = $(this).offset();
        $(this).css({
          top: offset.top + 'px',
          position: 'fixed',
          'z-index': '1000'
        });
        $('#tablescreen').css('display', 'block');
        dropDownFlag = true;
      }
    });
    $('.detailsDrop').on('hide.bs.dropdown', function () {
      if (dropDownFlag) {
        var offset = $(this).offset();
        $(this).css({ top: '', position: '', 'z-index': '' });
        $('#tablescreen').css('display', 'none');
        dropDownFlag = false;
      }
    });

    if (typeof selectedCallback === 'function') {
      selectedCallback(callbackParam2);
      return;
    }
  };

  self.EquipmentReceived = function (equipData) {
    var idx = self.FindEquipmentIdx(equipData.EquipmentID);

    if (idx >= 0) {
      self.SelectedPageItem().Equipments.splice(idx, 1, new EquipmentAssignment(equipData, idx));
    } else if (equipData.ParentID == self.SelectedPageItemID()) {
      idx = self.SelectedPageItem().Equipments().length;
      self.SelectedPageItem().Equipments.push(new EquipmentAssignment(equipData, idx));
    }

    if (equipData.EquipmentID == self.SelectedEquipmentID()) {
      self.SelectedEquipment(null);
      self.SelectEquipment(equipData.EquipmentID); //make sure we have updated version
    }
  };

  self.OpenCandidate = function () {
    if (self.$candidatemodal) {
      self.$candidatemodal.modal('show');
    } else {
      self.$candidatemodal = $('#divCandidate');
      self.$candidatemodal.modal({
        backdrop: 'static',
        keyboard: false,
        show: true
      });
      Common.ConfigDialog(self.$candidatemodal);
    }
  };

  self.CloseCandidate = function () {
    self.CandidateFrequencies().forEach(function (c) {
      c.FrequencyNote('');
      c.FrequencyNoteEdit(false);
      c.FrequencyNoteEditDone(true);
      c.isSelected(false);
    });

    if (self.$candidatemodal) {
      self.$candidatemodal.modal('hide');
    }
  };

  self.AssignCandidates = function () {
    var frequenciesToAssign = [];

    var equip = self.SelectedEquipment();
    if (!equip || equip.AssignmentCompleted()) return;

    var activityIDs = (self.SelectedActivities() || []).map(function (a) {
      return a.ActivityID;
    });

    self
      .CandidateFrequencies()
      .filter(function (f) {
        return f.isSelected();
      })
      .forEach(function (c) {
        frequenciesToAssign.push({
          Key: c.FrequencyDisplay,
          Value: c.FrequencyNote()
        });
      });

    if (!frequenciesToAssign || frequenciesToAssign.length == 0) Common.Dialog('Assign Candidate', 'Select frequency to assign');
    else if (frequenciesToAssign && frequenciesToAssign.length > 0) {
      self.EquipmentAjaxCall(
        equip.EquipmentID,
        'AssignFrequencies',
        {
          frequenciesAndNotes: frequenciesToAssign,
          activityItemIDs: activityIDs
        },
        'assigning candidate frequencies',
        function () {
          self.CloseCandidate();
          self.BroadcastUpdateMultiple(
            equip.EquipmentID,
            frequenciesToAssign.map(function (x) {
              return x.Key;
            })
          );
        }
      );
    }
  };

  self.showIntermodWidget = function (e) {
    // DJM added 5-24 for EFC-1195
    //var button           = (($(e.target).parents('.intermodbtn').length !== 0) || $(e.target).hasclass('intermodbtn'))   ? '.intermodbtn' : '.importscanoptiosnbtn'
    //var drop             = (button == '.intermodbtn')                                                                    ? '#freqsdropdown' : '#importscandropdown';
    //var toggleObserv     = (button == '.intermodBtn')                                                                    ? self.ShowingIntermodWidget : self.ShowingImportScanOptions;

    self.ShowingIntermodWidget(true);
    var y = $('.intermodBtn').offset();
    var x = y.left;
    // Adding 11 because of padding and border.
    y = y.top + $('.intermodBtn').height() + 11;
    $('#freqsDropdown').css({
      zIndex: '3',
      top: y,
      left: x
    });
    if ($(e.target).parents('#freqsDropdown').length == 0) {
      setTimeout(function () {
        if (self.ShowingIntermodWidget() == true)
          $(document).on('click.applyIntermod', '*', function (e) {
            menuClose(e);
          });
      }, 100);
    }
  };

  var menuClose = function (e) {
    if ($(e.target).parents('#freqsDropdown').length == 0 || $(e.target).hasClass('freqDropReset')) {
      if (!self.InterModOptions.ApplyIntermod()) {
        self.InterModOptions.Show2ndOrder(false);
        self.InterModOptions.Show3rdOrder(false);
        self.InterModOptions.Show5thOrder(false);
        self.InterModOptions.Show7thOrder(false);
        self.InterModOptions.SelectedIModCompareIDs([]);
        $('#IModCompareIDs').trigger('chosen:updated');
      }
      $(document).off('click.applyIntermod');
      self.ShowingIntermodWidget(false);
    } else if ($(e.target).hasClass('freqDropApply')) {
      $(document).off('click.applyIntermod');
      self.ShowingIntermodWidget(false);
    }
  };

  self.SetProcessInterModOptions = function () {
    var initialPageLoad = self.SetIntermodOptionsFromCache;

    self.InterModOptions.EquipmentRequestTypeID = self.EquipmentRequestType;
    self.InterModOptions.CompareEntityIDs = self.InterModOptions.ApplyIntermod()
      ? self.InterModOptions.SelectedIModCompareIDs() && self.InterModOptions.SelectedIModCompareIDs().length > 0
        ? self.InterModOptions.SelectedIModCompareIDs().join(',')
        : ''
      : '';

    Common.BlockUI();
    $.post(
      RazorShared.baseUrl + self.ControllerInfo.ControllerName + '/SetProcessInterModOptions',
      {
        procID: self.SelectedPageItem().ProcID,
        iModOptions: self.InterModOptions
      },
      function (data) {
        if (data.success) {
          $.unblockUI();
          if (!initialPageLoad)
            self.LoadPageItem(
              self.SelectedPageItem().ItemID,
              self.SelectedEquipmentID(),
              null,
              self.AssignFrequencyBlockSelector.SelectedFrequencyBlock(),
              self.AssignFrequencyBlockSelector.SelectedFreqBlockSegment()
            );
        } else {
          $.unblockUI();
          Common.Dialog('Error setting intermod options for process', null, data.error);
        }
      }
    ).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $.unblockUI();
      Common.Dialog('Error setting intermod options for process', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });

    Cookies.set(self.intermodOptionsCookieName, ko.toJS(self.InterModOptions), {
      expires: 30
    });
  };

  self.ApplyIntermodToGraph = function (equip) {
    if (!equip) return;
    self.InterModOptions.ApplyIntermod(true);
    self.SetProcessInterModOptions();
  };

  //self._ApplyIntermodToGraph = function (equip, callback) {
  //   callback();
  //   self.LoadEquipmentFrequencyData(equip);
  //}

  self.ClearIntermod = function (equip) {
    if (!equip) return;

    self.InterModOptions.Show2ndOrder(false);
    self.InterModOptions.Show3rdOrder(false);
    self.InterModOptions.Show5thOrder(false);
    self.InterModOptions.Show7thOrder(false);
    self.InterModOptions.SelectedIModCompareIDs([]);
    $('#IModCompareIDs').trigger('chosen:updated');
    self.InterModOptions.ApplyIntermod(false);

    self.SetProcessInterModOptions();
  };

  self.LoadEquipmentFrequencyData = function (equip, selectedCallback, callbackParam2, callbackParam3) {
    if (!equip) equip = self.SelectedEquipment();

    if (!self.AssignFrequencyBlockSelector.SelectedFrequencyBlock()) {
      self.ClearFrequencies();
      return;
    }

    var params = {
      procID: self.SelectedPageItem().ProcID,
      freqBlockID: self.AssignFrequencyBlockSelector.SelectedFrequencyBlock(),
      segmentNum: self.AssignFrequencyBlockSelector.SelectedFreqBlockSegment(),
      spacingBandwidth: equip.Bandwidth + equip.PriorityBandwidth,
      parentEntityID: equip.ParentID || 0
    };
    if (self.EquipmentRequestType == EquipmentRequestType.Request)
      params.locationId = self
        .SelectedActivities()
        .map(function (a) {
          return a.LocationID;
        })
        .join();
    else if (self.EquipmentRequestType == EquipmentRequestType.Static)
      params.locationId = self
        .CurrentStaticSet()
        .map(function (a) {
          return a.LocationID;
        })
        .join();

    Common.BlockUI();
    $.post(RazorShared.baseUrl + self.ControllerInfo.ControllerName + '/FrequencyAssignmentData', params, function (data) {
      if (data.success) {
        if (equip.EquipmentID == self.SelectedEquipmentID()) {
          // hasn't changed during ajax call
          self.ClearFrequencies();
          if (data.segmentList)
            self.AssignFrequencyBlockSelector.LoadBlockSegments(data.segmentList, params.segmentNum, self.resetSelectedFreqBlockSegment());
          self.FrequencyDataReceived(equip.EquipmentID, data.freqData, data.scanData, equip.AssignmentCompleted());
        }
        $.unblockUI();
        if (typeof selectedCallback === 'function') selectedCallback(equip.EquipmentID, callbackParam2, callbackParam3);
      } else {
        $.unblockUI();
        Common.Dialog('Error retrieving frequency data', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $.unblockUI();
      Common.Dialog('Error retrieving frequency data', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.AssignFrequencyBlockSelector = new AssignFrequencyBlockSelector(self.LoadEquipmentFrequencyData);

  self.EquipmentAjaxCall = function (equipmentID, ajaxMethod, ajaxParams, messageTitle, callback, newEquip) {
    var equip = null;
    var unassignFreqHasIntermodConflict = false;

    if (!newEquip) {
      equip = self.FindEquipment(equipmentID); // defaults to selected if param null
      if (!equip) {
        Common.Dialog('Error ' + messageTitle, 'Cannot identify equipment');
        return;
      }
    }

    self.lastAjaxMethod = ajaxMethod;

    if (!ajaxParams) ajaxParams = {};
    ajaxParams.procID = self.SelectedPageItem().ProcID;
    ajaxParams.equipID = newEquip ? equipmentID : equip.EquipmentID;
    if (ajaxMethod == 'UnassignFrequency' && ajaxParams.frequency)
      unassignFreqHasIntermodConflict = ko.utils
        .arrayFirst(equip.Frequencies(), function (f) {
          return f.Frequency() == ajaxParams.frequency;
        })
        .HasIntermodConflict();
    else if (ajaxMethod == 'ClearAllAssignedFrequencies')
      unassignFreqHasIntermodConflict = equip.Frequencies().some(function (f) {
        return f.HasIntermodConflict() == true;
      });

    Common.BlockUI();
    $.post(RazorShared.baseUrl + self.ControllerInfo.ControllerName + '/' + ajaxMethod, ajaxParams, function (data) {
      $.unblockUI();
      if (data.success) {
        if (data.equip) self.EquipmentReceived(data.equip);
        if (data.RequestStatusID) self.UpdateRequestStatus(data.equip.ParentID, data.RequestStatusID);
        if (data.sendRequestCompleteEmail) self.ShowEmailMessageDialog(data.equip.ParentID);
        if (callback && typeof callback === 'function') callback();
        if (data.reloadAllEquip || unassignFreqHasIntermodConflict)
          self.LoadPageItem(
            self.SelectedPageItem().ItemID,
            data.equip.EquipmentID,
            null,
            self.AssignFrequencyBlockSelector.SelectedFrequencyBlock(),
            self.AssignFrequencyBlockSelector.SelectedFreqBlockSegment()
          );
      } else {
        Common.Dialog('Error ' + messageTitle, null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $.unblockUI();
      Common.Dialog('Error ' + messageTitle, textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  // Join SignalR Group
  self.Join = function () {
    if (self.EquipmentRequestType == 1) RazorShared.assignFrequencyNotification.joinGroup(self.ScreenVM.EventID);
  };

  // Broadcast SignalR update
  self.BroadcastUpdate = function (equipmentID, frequency) {
    if (self.EquipmentRequestType == EquipmentRequestType.Request && frequency) {
      RazorShared.assignFrequencyNotification.updateAssignment(
        self.ScreenVM.EventID,
        self.SelectedPageItem().RequestID,
        equipmentID,
        frequency,
        true
      );
    }
  };

  // Broadcast SignalR update
  self.BroadcastUpdateMultiple = function (equipmentID, frequencies) {
    if (self.EquipmentRequestType == EquipmentRequestType.Request && frequencies) {
      var decimalFrequencies = [];
      frequencies.forEach(function (f) {
        decimalFrequencies.push(parseFloat(f));
      });
      RazorShared.assignFrequencyNotification.updateAssignments(
        self.ScreenVM.EventID,
        self.SelectedPageItem().RequestID,
        equipmentID,
        self.SelectedPageItem().RequestStatusID(),
        decimalFrequencies,
        true
      );
    }
  };

  self.BroadcastUpdateRequestClosed = function (requestID, requestStatusID, frequencies) {
    if (self.EquipmentRequestType == EquipmentRequestType.Request && frequencies) {
      RazorShared.assignFrequencyNotification.updateRequestClosed(self.ScreenVM.EventID, requestID, requestStatusID, frequencies);
    }
  };

  self.BroadcastEquipmentAdded = function (equipmentID, requestStatusID, frequencies) {
    if (self.EquipmentRequestType == EquipmentRequestType.Request && frequencies) {
      RazorShared.assignFrequencyNotification.updateEquipmentAdded(
        self.ScreenVM.EventID,
        self.SelectedPageItem().RequestID,
        equipmentID,
        requestStatusID,
        frequencies
      );
    }
  };

  // SignalR notification
  self.updateAssignmentChange = function (eventID, requestID, equipmentID, frequency) {
    if (self.EquipmentRequestType == EquipmentRequestType.Request && self.ScreenVM.EventID == eventID && self.SelectedEquipment()) {
      if (self.chart) self.chart.saveZoom();
      //Common.BlockUI();
      if (requestID == self.SelectedPageItem().RequestID) {
        self.EquipmentAjaxCall(equipmentID, 'GetEquipmentAssignmentData', null, 'Updating equipment data modified by another user');
        if (equipmentID != self.SelectedEquipment().EquipmentID)
          self.EquipmentAjaxCall(
            self.SelectedEquipment().EquipmentID,
            'GetEquipmentAssignmentData',
            null,
            'Updating equipment data modified by another user'
          );
      } else {
        var segmentFrequencies = self.AssignFrequencyBlockSelector.FrequenciesBySegment.filter(function (x) {
          return x.Value == self.AssignFrequencyBlockSelector.SelectedFreqBlockSegment();
        });
        if (segmentFrequencies) {
          var minSegmentFreq = segmentFrequencies[0].Key;
          var maxSegmentFreq = segmentFrequencies[segmentFrequencies.length - 1].Key;

          if (minSegmentFreq && maxSegmentFreq && minSegmentFreq <= frequency && frequency <= maxSegmentFreq) {
            console.log('Update this segment');
            if (self.AssignFrequencyBlockSelector.LoadingEquipment()) return;
            self.AssignFrequencyBlockSelector.GetDataCallback();
          }
        }
      }
      //$.unblockUI();
    }
  };

  // SignalR notification
  self.updateAssignmentChanges = function (eventID, requestID, equipmentID, requestStatusID, frequencies) {
    if (self.EquipmentRequestType == EquipmentRequestType.Request && self.ScreenVM.EventID == eventID && self.SelectedEquipment()) {
      if (self.chart) self.chart.saveZoom();
      //Common.BlockUI();
      if (requestID == self.SelectedPageItem().RequestID) {
        self.EquipmentAjaxCall(equipmentID, 'GetEquipmentAssignmentData', null, 'Updating equipment data modified by another user');
        if (equipmentID != self.SelectedEquipment().EquipmentID)
          self.EquipmentAjaxCall(
            self.SelectedEquipment().EquipmentID,
            'GetEquipmentAssignmentData',
            null,
            'Updating equipment data modified by another user'
          );
        if (self.SelectedPageItem().RequestStatusID() != requestStatusID)
          self.UpdateRequestStatus(self.SelectedPageItem().RequestID, requestStatusID);
      } else {
        var segmentFrequencies = self.AssignFrequencyBlockSelector.FrequenciesBySegment.filter(function (x) {
          return x.Value == self.AssignFrequencyBlockSelector.SelectedFreqBlockSegment();
        });
        if (segmentFrequencies) {
          var minSegmentFreq = segmentFrequencies[0].Key;
          var maxSegmentFreq = segmentFrequencies[segmentFrequencies.length - 1].Key;

          if (
            minSegmentFreq &&
            maxSegmentFreq &&
            frequencies.some(function (x) {
              return minSegmentFreq <= x && x <= maxSegmentFreq;
            })
          ) {
            console.log('Update this segment');
            if (self.AssignFrequencyBlockSelector.LoadingEquipment()) return;
            self.AssignFrequencyBlockSelector.GetDataCallback();
          }
        }
      }
      //$.unblockUI();
    }
  };

  // SignalR notifiation
  self.updateRequestClosed = function (eventID, requestID, requestStatusID, frequencies) {
    if (self.EquipmentRequestType == EquipmentRequestType.Request && self.ScreenVM.EventID == eventID) {
      if (self.chart) self.chart.saveZoom();
      if (self.SelectedPageItem().RequestID == requestID) {
        self
          .SelectedPageItem()
          .Equipments()
          .forEach(function (e) {
            self.EquipmentAjaxCall(e.EquipmentID, 'GetEquipmentAssignmentData', null, 'Updating equipment data modified by another user');
          });
        self.UpdateRequestStatus(self.SelectedPageItem().RequestID, requestStatusID);
      } else {
        self.updateAssignmentChanges(eventID, null, null, frequencies);
      }
    }
  };

  self.updateEquipmentAdded = function (eventID, requestID, equipmentID, requestStatusID, frequencies) {
    if (self.EquipmentRequestType == EquipmentRequestType.Request && self.ScreenVM.EventID == eventID) {
      if (self.chart) self.chart.saveZoom();
      if (self.SelectedPageItem().RequestID == requestID) {
        //self.LoadPageItem(self.SelectedPageItem().RequestID, equipmentID, null, true);
        self.EquipmentAjaxCall(
          equipmentID,
          'GetEquipmentAssignmentData',
          null,
          'New equipment added',
          function () {
            self.updateAssignmentChanges(eventID, null, null, null, frequencies);
          },
          true
        );
      } else {
        self.updateAssignmentChanges(eventID, requestID, equipmentID, requestStatusID, frequencies);
      }
    }
  };

  self.updateRequestCheckInStatus = function (requestID, requestStatusID) {
    if (self.EquipmentRequestType == EquipmentRequestType.Request && self.SelectedPageItem().RequestID == requestID)
      self.UpdateRequestStatus(self.SelectedPageItem().RequestID, requestStatusID);
  };

  self.redirectIfRequestRemoved = function (requestIDs) {
    if (self.EquipmentRequestType == EquipmentRequestType.Request) {
      var requestDeleted = requestIDs.find(function (id) {
        return self.SelectedPageItem().RequestID == id;
      });
      if (requestDeleted && requestDeleted > 0) {
        Common.DialogWithCallback('Request removed', 'This request created from static set has been removed', null, function () {
          self.RedirectToDashboard();
        });
      }
    }
  };

  self.RedirectToDashboard = function () {
    JSPost(RazorShared.baseUrl, null);
  };

  self.ScanUpdatedSignalR = ko.observable(true); //SignalR

  //SignalR notification
  self.spectrumScanUpdated = function (eventID, minFrequency, maxFrequency) {
    if (self.EquipmentRequestType == EquipmentRequestType.Request && self.ScreenVM.EventID == eventID && self.SelectedEquipment()) {
      if (!self.ShowSpectrumData()) {
        self.ScanUpdatedSignalR(true);
      } else if (minFrequency && maxFrequency && (minFrequency > 0 || maxFrequency > 0)) {
        var segmentFrequencies = self.AssignFrequencyBlockSelector.FrequenciesBySegment.filter(function (x) {
          return x.Value == self.AssignFrequencyBlockSelector.SelectedFreqBlockSegment();
        });
        if (segmentFrequencies) {
          var minSegmentFreq = segmentFrequencies[0].Key;
          var maxSegmentFreq = segmentFrequencies[segmentFrequencies.length - 1].Key;

          if (minSegmentFreq && maxSegmentFreq) {
            if (
              (minSegmentFreq <= minFrequency && minFrequency <= maxSegmentFreq) ||
              (minSegmentFreq <= maxFrequency && maxFrequency <= maxSegmentFreq) ||
              (minFrequency <= minSegmentFreq && minSegmentFreq <= maxFrequency) ||
              (minFrequency <= maxSegmentFreq && maxSegmentFreq <= maxFrequency)
            ) {
              console.log('Update this segment');
              //self.RefreshSpectrum();
              self.AssignFrequencyBlockSelector.GetDataCallback();
            }
          }
        }
      }
    }
  };

  //SignalR notification
  self.spectrumScanRemovedForLocation = function (eventID) {
    if (self.EquipmentRequestType == EquipmentRequestType.Request && self.ScreenVM.EventID == eventID && self.SelectedEquipment()) {
      if (!self.ShowSpectrumData()) {
        self.ScanUpdatedSignalR(true);
      } else {
        //self.RefreshSpectrum();
        self.AssignFrequencyBlockSelector.GetDataCallback();
      }
    }
  };

  if (self.EquipmentRequestType == EquipmentRequestType.Request) {
    self.ShowEmailMessageDialog = function (pageItemID) {
      DialogMgr.ShowEmailMessageDialog(pageItemID, 'Email Message', self.SendRequestCompletedEmail, true);
    };

    self.SendRequestCompletedEmail = function (pageItemID, message, footer) {
      $.post(
        RazorShared.baseUrl + self.ControllerInfo.ControllerName + '/SendRequestCompletedEmail',
        { requestID: pageItemID, message: message, footer: footer },
        function (data) {
          if (!data.success) Common.Dialog('Error sending request completed email', null, data.error);
        }
      ).fail(function (XMLHttpRequest, textStatus, errorThrown) {
        var emailError =
          XMLHttpRequest.responseJSON && XMLHttpRequest.responseJSON.error.length > 0
            ? XMLHttpRequest.responseJSON.error
            : textStatus + '/' + errorThrown;
        Common.Dialog('Error sending request completed email', emailError, null, XMLHttpRequest.status);
      });
    };

    self.SendAssignmentClosedEmail = function (equipmentID) {
      self.EquipmentAjaxCall(equipmentID, 'SendAssignmentClosedEmail', null, 'send equipment assigned email');
    };
  } else {
    self.ShowEmailMessageDialog = function (pageItemID) {};
    self.SendRequestCompletedEmail = function (pageItemID, message) {};
    self.SendAssignmentClosedEmail = function (equipmentID) {};
  }

  self.EditEquipment = function (equipmentID) {
    var equip = self.FindEquipment(equipmentID); // defaults to selected if param null
    if (!equip) return;

    if (equip.AssignmentStarted()) {
      Common.DialogConfirmation(
         'Edit Equipment',
        null,
        'Assignment for this equipment has started.  Making changes to Equipment Type, Manufacturer, Model or Frequency Block may cause you to lose your frequency changes, and require you to re-coordinate this equipment.<br><br>Do you want to continue editing this equipment?',
        
        function () {
    self.ControllerInfo.EquipEditFunc(
      equip.EquipmentID,
      function () {
        self.EquipmentAjaxCall(equip.EquipmentID, 'GetEquipmentAssignmentData', null, 'refreshing edited data');
      },
      true
    );
        },
        null
      );
    } else {
      self.ControllerInfo.EquipEditFunc(
        equip.EquipmentID,
        function () {
          self.EquipmentAjaxCall(equip.EquipmentID, 'GetEquipmentAssignmentData', null, 'refreshing edited data');
        },
        true
      );
    }
  };

  self.ViewEquipment = function (equipmentID) {
    var equip = self.FindEquipment(equipmentID); // defaults to selected if param null
    if (!equip) return;
    self.ControllerInfo.EquipViewFunc(equip.EquipmentID);
  };

  if (self.EquipmentRequestType == EquipmentRequestType.WalkIn) {
    self.AddNewEquipment = function () {
      if (!self.SelectedPageItem() || !self.ControllerInfo.EquipAddFunc) return;
      self.ControllerInfo.EquipAddFunc(
        self.SelectedPageItem().ItemID,
        function (newEquip) {
          self.LoadPageItem(self.SelectedPageItem().ItemID, newEquip.EntityID);
        },
        // TODO: When new walk-ins pushed by SignalR, will be no need to reload entire list
        //function (newEquip) { self.EquipmentAjaxCall(newEquip.EquipmentID, 'GetEquipmentAssignmentData', null, 'loading equipment data'); },
        self.DefaultActivityID()
      );
    };
  } else if (self.EquipmentRequestType == EquipmentRequestType.Request) {
    self.AddNewEquipment = function () {
      if (!self.SelectedPageItem() || !self.ControllerInfo.EquipAddFunc) return;

      if (self.SelectedPageItemIsCheckedIn()) {
        Common.DialogConfirmation(
          'Add Equipment',
          'Request is Checked-In.  Do you want to add equipment to this request?',
          null,
          function () {
            self.ControllerInfo.EquipAddFunc(self.SelectedPageItem().ItemID, function (newEquip) {
              //self.EquipmentAjaxCall(newEquip.EntityID, 'GetEquipmentAssignmentData', null, 'loading equipment data', null, true);   // Works, but does not update chart if new euip frequency falls within its range
              self.LoadPageItem(self.SelectedPageItem().ItemID, newEquip.EntityID);
              //self.BroadcastEquipmentAdded(newEquip.EntityID, self.SelectedPageItem().RequestStatusID(), newEquip.Frequencies.map(function (f) { return f.Frequency; }));
            });
          },
          null
        );
      } else {
        self.ControllerInfo.EquipAddFunc(
          self.SelectedPageItem().ItemID,
          function (newEquip) {
            //self.EquipmentAjaxCall(newEquip.EntityID, 'GetEquipmentAssignmentData', null, 'loading equipment data', null, true);   // Works, but does not update chart if new euip frequency falls within its range
            self.LoadPageItem(self.SelectedPageItem().ItemID, newEquip.EntityID);
            //self.BroadcastEquipmentAdded(newEquip.EntityID, self.SelectedPageItem().RequestStatusID(), newEquip.Frequencies.map(function (f) { return f.Frequency; }));
          },
          true
        );
        // TODO: When new walk-ins?? pushed by SignalR, will be no need to reload entire list
        //function (newEquip) { self.EquipmentAjaxCall(newEquip.EquipmentID, 'GetEquipmentAssignmentData', null, 'loading equipment data'); },
      }
    };
  } else {
    self.AddNewEquipment = function () {};
  }

  self.CloseAssignments = function (equipmentID) {
    var pendingFrequencies = [];
    if (self.SelectedEquipment() && equipmentID == self.SelectedEquipment().EquipmentID)
      pendingFrequencies = self
        .SelectedEquipment()
        .Frequencies()
        .filter(function (fa) {
          return fa.IsPending() == true;
        });
    self.EquipmentAjaxCall(equipmentID, 'CloseEquipment', null, 'closing assignments', function () {
      self.BroadcastUpdateMultiple(
        equipmentID,
        pendingFrequencies.map(function (f) {
          return f.Frequency();
        })
      );
    });
  };

  self.ReopenAssignments = function (equipmentID) {
    if (self.SelectedPageItemIsCheckedIn()) {
      Common.DialogConfirmation(
        'Reopen Assignment',
        'Request is Checked-In.  Do you want to reopen assignemnts?',
        null,
        function () {
          self.EquipmentAjaxCall(equipmentID, 'ReopenEquipment', null, 'reopening assignments');
        },
        null
      );
    } else self.EquipmentAjaxCall(equipmentID, 'ReopenEquipment', null, 'reopening assignments');
  };

  self.SetPriorityBandwidth = function (equipmentID) {
    var equip = self.FindEquipment(equipmentID); // defaults to selected if param null
    if (!equip) return;
    DialogMgr.EditPriorityBandwidth(
      self.SelectedPageItem().ProcID,
      equip.EquipmentID,
      self.ControllerInfo.ControllerName,
      self.DefaultPriorityBandwidth,
      self.PriorityBandwidthSet
    );
  };

  self.PriorityBandwidthSet = function (equipmentID, priorityBW) {
    if (!priorityBW || (priorityBW && priorityBW.length == 0)) priorityBW = 0;

    if (priorityBW) self.DefaultPriorityBandwidth = priorityBW;

    var equip = self.FindEquipment(equipmentID); // defaults to selected if param null
    if (equip) {
      equip.PriorityBandwidth = priorityBW;
      self.EquipmentAjaxCall(equip.EquipmentID, 'GetEquipmentAssignmentData', null, 'refreshing edited data');
    }
  };

  self.CloseAllEquipment = function () {
    var pageItemID = self.SelectedPageItem().ItemID;
    var params = {
      procID: self.SelectedPageItem().ProcID,
      equipParentID: pageItemID
    };

    Common.BlockUI();
    $.post(RazorShared.baseUrl + self.ControllerInfo.ControllerName + '/CloseEquipmentList', params, function (data) {
      $.unblockUI();
      if (data.success) {
        (data.equipList || []).forEach(function (e) {
          self.EquipmentReceived(e);
        });
        if (data.RequestStatusID) self.UpdateRequestStatus(pageItemID, data.RequestStatusID);
        if (data.sendRequestCompleteEmail) self.ShowEmailMessageDialog(pageItemID);
        if (data.rejectedFrequencies) self.BroadcastUpdateRequestClosed(pageItemID, data.RequestStatusID, data.rejectedFrequencies);
      } else {
        Common.Dialog('Error closing equipment', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $.unblockUI();
      Common.Dialog('Error closing equipment', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  // Frequencies
  self.FrequencyDataReceived = function (equipmentID, freqData, scanData, isEquipmentClosed) {
    if (equipmentID != self.SelectedEquipmentID()) return; // already on another equipment

    //self.SetupEquipmentItemConflicts(self.SelectedEquipment());

    var assignmentCallback = null;
    if (!self.SelectedEquipment().AssignmentCompleted()) assignmentCallback = self.AddRemoveFrequencyAssignment;

    var zoomchart = self.Frequencies().length == 0; // reset zoom if starting from scratch

    self.ScanData(scanData);

    var frequencies = (freqData.Frequencies || []).map(function (f) {
      return new FrequencyDataForGraph(f, equipmentID, self.MakeSelectionMask, assignmentCallback, FrequencyGraphItemBuilder, isEquipmentClosed);
    });

    // FILTER freqData TO SETUP CANDIDATE FREQ
    var candidateFrequencies = [];
    if (!isEquipmentClosed) {
      var frequenciesFiltered = (frequencies || []).filter(function (f) {
        return f.IsCandidateFrequency;
      });
      candidateFrequencies = frequenciesFiltered.map(function (c) {
        return new CandidateFrequency(c);
      });
    }

    (freqData.ContinuityDividers || []).forEach(function (c) {
      frequencies.push(new ContinuityDivider(c, equipmentID));
    });

    self.Frequencies(
      frequencies.sort(function (l, r) {
        return l.Frequency == r.Frequency ? 0 : l.Frequency < r.Frequency ? -1 : 1;
      })
    );
    self.CandidateFrequencies(
      candidateFrequencies.sort(function (l, r) {
        return l.Frequency == r.Frequency ? 0 : l.Frequency < r.Frequency ? -1 : 1;
      })
    );
    self.RefreshFrequencies(zoomchart);
  };

  self.ClearFrequencies = function () {
    self.Frequencies([]);
    self.RefreshChart();
  };

  self.RefreshFrequencies = function (rezoom) {
    self.Frequencies().forEach(function (f) {
      f.ApplyFilter(self.SelectionMask());
    });
    self.RefreshChart(rezoom);
  };

  self.AddRemoveFrequencyAssignment = function (equipmentID, frequency, assignType, dlgTitle) {
    if (equipmentID && equipmentID != self.SelectedEquipmentID()) return; // already on a different equipment

    var equip = self.SelectedEquipment();
    if (!equip || equip.AssignmentCompleted()) return;

    var activityIDs = [];

    switch (assignType) {
      case 'A':
      case 'N':
        var checkConflicts = false;
        if (assignType == 'A') {
          activityIDs = self.SelectedActivities().map(function (a) {
            return a.ActivityID;
          });
          checkConflicts = true;
        }

        self.GetFreqNoteDetail(
          equip.EquipmentID,
          frequency,
          function (freqInfo) {
            RazorShared.FrequencyAssignmentNoteVM.ShowFixedFreq(
              equip.EquipmentID,
              dlgTitle || 'Assign Frequency',
              checkConflicts,
              freqInfo,
              function (e, f, n) {
                self._AssignFrequencyConfirmed(e, f, activityIDs, n); // e, f, n = equipmentID, frequency, freqNote
              }
            );
          },
          true
        );
        break;
      case 'U':
        activityIDs = self.AllActivities.map(function (a) {
          return a.ActivityID;
        });
        Common.DialogConfirmation(
          'Unassign Frequency ' + frequency.toFixed(5),
          'Are you sure you want to unassign ' + frequency.toFixed(5) + ' for this equipment?',
          null,
          function () {
            self._UnassignFrequencyConfirmed(equip.EquipmentID, frequency, activityIDs);
          }
        );
        break;
      case 'R':
        self.EquipmentAjaxCall(equipmentID, 'RejectFrequency', { frequency: frequency }, 'rejecting frequency', function () {
          self.BroadcastUpdate(equipmentID, frequency);
        });
        break;
      case 'UR':
        self.EquipmentAjaxCall(equipmentID, 'UnassignFrequency', { frequency: frequency, activityItemIDs: [] }, 'unrejecting frequency', function () {
          self.BroadcastUpdate(equipmentID, frequency);
        });
        break;
      case 'AA':
        activityIDs = self.SelectedActivities().map(function (a) {
          return a.ActivityID;
        });
        var pendingFrequencies = equip.Frequencies().filter(function (fa) {
          return fa.IsPending() == true;
        });
        self.EquipmentAjaxCall(equipmentID, 'AssignAllPendingFrequencies', { activityItemIDs: activityIDs }, 'assigning frequencies', function () {
          self.BroadcastUpdateMultiple(
            equipmentID,
            pendingFrequencies.map(function (f) {
              return f.Frequency();
            })
          );
        });
        break;
      case 'CA':
        var assignedFrequencies = equip.Frequencies().filter(function (fa) {
          return fa.IsAssigned() == true;
        });
        Common.DialogConfirmation(
          'Unassign All Frequencies',
          'Are you sure you want to unassign all frequencies for this equipment?',
          null,
          function () {
            self.EquipmentAjaxCall(equipmentID, 'ClearAllAssignedFrequencies', null, 'unassigning frequencies', function () {
              self.BroadcastUpdateMultiple(
                equipmentID,
                assignedFrequencies.map(function (f) {
                  return f.Frequency();
                })
              );
            });
          }
        );
        break;
      default:
        break;
    }
  };

  self.GetFreqNoteDetail = function (equipmentID, frequency, callbackWithDetail, skipIntermodByEquipmentType) {
    if (typeof callbackWithDetail !== 'function') return;
    if (equipmentID && equipmentID != self.SelectedEquipmentID()) return; // already on a different equipment
    if (!equipmentID) equipmentID = self.SelectedEquipmentID();

    var extraWarnings = [];
    if (self.AssignFrequencyBlockSelector.FindSegmentNum(frequency) < 0) extraWarnings.push('Frequency not included in selected Frequency Block.');

    let params = {
      procID: self.SelectedPageItem().ProcID,
      equipID: equipmentID,
      frequency: frequency,
      skipIntermodByEquipmentType: skipIntermodByEquipmentType
    };

    Common.BlockUI();
    $.post(RazorShared.baseUrl + self.ControllerInfo.ControllerName + '/EquipmentFrequencyDetail', params, function (data) {
      $.unblockUI();
      if (data.success) {
        var freqDetail = new FrequencyDataDetailed(data.freqDetail, equipmentID, self.MakeSelectionMask, extraWarnings);
        freqDetail.ApplyFilter(self.SelectionMask());
        callbackWithDetail(freqDetail);
      } else {
        Common.Dialog('Error Retrieving Frequency Detail', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $.unblockUI();
      Common.Dialog('Error Retrieving Frequency Detail', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self._AssignFrequencyConfirmed = function (equipmentID, frequency, activityIDs, freqNote) {
    self.EquipmentAjaxCall(
      equipmentID,
      'AssignFrequency',
      {
        frequency: frequency,
        activityItemIDs: activityIDs,
        frequencyNote: freqNote
      },
      'assigning frequency',
      function () {
        self.BroadcastUpdate(equipmentID, frequency);
      }
    );
  };

  self._UnassignFrequencyConfirmed = function (equipmentID, frequency, activityIDs) {
    self.EquipmentAjaxCall(
      equipmentID,
      'UnassignFrequency',
      { frequency: frequency, activityItemIDs: activityIDs },
      'unassigning frequency',
      function () {
        self.BroadcastUpdate(equipmentID, frequency);
      }
    );
  };

  self.ShowInChart = function (equipmentID, frequency) {
    self.SelectEquipment(equipmentID, self._ShowInChart, frequency);
  };

  self._ShowInChart = function (equipmentID, frequency) {
    if (!self.AssignFrequencyBlockSelector.ShowFrequency(frequency, self.chart))
      Common.Dialog('Error', 'Frequency not included in selected Frequency Block.');
  };

  self.SelectFrequency = function (equipmentID, frequency) {
    if (!self.SelectedEquipment() || self.SelectedEquipmentID() != equipmentID) self.SelectEquipment(equipmentID, self._SelectFrequency, frequency);
    else self._SelectFrequency(frequency);
  };

  self._SelectFrequency = function (frequency) {
    if (frequency == self.SelectedEquipmentID())
      // somehow this gets triggered twice when coming from different equip with value of freq being equipmentid
      return;

    if (self.AssignFrequencyBlockSelector.SelectedFrequencyBlock()) self.AssignFrequencyBlockSelector.ShowFrequency(frequency, self.chart);
    else {
      self.AssignFrequencyBlockSelector.SelectCandidateFrequencyBlock(self.ControllerInfo.ControllerName, frequency, function () {
        if (self.AssignFrequencyBlockSelector.SelectedFrequencyBlock()) self.AssignFrequencyBlockSelector.ShowFrequency(frequency, self.chart);
      });
    }
  };

  self.AssignFrequency = function (equipmentID, frequency) {
    self.SelectEquipment(equipmentID, self._AssignFrequency, frequency);
  };

  self._AssignFrequency = function (equipmentID, frequency) {
    self.AssignFrequencyBlockSelector.ShowFrequency(frequency, self.chart);
    self.AddRemoveFrequencyAssignment(equipmentID, frequency, 'A', 'Assign Frequency');
  };

  self.UnassignFrequency = function (equipmentID, frequency) {
    self.SelectEquipment(equipmentID, self._UnassignFrequency, frequency);
  };

  self._UnassignFrequency = function (equipmentID, frequency) {
    self.AddRemoveFrequencyAssignment(equipmentID, frequency, 'U');
  };

  self.RejectFrequency = function (equipmentID, frequency) {
    self.SelectEquipment(equipmentID, self._RejectFrequency, frequency);
  };

  self._RejectFrequency = function (equipmentID, frequency) {
    self.AddRemoveFrequencyAssignment(equipmentID, frequency, 'R');
  };

  self.UnrejectFrequency = function (equipmentID, frequency) {
    self.SelectEquipment(equipmentID, self._UnrejectFrequency, frequency);
  };

  self._UnrejectFrequency = function (equipmentID, frequency) {
    self.AddRemoveFrequencyAssignment(equipmentID, frequency, 'UR');
  };

  self.AddFrequencyNote = function (equipmentID, frequency) {
    self.SelectEquipment(equipmentID, self._AddFrequencyNote, frequency);
  };

  self._AddFrequencyNote = function (equipmentID, frequency) {
    self.AddRemoveFrequencyAssignment(equipmentID, frequency, 'N', 'Add Frequency Note');
  };

  self.EditFrequencyNote = function (equipmentID, frequency) {
    self.SelectEquipment(equipmentID, self._EditFrequencyNote, frequency);
  };

  self._EditFrequencyNote = function (equipmentID, frequency) {
    self.AddRemoveFrequencyAssignment(equipmentID, frequency, 'N', 'Edit Frequency Note');
  };

  self.ShowFrequencyConflicts = function (equipmentID, frequency) {
    self.SelectEquipment(equipmentID, self._ShowFrequencyConflicts, frequency);
  };

  self._ShowFrequencyConflicts = function (equipmentID, frequency) {
    self.GetFreqNoteDetail(equipmentID, frequency, function (freqInfo) {
      RazorShared.FrequencyAssignmentNoteVM.ShowConflict(equipmentID, freqInfo);
    });
  };

  self.AddOtherFrequency = function (equipmentID) {
    self.SelectEquipment(equipmentID, self._AddOtherFrequency);
  };

  self._AddOtherFrequency = function (equipmentID) {
    if (equipmentID && equipmentID != self.SelectedEquipmentID()) return; // already on a different equipment

    var equip = self.SelectedEquipment();
    if (!equip || equip.AssignmentCompleted()) return;

    var activityIDs = self.SelectedActivities().map(function (a) {
      return a.ActivityID;
    });

    RazorShared.FrequencyAssignmentNoteVM.ShowFreq(equip.EquipmentID, 'Add Frequency', true, self.GetFreqNoteDetail, function (e, f, n) {
      self._AssignFrequencyConfirmed(e, f, activityIDs, n); // e, f, n = equipmentID, frequency, freqNote
    });
  };

  self.AssignAllPendingFrequencies = function (equipmentID) {
    self.AddRemoveFrequencyAssignment(equipmentID, null, 'AA');
  };

  self.ClearAllAssignedFrequencies = function (equipmentID) {
    self.AddRemoveFrequencyAssignment(equipmentID, null, 'CA');
  };

  self.NoteBubbleText = function (noteText) {
    return noteText + '\n \n Click to Edit';
  };

  self.ShowAllEventFrequencies = function () {};

  // Chart stuff
  self.chart = null;

  self.LoadChart = function () {
    var locationIDs = [];
    if (self.EquipmentRequestType == EquipmentRequestType.Request) {
      var sortedLocations = self.AllActivities.sort(Common.StringSortFunc('LocationName'));
      //locationIDs = Array.from(new Set(sortedLocations.map(a => a.LocationID)));
      locationIDs = Array.from(
        new Set(
          sortedLocations.map(function (a) {
            return a.LocationID;
          })
        )
      );
    } else if (self.EquipmentRequestType == EquipmentRequestType.Static)
      locationIDs = self.AllStaticSets.map(function (a) {
        return a.LocationID;
      });
    var locationAndScanColors = locationIDs.reduce(function (result, item, idx) {
      if (!self.ScanColors) return;
      result[item] = self.ScanColors[idx];
      return result;
    }, {});

    self.chart = new FrequencyChartVM(self.ChartData, self.SetInfoFrequency, locationIDs, locationAndScanColors);
    self.SelectionMask.subscribe(function () {
      self.RefreshFrequencies();
    });
  };

  self.RefreshChart = function (rezoom) {
    Common.LogConsole('RefreshChart');
    Common.LogConsole('lastAjaxMethod=' + self.lastAjaxMethod);

    if (self.chart) {
      self.chart.Refresh();
      if (rezoom) {
        self.chart.zoomChart();
        self.lastAjaxMethod = null;
      }
    }
  };

  self.ChartData = function () {
    //return self.Frequencies().map(function (f) {
    //   return f.GraphItem;
    //});

    var data = self.Frequencies().map(function (f) {
      return f.GraphItem;
    });

    //loop thru scan data
    if (self.ShowSpectrumData() || false) {
      var previousFrequencies = [];
      var locationIDs = self.SelectedScanLocations(); //Array.from(new Set(self.SelectedScanLocations().map(function (a) { return a.ID; })));
      var previousLocationId = [];
      var foundCount = 0;
      var addedCount = 0;

      var range = $('#hdnSpectrumScanFrequencyRange').val();
      if (range == null || range == '') range = '0.05';

      Common.LogConsole('hdnSpectrumScanFrequencyRange=' + range);

      $.each(data, function (o, graphItem) {
        //find frequency in scanData for each location
        $(locationIDs).each(function (o, locationId) {
          $(self.ScanData()).each(function (o, sc) {
            //spectrumScan
            if (sc.LocationID == locationId) {
              var d = $.grep(sc.Frequencies, function (f) {
                //frequency
                //frequency difference must be within range for match
                //multiple by 10000 because javascript can't handle precision math
                var factor = 10000;
                var diff = parseFloat(f.Frequency) * factor - parseFloat(graphItem.Frequency) * factor;
                return Math.abs(diff) <= parseFloat(range) * factor;
              });

              if (d.length > 0) {
                //Frequency found
                graphItem['SignalStrength_' + locationId] = d[0].GraphPosition;

                foundCount++;
                d[0]['Added'] = true;

                return false;
              }
            }
          });
        });
      });

      //add missing frequencies (DISABLED, just log count that is missing)
      $(self.ScanData()).each(function (o, sc) {
        var missing = $.grep(sc.Frequencies, function (f) {
          return !f.Added;
        });

        $(missing).each(function (o, f) {
          if ($.inArray(f.Frequency, previousFrequencies) == -1) {
            var graphItem = {
              Frequency: f.Frequency,
              MaxVal: 100,
              Value: 0,
              Color: '',
              OwnValue: 0,
              OwnColor: '',
              OwnBorder: '',
              OwnAlpha: 0,
              SBValue: 0,
              SBColor: '',
              PendingValue: 0,
              ClickFunc: null,
              BalloonText: ''
            };

            //graphItem['SignalStrength_' + sc.LocationID] = f.GraphPosition;
            //data.push(graphItem);
            previousFrequencies.push(f.Frequency);

            addedCount++;

            //Common.LogConsole('Added [' + f.Frequency + ']');
          } else {
            //frequency already added, find and update position for location
            var items = $.grep(data, function (g) {
              return g.Frequency == f.Frequency;
            });

            if (items.length > 0) {
              //var graphItem = items[0];
              //graphItem['SignalStrength_' + sc.LocationID] = f.GraphPosition;
            }
          }
        });
      });

      Common.LogConsole('[' + foundCount + '] frequencies were found');
      //Common.LogConsole('[' + addedCount + '] frequencies were added');
      Common.LogConsole('[' + addedCount + '] frequencies missing');
    }

    return data.sort(function (l, r) {
      return l.Frequency == r.Frequency ? 0 : l.Frequency < r.Frequency ? -1 : 1;
    });
  };

  // Freq Info Hover/Detail Box
  self.InfoFrequency = ko.observable(null);
  self.InfoDetail = ko.observable(false);
  self.InfoXCoord = ko.observable(0);

  // for ko display mode
  self.IsHover = ko.pureComputed(function () {
    return !self.InfoDetail();
  });
  self.IsPinned = ko.pureComputed(function () {
    return self.InfoDetail();
  });
  self.IsDialog = false;

  self.SetInfoFrequency = function (frequency, screenX, detail) {
    if (detail) {
      self.ClearInfoFrequency();
      self.GetFreqNoteDetail(self.SelectedEquipmentID(), frequency, function (freqDetail) {
        self.InfoXCoord(screenX);
        self.InfoDetail(true);
        self.InfoFrequency(freqDetail);
      });
    } else if (!self.InfoDetail()) {
      //  if detail box currently displayed, don't replace it except with another detail box
      var freqVM = self.Frequencies().find(function (f) {
        return f.Frequency == frequency;
      });
      if (freqVM) {
        self.InfoXCoord(screenX);
        self.InfoDetail(false);
        self.InfoFrequency(freqVM);
      } else {
        self.ClearInfoFrequency();
      }
    }
  };

  self.ClearInfoFrequency = function () {
    self.InfoFrequency(null);
    self.InfoXCoord(0);
    self.InfoDetail(false);
  };

  self.InfoDetail.subscribe(function (newValue) {
    // if detail box is being opened, attach handler to close it when clicking outside
    if (newValue) {
      $(document).on('click', self.DetailBoxClickHandler);
    } else {
      $(document).off('click', self.DetailBoxClickHandler);
    }
  });

  self.DetailBoxClickHandler = function (e) {
    var $detailBox = $('#detailInfo');
    if (!$detailBox.is(e.target) && $detailBox.has(e.target).length === 0) {
      self.ClearInfoFrequency();
    }
  };

  // Manage Spectrum Scan For Locations
  self.ShowManageScanForLocations = function (d, e) {
    if (self.DistinctActivityLocations().length == 1) {
      if (self.SelectedScanLocations().length == 0) self.SelectedScanLocations().push(self.DistinctActivityLocations()[0].ID);
      self.ToggleShowScan();
      return;
    }

    self.ShowingManageScanOptions(true);

    var y = $('.spectBtn').offset();
    var x = y.left;
    // Adding 11 because of padding and border.
    y = y.top + $('.spectBtn').height() + 11;
    $('#scanLocationsDropDown').css({
      zIndex: '3',
      top: y,
      left: x
    });

    if ($(e.target).parents('#scanLocationsDropDown').length == 0) {
      setTimeout(function () {
        if (self.ShowingManageScanOptions() == true)
          $(document).on('click.showScan', '*', function (e) {
            self.HideSpectrumScanOptions(e);
          });
      }, 100);
    }
  };

  self.HideSpectrumScanOptions = function (e) {
    if ($(e.target).parents('#scanLocationsDropDown').length == 0 || $(e.target).hasClass('spectBtnCancel')) {
      $(document).off('click.showScan');
      self.ShowSpectrumData(false);
      self.ShowingManageScanOptions(false);
      self.SelectedScanLocations.removeAll();
      $('#scanLocationsDropDown').find('.styleCheck').removeClass('active');
    } else if ($(e.target).hasClass('spectBtnApply')) {
      if (!self.ShowSpectrumData()) self.ShowSpectrumData(true);
      else self.RefreshSpectrum();

      $(document).off('click.showScan');
      self.ShowingManageScanOptions(false);
    }
  };

  //Import Spectrum Data
  self.ShowImportScanOptions = function (d, e) {
    self.ShowingImportScanOptions(true);

    var y = $('.importScanOptiosnBtn').offset();
    var x = y.left;
    // Adding 11 because of padding and border.
    y = y.top + $('.importScanOptiosnBtn').height() + 11;
    $('#importScanDropdown').css({
      zIndex: '3',
      top: y,
      left: x
    });

    if ($(e.target).parents('#importScanDropdown').length == 0) {
      setTimeout(function () {
        if (self.ShowingImportScanOptions() == true)
          $(document).on('click.importScans', '*', function (e) {
            self.HideImportScanOptions(e);
          });
      }, 100);
    }
  };

  self.HideImportScanOptions = function (e) {
    if ($(e.target).parents('#importScanDropdown').length == 0 || $(e.target).hasClass('importScanCancel')) {
      $(document).off('click.importScans');
      self.ShowingImportScanOptions(false);
    } else if ($(e.target).hasClass('importScanToLocations')) {
      $(document).off('click.importScans');
      self.ShowingImportScanOptions(false);
    }

    if (!self.ShowingImportScanOptions()) {
      self.ImportScanToLocations.removeAll();
      $('#importScanDropdown').find('.styleCheck').removeClass('active');
    }
  };

  self.ImportSpectrumScan = function () {
    if (self.ImportScanToLocations().length == 0) {
      Common.Dialog('Import Spectrum Data', 'Please select location(s) to import scan to.');
      return;
    }

    function ProcessFile(f, contents) {
      console.log('name: ' + f.name + '\ntype: ' + f.type + '\nsize: ' + f.size + ' bytes');

      var patt = /[0-9 a-z]+\.spa/gi;

      if (patt.test(f.name)) {
        console.log('valid filename');

        Common.BlockUI();

        setTimeout(function () {
          var locationIds = '';

          self.ImportScanToLocations().forEach(function (l) {
            locationIds += (locationIds.length > 0 ? '&' : '') + 'id=' + l.toString();
          });

          $.post(
            RazorShared.baseUrl + 'LocationMgmt/ImportSpectrumScan/?' + locationIds + '&fileName=' + f.name + '&notifyGroupOnly=true',
            contents,
            function (data) {
              if (data.success) {
                Common.Dialog('Import Success', "Successfully imported spectrum scan from file '" + f.name + "'");
                self.ImportScanToLocations.removeAll();
                $('#importScanDropdown').find('.styleCheck').removeClass('active');
                $(document).off('click.importScans');
                self.ShowingImportScanOptions(false);
                self.LoadEquipmentFrequencyData(self.SelectedEquipment());
                self.ShowSpectrumData(true);
              } else Common.Dialog('Error importing spectrum scan', null, data.error);
            }
          )
            .fail(function (XMLHttpRequest, textStatus, errorThrown) {
              Common.Dialog('Error importing spectrum scan', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
            })
            .always(function () {
              $.unblockUI();
            });
        }, 100);
      } else {
        Common.Dialog('Error', "Invalid file selected: '" + f.name + "'");
      }
    }

    if (window.File && window.FileReader && window.FileList && window.Blob) {
      var input = $(document.createElement('input'));
      input.attr('type', 'file');
      input.attr('accept', '.spa');
      input.on('change', function (e) {
        self.ReadSingleFile(e, ProcessFile);
      });
      input.trigger('click');
    } else {
      Common.Dialog('Error', 'The File APIs are not fully supported by your browser, please contact support.');
    }
  };

  self.ReadSingleFile = function (evt, callback) {
    var f = evt.target.files[0]; //first file only

    if (f) {
      var r = new FileReader();
      r.onload = function (e) {
        var contents = e.target.result;
        callback(f, contents);
      };
      r.readAsText(f);
    } else {
      Common.Dialog('Error', 'Failed to load file, please contact support.');
    }
  };
};

var AssignFrequencyBlockSelector = function (getDataCallback) {
  var self = this;

  if (typeof getDataCallback === 'function') self.GetDataCallback = getDataCallback;
  //function (equip, freqBlockID, segmentNum, selectedCallback, callbackParam2, callbackParam3)
  else self.GetDataCallback = function () {};

  self.AvailFrequencyBlocks = ko.observableArray([]);
  self.AvailFreqBlockSegments = ko.observableArray([]);
  self.FrequenciesBySegment = [];

  self.SelectedFrequencyBlock = ko.observable();

  self.SelectedFreqBlockSegment = ko.observable(-1);
  self.LastSelectedFreqBlockSegment = self.SelectedFreqBlockSegment();
  self.SegmentListReset = ko.observable(false);
  self.SegmentSelectedAfterReset = -1;

  self.FrequencyBlocksSelectable = ko.pureComputed(function () {
    return self.AvailFrequencyBlocks().length > 1;
  });
  self.FreqBlockSegmentsSelectable = ko.pureComputed(function () {
    return self.AvailFreqBlockSegments().length > 1;
  });
  self.LoadingEquipment = ko.observable(true); // subscriptions disabled until something loaded

  self.SelectedFreqBlockSegment.subscribe(function (freqBlockID) {
    Common.LogConsole('SelectedFreqBlockSegment changed to ' + freqBlockID);
    if (self.LoadingEquipment()) return;
    self.GetDataCallback();
  });

  self.SelectedFrequencyBlock.subscribe(function (freqBlockID) {
    if (self.LoadingEquipment()) return;

    self.SelectedFreqBlockSegment(-1);

    if (self.SegmentListReset() && self.SegmentSelectedAfterReset > 0) {
      self.GetDataCallback(null, function () {
        self.SelectedFreqBlockSegment(self.SegmentSelectedAfterReset);
        self.SegmentListReset(false);
      });
    } else self.GetDataCallback();
  });

  self.LoadBlocksForEquipment = function (equip, resetSelectedFreqBlockSegment) {
    self.LoadingEquipment(true);

    if (resetSelectedFreqBlockSegment) {
      Common.LogConsole('LoadBlocksForEquipment->Set SelectedFreqBlockSegment');
      self.SelectedFreqBlockSegment(0);
      self.SelectedFrequencyBlock(null);
    }

    self.AvailFrequencyBlocks(equip.AvailableFrequencyBlocks);

    self.LastSelectedFreqBlockSegment = self.SelectedFreqBlockSegment(); //remember selection before resetting
    self.AvailFreqBlockSegments([]);

    self.FrequenciesBySegment = [];

    if (equip.DefaultFrequencyBlock && equip.DefaultFrequencyBlock.FrequencyBlockID > 0) {
      self.SelectedFrequencyBlock(equip.DefaultFrequencyBlock.FrequencyBlockID);
      self.LoadBlockSegments(equip.DefaultFrequencyBlock, resetSelectedFreqBlockSegment);
    } else if (equip.AvailableFrequencyBlocks.length == 1) {
      self.SelectedFrequencyBlock(equip.AvailableFrequencyBlocks[0].Key);
      self.GetDataCallback();
    }

    self.LoadingEquipment(false);
  };

  self.LoadBlockSegments = function (segmentList, currSegmentNum, resetSelectedFreqBlockSegment) {
    if (!segmentList) return;
    self.LoadingEquipment(true);

    self.AvailFreqBlockSegments(segmentList.AvailableSegments);
    if (self.LastSelectedFreqBlockSegment != -1) {
      Common.LogConsole('Restoring SelectedFreqBlockSegment to ' + self.LastSelectedFreqBlockSegment);
      self.SelectedFreqBlockSegment(self.LastSelectedFreqBlockSegment); //restore selected value
    }

    self.FrequenciesBySegment = segmentList.FrequenciesBySegment;

    if (resetSelectedFreqBlockSegment) {
      Common.LogConsole('LoadBlockSegments->Set SelectedFreqBlockSegment');
      self.SelectedFreqBlockSegment(currSegmentNum && currSegmentNum >= 0 ? currSegmentNum : 0);
    }

    self.LoadingEquipment(false);
  };

  self.FindSegmentNum = function (frequency) {
    var seg = self.FrequenciesBySegment.find(function (f) {
      return f.Key == frequency;
    });
    return seg ? seg.Value : -1;
  };

  self.ShowFrequency = function (frequency, chartToScroll, callback, callbackParam2, callbackParam3) {
    var segmentNum = self.FindSegmentNum(frequency);
    if (segmentNum >= 0) {
      self.LoadingEquipment(true);

      Common.LogConsole('ShowFrequency->Set SelectedFreqBlockSegment to ' + segmentNum);
      self.SelectedFreqBlockSegment(segmentNum);

      self.LoadingEquipment(false);
      self.GetDataCallback(
        null,
        chartToScroll
          ? function () {
              chartToScroll.ScrollToValue(frequency);
            }
          : function () {}
      );
      return true;
    }
    return false;
  };

  self.ViewFrequencyBlock = function () {
    if (self.SelectedFrequencyBlock()) DialogMgr.ShowFrequencyListForBlock(self.SelectedFrequencyBlock());
  };

  self.SelectCandidateFrequencyBlock = function (controller, frequency, callback) {
    var ids = self.AvailFrequencyBlocks().map((a) => a.Key);
    console.log(ids);

    var params = {};
    params.availFrequencyBlocks = ids;
    params.frequency = frequency;

    Common.BlockUI();
    $.post(RazorShared.baseUrl + controller + '/GetAssignableFrequencyBlock', params, function (data) {
      $.unblockUI();
      if (data.success) {
        if (data.candidateBlockID) {
          self.SelectedFrequencyBlock(data.candidateBlockID);
          if (typeof callback === 'function') callback;
        }
      } else {
        Common.Dialog('Error', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $.unblockUI();
      Common.Dialog('Error', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

var NoteListContainer = function (noteControllerName) {
  var self = this;

  self.NoteControllerName = noteControllerName;

  self.PageItemID = -1;
  self.AllNotes = ko.observableArray([]);

  self.NewNote = function (noteText) {
    return new RequestNoteVM(
      {
        RequestID: self.PageItemID,
        Note: noteText ? noteText : ''
      },
      0,
      true,
      self.NoteSaved
    );
  };

  self.CreateNote = function (noteText) {
    return self.NewNote(noteText);
  };

  self.NoteSaved = function (editedNote) {
    if (editedNote != undefined) {
      editedNote.Note(EncodeHTML(editedNote.Note()));

      var postdata = $.param({
        note: editedNote
      });

      $.post(RazorShared.baseUrl + self.NoteControllerName + '/SaveRequestNote', postdata, function (data) {
        if (!data.success) {
          Common.Dialog('Error saving Notes', null, data.error);
        } else {
          if (data.RequestNoteID != 0) {
            if (editedNote.RequestNoteID == 0) editedNote.RequestNoteID = data.RequestNoteID;
          }
          editedNote.Note(DecodeHTML(editedNote.Note()));
          editedNote.NoteDate(data.NoteDate);
        }
      }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
        Common.Dialog('Save Notes', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
      });
    }
  };

  self.NoteRemoved = function (noteID, requestEquipmentID) {
    var postdata = $.param({
      removed: noteID
    });

    $.post(RazorShared.baseUrl + self.NoteControllerName + '/RemoveRequestNote', postdata, function (data) {
      if (!data.success) {
        Common.Dialog('Error saving Notes', null, data.error);
      } else {
        //self.okCallback();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Notes', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.RequestNoteList = new RequestNoteListVM(self.CreateNote, self.NoteSaved, self.NoteRemoved);

  self.LoadNotes = function (pageVM) {
    if (!pageVM || (!pageVM.RequestNotes && !pageVM.EquipmentNotes)) {
      self.PageItemID = -1;
      self.AllNotes([]);
      return;
    }

    self.PageItemID = pageVM.ItemID;

    var noteList = [];
    var equipList = [];

    if (pageVM.RequestNotes().length > 0) {
      pageVM.RequestNotes().forEach(function (n) {
        noteList.push(n);
      });
    }

    if (pageVM.Equipments().length > 0) {
      pageVM.Equipments().forEach(function (e) {
        equipList.push({
          ID: e.EquipmentID,
          Name: e.EquipmentTypeName + ' - ' + e.HowUsed
        });
      });
    }

    noteList.forEach(function (x) {
      x.SetSaveFunc(self.NoteSaved);
    });
    self.AllNotes(noteList);
    self.RequestNoteList.LoadEquipment(ko.toJS(equipList));
    self.RequestNoteList.LoadList(self.AllNotes, false);
    self.RequestNoteList.ShowAllEquipmentNotes(false);
  };

  self.UpdateNotesEquipmentID = function (equipid) {
    self.RequestNoteList.NotesForEquipmentID(equipid);
  };
};

var FrequencyGraphItemBuilder = function (freqData) {
  var graphItem = {
    Frequency: freqData.FrequencyDisplay,
    MaxVal: 100,
    Value: 0,
    Color: '',
    OwnValue: 0,
    OwnColor: '',
    OwnBorder: '',
    OwnAlpha: 0,
    SBValue: 0,
    SBColor: '',
    PendingValue: 0,
    ImodValue: 0,
    ClickFunc: freqData.ToggleAssignment,
    BalloonText: freqData.BalloonText
  };

  var GetGraphValue = function (val) {
    if (val > 0 && val < AssignFrequenciesConst.MinGraphHeight) return AssignFrequenciesConst.MinGraphHeight;
    if (val > AssignFrequenciesConst.MaxGraphHeight) return AssignFrequenciesConst.MaxGraphHeight;
    return val;
  };

  if (freqData.CurrentEquipmentUses.length > 0) {
    // has something for current equipment
    graphItem.OwnValue = graphItem.MaxVal;
    graphItem.SBValue = graphItem.OwnValue;
    graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha * 0.5;

    switch (freqData.CurrentEquipmentUses[0].FrequencyUseConflictTypeID) {
      case FrequencyUseConflictType.AssignedCenter:
        graphItem.OwnColor =
          freqData.CurrentEquipmentAssigned && freqData.CurrentEquipmentAssigned.DisplayColor
            ? freqData.CurrentEquipmentAssigned.DisplayColor
            : FrequencyChartColors.ColorTaken;
        graphItem.OwnBorder = FrequencyChartColors.StrokeAssigned;
        graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha;
        graphItem.SBColor = graphItem.OwnColor;
        break;
      case FrequencyUseConflictType.AssignedBandwidth:
        graphItem.OwnColor = FrequencyChartColors.ColorOverlap;
        graphItem.OwnBorder = FrequencyChartColors.StrokeAssigned;
        graphItem.SBColor = graphItem.OwnColor;
        break;
      case FrequencyUseConflictType.Spacing:
      case FrequencyUseConflictType.Intermod:
        graphItem.OwnColor = FrequencyChartColors.ColorOverlap;
        graphItem.OwnBorder = FrequencyChartColors.StrokeWithin;
        graphItem.SBColor = graphItem.OwnColor;
        break;
      case FrequencyUseConflictType.Pending:
      case FrequencyUseConflictType.Other:
        graphItem.OwnColor = FrequencyChartColors.ColorOpen;
        graphItem.OwnBorder = FrequencyChartColors.StrokePending;
        graphItem.SBColor = '';
        graphItem.SBValue = 0;
        break;
      default:
        graphItem.OwnValue = 0;
        graphItem.OwnColor = '';
        graphItem.OwnBorder = '';
        graphItem.SBColor = '';
        graphItem.SBValue = 0;
        break;
    }
    if (freqData.ShowIModInGraph) {
      graphItem.ImodValue = '30';
      //graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha * 1;
      graphItem.OwnBorder = FrequencyChartColors.StrokeWithin;
      graphItem.SBValue = '30';
      graphItem.SBColor = FrequencyChartColors.ColorImodInRange;
    }
  } else if (freqData.ImodActive.length) {
    graphItem.OwnValue = graphItem.MaxVal;
    graphItem.SBValue = graphItem.OwnValue;
    graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha * 0.5;
    graphItem.OwnColor = FrequencyChartColors.ColorOverlap;
    graphItem.OwnBorder = FrequencyChartColors.StrokeWithin;
    graphItem.SBColor = graphItem.OwnColor;
  } else if (freqData.ShowIModInGraph && freqData.CurrentEquipmentUses.length == 0) {
    graphItem.ImodValue = '30';
    graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha * 1;
    graphItem.OwnColor = FrequencyChartColors.ColorImodInRange;
    graphItem.OwnBorder = '';
    graphItem.SBValue = '';
    graphItem.SBColor = '';
  } else {
    // nothing for current equipment
    graphItem.OwnValue = 0;
    graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha * 0.5;
    graphItem.OwnColor = '';
    graphItem.OwnBorder = '';
    graphItem.SBColor = '';
    graphItem.SBValue = 0;
  }

  var otherPower = 0,
    TVPower = 0,
    otherVal = 0,
    TVVal = 0,
    otherColor = '',
    TVColor = '';
  if (freqData.ActiveTVUses.length > 0) {
    TVPower =
      freqData.ActiveTVUses.map(function (x) {
        return x.Power;
      }).reduce(function (max, cur) {
        return Math.max(max, cur);
      }, 1) * AssignFrequenciesConst.TVPowerGraphFactor;
    TVVal = GetGraphValue(TVPower);

    if (freqData.ActiveTVUses[0].DisplayColor) TVColor = freqData.ActiveTVUses[0].DisplayColor;
    else if (freqData.ActiveTVUses[0].IsHighPower) TVColor = FrequencyChartColors.ColorTVHigh;
    else TVColor = FrequencyChartColors.ColorTVLow;
  }

  if (freqData.OtherEquipmentActive.length > 0) {
    otherPower =
      freqData.OtherEquipmentActive.map(function (x) {
        return x.Power;
      }).reduce(function (max, cur) {
        return Math.max(max, cur);
      }, 1) * AssignFrequenciesConst.EquipPowerGraphFactor;
    otherVal = GetGraphValue(otherPower);

    if (freqData.OtherEquipmentThisCenterAssigned.length > 0) {
      // This gives Center Assigned Conflict Equipment Color Higer Priority Over Bandwidth Conflict
      otherColor = freqData.OtherEquipmentThisCenterAssigned[0].DisplayColor
        ? freqData.OtherEquipmentThisCenterAssigned[0].DisplayColor
        : FrequencyChartColors.ColorTaken;
    } else if (freqData.OtherEquipmentThisAssigned.length > 0) {
      otherColor = freqData.OtherEquipmentThisAssigned[0].DisplayColor
        ? freqData.OtherEquipmentThisAssigned[0].DisplayColor
        : FrequencyChartColors.ColorTaken;
    } else otherColor = FrequencyChartColors.ColorOverlap;
  }

  if (otherPower > 0 && TVPower > 0 && graphItem.OwnValue == 0) {
    // "borrow" own equipment graph to show both
    graphItem.OwnValue = TVVal;
    graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha * 2;
    graphItem.OwnColor = TVColor;
    graphItem.OwnBorder = '';

    graphItem.Value = otherVal;
    graphItem.Color = otherColor;

    if (TVPower > otherPower) {
      graphItem.SBColor = TVColor;
      graphItem.SBValue = TVVal;
    } else {
      graphItem.SBColor = otherColor;
      graphItem.SBValue = otherVal;
    }
  } else if (TVPower > otherPower) {
    // show TV if stronger than other equip
    graphItem.Value = TVVal;
    graphItem.Color = TVColor;
  } else if (otherPower > 0) {
    // show other equipment if TV not stronger
    graphItem.Value = otherVal;
    graphItem.Color = otherColor;
  } else {
    // nothing to show
    graphItem.Value = 0;
    graphItem.Color = FrequencyChartColors.ColorOpen;
  }

  if (graphItem.SBValue == 0) {
    graphItem.SBValue = graphItem.Value;
    graphItem.SBColor = graphItem.Color;
  }

  graphItem.PendingValue = graphItem.Value == 0 ? freqData.OtherEquipmentPending.length * AssignFrequenciesConst.PendingCountGraphFactor : 0;

  return graphItem;
};

var PriorityBandwidthVM = function () {
  var self = this;

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};
  self.procID = -1;
  self.equipID = -1;
  self.controllerName = '';

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;

    self.procID = params.procID || -1;
    self.equipID = params.equipID || -1;
    self.controllerName = params.controllerName || '';
  };

  self.Remove = function () {
    self.Save(true);
  };

  self.Save = function (remove) {
    if (!self.$form.validate().form()) return;

    if (!self.controllerName || (!self.equipID && self.equipID !== 0)) {
      Common.Dialog('Error saving Priority Bandwidth', null, 'Unable to access Save method.');
      self.$container.modal('hide');
      return;
    }
    var priorityBW = remove ? 0 : $('#PriorityBandwidth').val();
    var params = {
      procID: self.procID,
      equipID: self.equipID,
      priority: priorityBW || 0
    };
    $.post(RazorShared.baseUrl + self.controllerName + '/SetPriorityBandwidth', params, function (data) {
      if (!data.success) {
        Common.Dialog('Error saving Priority Bandwidth', null, data.error);
      } else {
        self.$container.modal('hide');
        self.okCallback(self.equipID, priorityBW, data.equip);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Error saving Priority Bandwidth', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

var FrequencyAssignmentNoteDialogVM = function () {
  var self = this;

  // Current implementation as two modals, abstracted out to make it easy to change
  self.$freqnotemodal = null;
  self.$conflictmodal = null;
  self.$freqEntryField = null;

  self.OpenFreq = function () {
    if (self.$freqnotemodal) {
      self.$freqnotemodal.modal('show');
    } else {
      self.$freqnotemodal = $('#divFrequencyAssignmentNote');
      self.$freqEntryField = self.$freqnotemodal.find('#Frequency');
      self.$freqnotemodal.modal({
        backdrop: 'static',
        keyboard: false,
        show: true
      });
      Common.ConfigDialog(self.$freqnotemodal);
    }
  };

  self.OpenConflict = function () {
    if (self.$conflictmodal) {
      self.$conflictmodal.modal('show');
    } else {
      self.$conflictmodal = $('#divConflictDialog');
      self.$conflictmodal.modal({
        backdrop: 'static',
        keyboard: false,
        show: true
      });
      Common.ConfigDialog(self.$conflictmodal);
    }
  };

  self.CloseFreq = function () {
    if (self.$freqnotemodal) self.$freqnotemodal.modal('hide');
  };

  self.CloseConflict = function () {
    if (self.$conflictmodal) self.$conflictmodal.modal('hide');
  };

  self.FreqFieldFocus = function () {
    if (self.$freqEntryField) self.$freqEntryField.focus();
  };
  // End modal implementation

  self.EquipID = -1;
  self.CheckConflicts = false;
  self.IsFixed = ko.observable(false);
  self.FreqEntry = ko.observable();
  self.FreqError = ko.observable('');
  self.FrequencyNoteEdit = ko.observable('');
  self.FreqInfo = ko.observable(null);
  self.DialogTitle = ko.observable('Assign Frequency');

  self.Frequency = ko.pureComputed(function () {
    return parseFloat(self.FreqEntry());
  });
  self.FrequencyDisplay = ko.pureComputed(function () {
    return self.Frequency() ? self.Frequency().toFixed(5) : '';
  });

  self.ChooseFreqCallback = function () {};
  self.ResultCallback = function () {};

  // for ko display mode
  self.InfoDetail = true;
  self.IsHover = false;
  self.IsPinned = false;
  self.IsDialog = true;
  self.CanSaveConflict = ko.observable(false);
  self.ClearInfoFrequency = function () {};

  self.ShowFreq = function (equipID, dlgTitle, checkConflicts, chooseFreqCallback, resultCallback) {
    self.Clear();
    self.EquipID = equipID;
    if (dlgTitle) self.DialogTitle(dlgTitle);
    self.CheckConflicts = checkConflicts;
    self.IsFixed(false);
    self.ChooseFreqCallback = chooseFreqCallback || function () {};
    self.ResultCallback = resultCallback || function () {};
    self.CanSaveConflict(typeof resultCallback == 'function');
    self.OpenFreq();
  };

  self.ShowFixedFreq = function (equipID, dlgTitle, checkConflicts, freqInfo, resultCallback) {
    self.Clear();
    self.EquipID = equipID;
    if (dlgTitle) self.DialogTitle(dlgTitle);
    self.CheckConflicts = checkConflicts;
    self.IsFixed(true);
    self.FreqEntry(freqInfo.FrequencyDisplay || '');
    self.FrequencyNoteEdit(freqInfo.CurrentNote || '');
    self.SetConflictInfo(freqInfo);
    self.ResultCallback = resultCallback || function () {};
    self.CanSaveConflict(typeof resultCallback == 'function');
    self.OpenFreq();
  };

  self.ShowConflict = function (equipID, freqInfo) {
    self.Clear();
    self.EquipID = equipID;
    self.IsFixed(true);
    self.FreqEntry(freqInfo.FrequencyDisplay || '');
    self.FrequencyNoteEdit(freqInfo.CurrentNote || '');
    self.SetConflictInfo(freqInfo);
    self.OpenConflict();
  };

  self.Clear = function () {
    self.EquipID = -1;
    self.CheckConflicts = false;
    self.IsFixed(false);
    self.FreqEntry(null);
    self.FreqError('');
    self.FrequencyNoteEdit('');
    self.FreqInfo(null);
    self.DialogTitle('Assign Frequency');
    self.ChooseFreqCallback = function () {};
    self.ResultCallback = function () {};
    self.CanSaveConflict(false);
  };

  self.SetConflictInfo = function (freqInfo) {
    self.FreqInfo(freqInfo.Frequency ? freqInfo : null);
  };

  self.FreqOK = function () {
    if (self.IsFixed()) {
      self.CloseFreq();
      self.CheckForConflict(); // opens window if necessary, otherwise saves
      return;
    }

    if (self.Frequency()) {
      var reString = '^\\s*0*(?=.*[1-9])\\d{1,7}(?:\\.\\d{0,6})?\\s*$'; //"^\d{1,7}(\.\d{0,5})?$";
      var re = new RegExp(reString);
      if (re.test(self.Frequency().toString())) {
        self.FreqError('');
        if (typeof self.ChooseFreqCallback == 'function') {
          self.ChooseFreqCallback(self.EquipID, self.Frequency(), function (info) {
            self.SetConflictInfo(info);
            self.CloseFreq();
            self.CheckForConflict(); // opens window if necessary, otherwise saves
          });
        }
      } else {
        self.FreqError('Invalid frequency.');
        self.FreqFieldFocus();
      }
    } else {
      self.FreqError('Please enter a valid numeric frequency.');
      self.FreqFieldFocus();
    }
  };

  self.CheckForConflict = function () {
    if (self.CheckConflicts && self.FreqInfo() && self.FreqInfo().HasConflict()) {
      self.OpenConflict();
    } else {
      self.ConflictOK();
    }
  };

  self.ConflictOK = function () {
    if (typeof self.ResultCallback == 'function') self.ResultCallback(self.EquipID, self.Frequency(), self.FrequencyNoteEdit());

    self.CloseConflict();
  };

  self.BackToFreq = function () {
    self.CloseConflict();
    self.OpenFreq();
  };

  self.CancelFreq = function () {
    self.CloseFreq();
  };

  self.CancelConflict = function () {
    self.CloseConflict();
  };
};

//var CandidateFrequencyDialogVM = function (data) {
//    var self = this;

//    self.CandidateFrequencies = ko.observable([]);

//    self.SelectedFrequencies = ko.observable([]);
//}

//setTimeout(function () {
//    $('.dropdown').on('show.bs.dropdown', function () {
//        var offset = $('.dropdown').offset();
//        console.log(offset);
//        if($(document).height() <= 680)$('#tablescreen').css('display', 'block')
//    });
//    $('.dropdown').on('hide.bs.dropdown', function () {
//        var offset = $('.dropdown').offset();
//        console.log(offset);
//        $('#tablescreen').css('display', 'none');
//    });
//}, 2000)

export { AssignFrequenciesVM, PriorityBandwidthVM, FrequencyAssignmentNoteDialogVM };
