import Cookies from 'js-cookie';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var TVWirelessMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 50;
  self.loadedCount = 0;
  self.cookieName = 'efcTVWirelessSearch';
  var searchCookie = Cookies.getJSON(self.cookieName);
  self.TVWirelessSearch = new TVWirelessSearch(searchCookie || jsonData.Search, self.defaultListCount);
  self.TVWirelessSearchHold = new TVWirelessSearch(searchCookie || jsonData.Search, self.defaultListCount);

  self.Countries = jsonData.Countries || [];
  self.WirelessMarkets = (jsonData.WirelessMarkets || []).sort(Common.StringSortFunc('Name'));
  self.TVChannels = jsonData.TVChannels || [];
  self.WirelessBlocks = (jsonData.WirelessBlocks || []).sort(Common.StringSortFunc('Name'));
  self.DisplayOptions = [
    { ID: '3', Name: 'TV and Wireless' },
    { ID: '1', Name: 'TV Only' },
    { ID: '2', Name: 'Wireless Only' }
  ];

  self.AvailWirelessMarkets = ko.pureComputed(function () {
    if (self.TVWirelessSearch.CountryIDs().length == 0) return self.WirelessMarkets;
    else
      return self.WirelessMarkets.filter(function (x) {
        return self.TVWirelessSearch.CountryIDs().indexOf(x.ParentID) >= 0;
      });
  });

  self.AvailWirelessBlocks = ko.pureComputed(function () {
    if (self.TVWirelessSearch.CountryIDs().length == 0) return self.WirelessBlocks;
    else
      return self.WirelessBlocks.filter(function (x) {
        return self.TVWirelessSearch.CountryIDs().indexOf(x.ParentID) >= 0;
      });
  });

  self.AvailTVChannels = ko.pureComputed(function () {
    var inCountries;
    if (self.TVWirelessSearch.CountryIDs().length == 0) inCountries = self.TVChannels;
    else
      inCountries = self.TVChannels.filter(function (x) {
        return self.TVWirelessSearch.CountryIDs().indexOf(x.ParentID) >= 0;
      });

    // reduce to unique values
    return inCountries.reduce(function (a, b) {
      if (a.indexOf(b.ID) < 0) a.push(b.ID);
      return a;
    }, []);
  });

  self.SetSort = function (sort) {
    if (self.TVWirelessSearch.SortBy == sort) {
      self.TVWirelessSearch.SortDesc = !self.TVWirelessSearch.SortDesc;
    } else {
      self.TVWirelessSearch.SortBy = sort;
      self.TVWirelessSearch.SortDesc = false;
    }
    self.LoadList();
  };

  self.LoadList = function () {
    $('#sectTVWirelessList').load(
      RazorShared.baseUrl + 'TVWireless/GetTVWirelessList',
      ko.toJS(self.TVWirelessSearch),
      function (response, status, xhr) {
        if (status == 'error')
          Common.Dialog('TV/Wireless Management', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
        else self.loadedCount = $('#numLoaded').val();
      }
    );
    Cookies.set(self.cookieName, ko.toJS(self.TVWirelessSearch), {
      expires: 30
    });
  };

  self.ApplyFilters = function () {
    var temp = self.TVWirelessSearch;
    self.TVWirelessSearch = new TVWirelessSearch(ko.toJS(self.TVWirelessSearchHold), self.defaultListCount);
    self.TVWirelessSearch.SortBy = temp.SortBy;
    self.TVWirelessSearch.SortDesc = temp.SortDesc;
    self.LoadList();
  };

  self.ListMore = function () {
    if (self.TVWirelessSearch.RetrieveCount > self.loadedCount) return;
    self.TVWirelessSearch.RetrieveCount += self.defaultListCount;
    self.LoadList();
  };

  self.ClearSearch = function () {
    self.TVWirelessSearch.ClearSearch(self.defaultListCount);
    self.TVWirelessSearchHold.ClearSearch(self.defaultListCount);

    self.LoadList();
  };

  self.AddTVStation = function () {
    DialogMgr.TVStationAddEdit(0, self.LoadList);
  };

  self.AddWirelessSpectrum = function () {
    DialogMgr.WirelessSpectrumAddEdit(0, self.LoadList);
  };

  self.EditTVStation = function (id) {
    DialogMgr.TVStationAddEdit(id, self.LoadList);
  };

  self.EditWirelessSpectrum = function (id) {
    DialogMgr.WirelessSpectrumAddEdit(id, self.LoadList);
  };

  self.setupSearchToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayToggled';
    var cookie = Cookies.get(toggleCookieName);
    if (cookie) $fld.click();

    $fld.on('displayToggled', function () {
      if ($(this).hasClass('toggled')) Cookies.set(toggleCookieName, 'toggled');
      else Cookies.remove(toggleCookieName);
    });
  };
};

var TVWirelessSearch = function (jsonData, defaultCount) {
  var self = this;

  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
  self.RetrieveCount = defaultCount;
  self.RetrieveStartAt = jsonData.RetrieveStartAt || null;

  self.IncludeNoLongerActive = ko.observable(jsonData.IncludeNoLongerActive || true);
  self.IncludeNotYetActive = ko.observable(jsonData.IncludeNotYetActive || true);
  self.CountryIDs = ko.observableArray(jsonData.CountryIDs || []);
  self.WirelessMarketIDs = ko.observableArray(jsonData.WirelessMarketIDs || []);
  self.TVChannels = ko.observableArray(jsonData.TVChannels || []);
  self.WirelessBlockIDs = ko.observableArray(jsonData.WirelessBlockIDs || []);
  self.OwnerNameLike = ko.observable(jsonData.OwnerNameLike || null);
  self.CallSignLike = ko.observable(jsonData.CallSignLike || null);
  self.Frequency = ko.observable(jsonData.Frequency || null);

  self.DisplayOption = ko.observable(3); // may change later
  self.IncludeTVStations = ko.pureComputed(function () {
    return self.DisplayOption() == 3 || self.DisplayOption() == 1;
  });
  self.IncludeWirelessSpectrum = ko.pureComputed(function () {
    return self.DisplayOption() == 3 || self.DisplayOption() == 2;
  });

  self.DisplayOption.subscribe(function (display) {
    var $tv = $('.tvonly');
    var $wireless = $('.wirelessonly');
    switch (display) {
      case '1': // TV Only
        $tv.removeAttr('disabled');
        $tv.removeClass('dynamic-disabled');
        $wireless.addClass('dynamic-disabled');
        $wireless.attr('disabled', true);
        self.WirelessMarketIDs([]);
        self.WirelessBlockIDs([]);
        self.OwnerNameLike(null);
        break;
      case '2': // Wireless Only
        $tv.addClass('dynamic-disabled');
        $tv.attr('disabled', true);
        $wireless.removeAttr('disabled');
        $wireless.removeClass('dynamic-disabled');
        self.TVChannels([]);
        self.CallSignLike(null);
        break;
      case '3':
      default:
        $tv.removeAttr('disabled');
        $tv.removeClass('dynamic-disabled');
        $wireless.removeAttr('disabled');
        $wireless.removeClass('dynamic-disabled');
        break;
    }
    $('.selectpicker').selectpicker('refresh');
  });

  var startStatus = 0;
  if (jsonData.IncludeTVStations || typeof jsonData.IncludeTVStations == 'undefined') startStatus += 1;
  if (jsonData.IncludeWirelessSpectrum || typeof jsonData.IncludeWirelessSpectrum == 'undefined') startStatus += 2;
  self.DisplayOption(startStatus || 3);

  self.ClearSearch = function (defaultCount) {
    if (defaultCount == undefined) return;
    self.DisplayOption(3);
    self.IncludeNoLongerActive(true);
    self.IncludeNotYetActive(true);
    self.CountryIDs([]);
    self.WirelessMarketIDs([]);
    self.TVChannels([]);
    self.WirelessBlockIDs([]);
    self.OwnerNameLike(null);
    self.CallSignLike(null);
    self.Frequency(null);

    self.RetrieveCount = defaultCount;
    self.RetrieveStartAt = null;
  };
};

export default TVWirelessMgmtVM;
