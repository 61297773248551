import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';
import { ActivityAssignment } from './AssignFrequenciesEntities.js';
import { FrequencyDataForGraph, FrequencyDataDetailed, AssignFrequenciesConst, FrequencyUseConflictType } from './AssignFrequenciesEntities.js';
import { FrequencyChartColors } from './FrequencyChart.js';

var EventFrequenciesVM = function (data) {
  var self = this;
  if (RazorShared.isDebug) Common.LogConsole('EventFrequeciesChartVM locator');

  self.EventID = data.EventID || 0;
  self.EventName = data.EventName || '';
  self.VenueName = data.VenueName || '';
  self.EventDate = data.EventDate || '';
  self.Frequencies = ko.observableArray([]);
  self.SegmentList = [];

  self.ScanColors = data.AvailScanColors;
  self.SelectedScanLocations = ko.observableArray([]);
  self.ShowingManageScanOptions = ko.observable(false);
  self.ShowSpectrumData = ko.observable(false);
  self.EnableApplyScanLocations = ko.observable(false);
  self.ScanUpdatedSignalR = ko.observable(true); //SignalR

  self.ChartMinFreq = ko.observable(data.ChartMinFreq || null);
  self.ChartMaxFreq = ko.observable(data.ChartMaxFreq || null);

  self.AllActivities = (data.Activities || []).map(function (a) {
    return new ActivityAssignment(a);
  });
  self.DistinctActivityLocations = ko.pureComputed(function () {
    var sortedActivities = self.AllActivities.sort(Common.StringSortFunc('LocationName')) || [];
    var distinctDisplayedLocations = Array.from(
      new Set(
        sortedActivities.map(function (a) {
          return a.LocationID;
        })
      )
    ).map(function (id, idx) {
      return {
        ID: id,
        Name: sortedActivities.find(function (l) {
          return l.LocationID === id;
        }).LocationName,
        ChartColor: self.ScanColors[idx]
      };
    });
    return distinctDisplayedLocations;
  });
  self.resetSelectedFreqBlockSegment = ko.observable(true);

  self.LoadInitial = function () {
    var currBitFlag = 1;

    self.AllActivities.forEach(function (a) {
      a.BitFlag = currBitFlag;
      currBitFlag <<= 1;
    });

    self.GetEventFrequencies();
  };

  self.SelectionMask = ko.pureComputed(function () {
    var mask = 0;
    if (self.AllActivities.length > 0)
      self.AllActivities.forEach(function (a) {
        mask += a.BitFlag;
      });
    return mask;
  });

  self.MakeSelectionMask = function (conflictIDs) {
    var bitmask = 0;
    if (conflictIDs) {
      conflictIDs.forEach(function (id) {
        var act = self.AllActivities.find(function (a) {
          return a.ActivityID == id;
        });
        if (act) bitmask += act.BitFlag;
      });
    }
    return bitmask;
  };

  self.GetEventFrequencies = function (keepSelectedSegmentAndZoom, message) {
    var params = {
      procID: self.EventID,
      segmentNum: self.EventFrequencyBlockSelector.SelectedFreqBlockSegment(),
      chartMinFreq: self.ChartMinFreq(),
      chartMaxFreq: self.ChartMaxFreq(),
      locationID: self.AllActivities.map(function (a) {
        return a.LocationID;
      }).join()
    };

    Common.BlockUI(message || '');
    $.post(RazorShared.baseUrl + 'EventFrequencies/FrequencyPopOutChart/', params, function (data) {
      if (data.success) {
        self.ClearFrequencies();
        if (data.segmentList) self.EventFrequencyBlockSelector.LoadBlockSegments(data.segmentList, params.segmentNum, !keepSelectedSegmentAndZoom); //self.resetSelectedFreqBlockSegment()
        self.FrequencyDataReceived(data.freqData, data.scanData, keepSelectedSegmentAndZoom);
        $.unblockUI();
      } else {
        $.unblockUI();
        Common.Dialog('Error retrieving frequency data', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $.unblockUI();
      Common.Dialog('Error retrieving frequency data', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.EventFrequencyBlockSelector = new EventFrequencyBlockSelector(self.GetEventFrequencies);

  self.UpdateChartRange = function () {
    self.UpdateAssignments();
  };

  self.UpdateAssignments = function () {
    self.EventFrequencyBlockSelector.SelectedFreqBlockSegment(-1);
    self.GetEventFrequencies();
    self.EventFrequencyBlockSelector.SelectedFreqBlockSegment(0);
  };

  // SignalR notification
  self.eventFrequencyUpdated = function (eventID, frequency) {
    if (self.EventID == eventID) {
      if (frequency && frequency > 0) {
        var segmentFrequencies = self.EventFrequencyBlockSelector.FrequenciesBySegment.filter(function (x) {
          return x.Value == self.EventFrequencyBlockSelector.SelectedFreqBlockSegment();
        });
        if (segmentFrequencies) {
          var minSegmentFreq = segmentFrequencies[0].Key;
          var maxSegmentFreq = segmentFrequencies[segmentFrequencies.length - 1].Key;

          if (minSegmentFreq && maxSegmentFreq && frequency && minSegmentFreq <= frequency && frequency <= maxSegmentFreq) {
            console.log('Update this segment');
            self.GetEventFrequencies(true, 'Updating with latest data');
          }
        }
      }
    }
  };

  //SignalR notification
  self.eventFrequencyUpdatedMultiple = function (eventID, frequencies) {
    if (self.EventID == eventID) {
      var segmentFrequencies = self.EventFrequencyBlockSelector.FrequenciesBySegment.filter(function (x) {
        return x.Value == self.EventFrequencyBlockSelector.SelectedFreqBlockSegment();
      });
      if (segmentFrequencies) {
        var minSegmentFreq = segmentFrequencies[0].Key;
        var maxSegmentFreq = segmentFrequencies[segmentFrequencies.length - 1].Key;

        if (
          minSegmentFreq &&
          maxSegmentFreq &&
          frequencies.some(function (x) {
            return minSegmentFreq <= x && x <= maxSegmentFreq;
          })
        ) {
          console.log('Update this segment');
          self.GetEventFrequencies(true, 'Updating with latest data');
        }
      }
    }
  };

  //SignalR notification
  self.spectrumScanUpdated = function (eventID, minFrequency, maxFrequency) {
    if (self.EventID == eventID) {
      if (!self.ShowSpectrumData()) {
        self.ScanUpdatedSignalR(true);
      } else if (minFrequency && maxFrequency && (minFrequency > 0 || maxFrequency > 0)) {
        var segmentFrequencies = self.EventFrequencyBlockSelector.FrequenciesBySegment.filter(function (x) {
          return x.Value == self.EventFrequencyBlockSelector.SelectedFreqBlockSegment();
        });
        if (segmentFrequencies) {
          var minSegmentFreq = segmentFrequencies[0].Key;
          var maxSegmentFreq = segmentFrequencies[segmentFrequencies.length - 1].Key;

          if (minSegmentFreq && maxSegmentFreq) {
            if (
              (minSegmentFreq <= minFrequency && minFrequency <= maxSegmentFreq) ||
              (minSegmentFreq <= maxFrequency && maxFrequency <= maxSegmentFreq) ||
              (minFrequency <= minSegmentFreq && minSegmentFreq <= maxFrequency) ||
              (minFrequency <= maxSegmentFreq && maxSegmentFreq <= maxFrequency)
            ) {
              console.log('Update this segment');
              self.GetEventFrequencies(true, 'Spectrum data updated');
            }
          }
        }
      }
    }
  };

  //SignalR notification
  self.spectrumScanRemovedForLocation = function (eventID) {
    if (self.EventID == eventID) {
      if (!self.ShowSpectrumData()) {
        self.ScanUpdatedSignalR(true);
      } else {
        self.GetEventFrequencies(true, 'Spectrum data updated');
      }
    }
  };

  // Frequencies
  self.FrequencyDataReceived = function (freqData, scanData, keepCurrentZoom) {
    var assignmentCallback = null;

    var zoomchart = self.Frequencies().length == 0 && !keepCurrentZoom; // reset zoom if starting from scratch

    self.ScanData(scanData);

    var frequencies = (freqData.Frequencies || []).map(function (f) {
      return new FrequencyDataForGraph(f, 0, self.MakeSelectionMask, null, FrequencyGraphItemBuilder, true);
    });

    //(freqData.ContinuityDividers || []).forEach(function (c) {
    //   frequencies.push(new ContinuityDivider(c, equipmentID));
    //});

    self.Frequencies(
      frequencies.sort(function (l, r) {
        return l.Frequency == r.Frequency ? 0 : l.Frequency < r.Frequency ? -1 : 1;
      })
    );
    self.RefreshFrequencies(zoomchart);
  };

  self.GetFreqNoteDetail = function (frequency, callbackWithDetail) {
    if (typeof callbackWithDetail !== 'function') return;

    var extraWarnings = [];

    let params = {
      procID: self.EventID,
      frequency: frequency
    };

    Common.BlockUI();
    $.post(RazorShared.baseUrl + 'AssignFrequencies/EventFrequencyDetail', params, function (data) {
      $.unblockUI();
      if (data.success) {
        var v = data.freqDetail;
        var freqDetail = new FrequencyDataDetailed(data.freqDetail, 0, self.MakeSelectionMask, extraWarnings);
        freqDetail.ApplyFilter(self.SelectionMask());
        callbackWithDetail(freqDetail);
      } else {
        Common.Dialog('Error Retrieving Frequency Detail', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $.unblockUI();
      Common.Dialog('Error Retrieving Frequency Detail', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.chart = null;

  self.LoadChart = function () {
    var locationIDs = [];
    var sortedLocations = self.AllActivities.sort(Common.StringSortFunc('LocationName'));
    locationIDs = Array.from(
      new Set(
        sortedLocations.map(function (a) {
          return a.LocationID;
        })
      )
    );

    var locationAndScanColors = locationIDs.reduce(function (result, item, idx) {
      result[item] = self.ScanColors[idx];
      return result;
    }, {});

    self.chart = new EventFrequencyChartVM(self.ChartData, self.SetInfoFrequency, locationIDs, locationAndScanColors);
    self.SelectionMask.subscribe(function () {
      self.RefreshFrequencies();
    });
  };

  self.RefreshChart = function (rezoom) {
    Common.LogConsole('RefreshChart');

    if (self.chart) {
      self.chart.Refresh();
      if (rezoom) {
        self.chart.zoomChart();
        self.lastAjaxMethod = null;
      }
    }
  };

  self.ClearFrequencies = function () {
    self.Frequencies([]);
    self.RefreshChart();
  };

  self.RefreshFrequencies = function (rezoom) {
    self.Frequencies().forEach(function (f) {
      f.ApplyFilter(self.SelectionMask());
    });
    self.RefreshChart(rezoom);
  };

  self.UpdateScanLocations = function (locationid) {
    var idx = self.SelectedScanLocations().indexOf(locationid);
    if (idx >= 0) {
      self.SelectedScanLocations.remove(locationid);
      $('#scanLocationsDropDown')
        .find('[data-loc="' + locationid + '"]')
        .removeClass('active');
    } else {
      self.SelectedScanLocations().push(locationid);
      $('#scanLocationsDropDown')
        .find('[data-loc="' + locationid + '"]')
        .addClass('active');
    }
    self.EnableApplyScanLocations(self.SelectedScanLocations().length > 0);
  };

  self.ScanData = ko.observableArray([]);
  self.ToggleShowScan = function () {
    self.ShowSpectrumData(!self.ShowSpectrumData());
  };

  self.HasSpectrumData = ko.pureComputed(function () {
    return self.ScanData() && self.ScanData().length > 0;
  });

  self.ShowSpectrumBalloonText = ko.pureComputed(function () {
    if (self.HasSpectrumData()) return 'Click to see spectrum scan data';
    else return 'No spectrum data available for selected frequency range';
  });

  // Manage Spectrum Scan For Locations
  self.ShowManageScanForLocations = function (d, e) {
    if (self.DistinctActivityLocations().length == 1) {
      if (self.SelectedScanLocations().length == 0) self.SelectedScanLocations().push(self.DistinctActivityLocations()[0].ID);
      self.ToggleShowScan();
      return;
    }

    self.ShowingManageScanOptions(true);

    var y = $('.spectBtn').offset();
    var x = y.left;
    // Adding 11 because of padding and border.
    y = y.top + $('.spectBtn').height() + 11;
    $('#scanLocationsDropDown').css({
      zIndex: '3',
      top: y,
      left: x
    });

    if ($(e.target).parents('#scanLocationsDropDown').length == 0) {
      setTimeout(function () {
        if (self.ShowingManageScanOptions() == true)
          $(document).on('click.showScan', '*', function (e) {
            self.HideSpectrumScanOptions(e);
          });
      }, 100);
    }
  };

  self.HideSpectrumScanOptions = function (e) {
    if ($(e.target).parents('#scanLocationsDropDown').length == 0 || $(e.target).hasClass('spectBtnCancel')) {
      $(document).off('click.showScan');
      self.ShowSpectrumData(false);
      self.ShowingManageScanOptions(false);
      self.SelectedScanLocations.removeAll();
      $('#scanLocationsDropDown').find('.styleCheck').removeClass('active');
    } else if ($(e.target).hasClass('spectBtnApply')) {
      if (!self.ShowSpectrumData()) self.ShowSpectrumData(true);
      else self.RefreshSpectrum();

      $(document).off('click.showScan');
      self.ShowingManageScanOptions(false);
    }
  };

  self.CancelSpectrumScan = function () {
    self.SelectedScanLocations().forEach(function (id) {
      $('#scanLocationsDropDown')
        .find('[data-loc="' + id + '"]')
        .removeClass('active');
    });
    self.SelectedScanLocations.removeAll();
    self.ShowSpectrumData(false);
  };

  self.ShowSpectrumData.subscribe(function (value) {
    Common.LogConsole('ShowSpectrumData=' + value);
    if (self.ScanUpdatedSignalR()) {
      self.GetEventFrequencies(true, 'Updating with latest data');
      self.ScanUpdatedSignalR(false);
    }
    self.RefreshSpectrum();
  });

  self.RefreshSpectrum = function () {
    if (self.chart) self.chart.saveZoom();
    setTimeout(function () {
      self.RefreshFrequencies(true);
    }, 10); //use timeout to immediately show checkbox change
  };

  self.ChartData = function () {
    var data = self.Frequencies().map(function (f) {
      return f.GraphItem;
    });

    //loop thru scan data
    if (self.ShowSpectrumData() || false) {
      var previousFrequencies = [];
      var locationIDs = self.SelectedScanLocations();
      var previousLocationId = [];
      var foundCount = 0;
      var addedCount = 0;

      var range = $('#hdnSpectrumScanFrequencyRange').val();
      if (range == null || range == '') range = '0.05';

      Common.LogConsole('hdnSpectrumScanFrequencyRange=' + range);

      $.each(data, function (o, graphItem) {
        //find frequency in scanData for each location
        $(locationIDs).each(function (o, locationId) {
          $(self.ScanData()).each(function (o, sc) {
            //spectrumScan
            if (sc.LocationID == locationId) {
              var d = $.grep(sc.Frequencies, function (f) {
                //frequency
                //frequency difference must be within range for match
                //multiple by 10000 because javascript can't handle precision math
                var factor = 10000;
                var diff = parseFloat(f.Frequency) * factor - parseFloat(graphItem.Frequency) * factor;
                return Math.abs(diff) <= parseFloat(range) * factor;
              });

              if (d.length > 0) {
                //Frequency found
                graphItem['SignalStrength_' + locationId] = d[0].GraphPosition;

                foundCount++;
                d[0]['Added'] = true;

                return false;
              }
            }
          });
        });
      });

      //add missing frequencies (DISABLED, just log count that is missing)
      $(self.ScanData()).each(function (o, sc) {
        var missing = $.grep(sc.Frequencies, function (f) {
          return !f.Added;
        });

        $(missing).each(function (o, f) {
          if ($.inArray(f.Frequency, previousFrequencies) == -1) {
            var graphItem = {
              Frequency: f.Frequency,
              MaxVal: 100,
              Value: 0,
              Color: '',
              OwnValue: 0,
              OwnColor: '',
              OwnBorder: '',
              OwnAlpha: 0,
              SBValue: 0,
              SBColor: '',
              PendingValue: 0,
              ClickFunc: null,
              BalloonText: ''
            };

            //graphItem['SignalStrength_' + sc.LocationID] = f.GraphPosition;
            //data.push(graphItem);
            previousFrequencies.push(f.Frequency);

            addedCount++;

            //Common.LogConsole('Added [' + f.Frequency + ']');
          } else {
            //frequency already added, find and update position for location
            var items = $.grep(data, function (g) {
              return g.Frequency == f.Frequency;
            });

            if (items.length > 0) {
              //var graphItem = items[0];
              //graphItem['SignalStrength_' + sc.LocationID] = f.GraphPosition;
            }
          }
        });
      });

      Common.LogConsole('[' + foundCount + '] frequencies were found');
      //Common.LogConsole('[' + addedCount + '] frequencies were added');
      Common.LogConsole('[' + addedCount + '] frequencies missing');
    }

    return data.sort(function (l, r) {
      return l.Frequency == r.Frequency ? 0 : l.Frequency < r.Frequency ? -1 : 1;
    });
  };

  // Freq Info Hover/Detail Box
  self.InfoFrequency = ko.observable(null);
  self.InfoDetail = ko.observable(false);
  self.InfoXCoord = ko.observable(0);

  // for ko display mode
  self.IsHover = ko.pureComputed(function () {
    return !self.InfoDetail();
  });
  self.IsPinned = ko.pureComputed(function () {
    return self.InfoDetail();
  });
  self.IsDialog = false;

  self.SetInfoFrequency = function (frequency, screenX, detail) {
    if (detail) {
      self.ClearInfoFrequency();
      self.GetFreqNoteDetail(frequency, function (freqDetail) {
        self.InfoXCoord(screenX);
        self.InfoDetail(true);
        self.InfoFrequency(freqDetail);
      });
    } else if (!self.InfoDetail()) {
      //  if detail box currently displayed, don't replace it except with another detail box
      var freqVM = self.Frequencies().find(function (f) {
        return f.Frequency == frequency;
      });
      if (freqVM) {
        self.InfoXCoord(screenX);
        self.InfoDetail(false);
        self.InfoFrequency(freqVM);
      } else {
        self.ClearInfoFrequency();
      }
    }
  };

  self.ClearInfoFrequency = function () {
    self.InfoFrequency(null);
    self.InfoXCoord(0);
    self.InfoDetail(false);
  };

  self.InfoDetail.subscribe(function (newValue) {
    // if detail box is being opened, attach handler to close it when clicking outside
    if (newValue) {
      $(document).on('click', self.DetailBoxClickHandler);
    } else {
      $(document).off('click', self.DetailBoxClickHandler);
    }
  });

  self.DetailBoxClickHandler = function (e) {
    var $detailBox = $('#detailInfo');
    if (!$detailBox.is(e.target) && $detailBox.has(e.target).length === 0) {
      self.ClearInfoFrequency();
    }
  };

  var FrequencyGraphItemBuilder = function (freqData) {
    var graphItem = {
      Frequency: freqData.FrequencyDisplay,
      MaxVal: 100,
      Value: 0,
      Color: '',
      OwnValue: 0,
      OwnColor: '',
      OwnBorder: '',
      OwnAlpha: 0,
      SBValue: 0,
      SBColor: '',
      PendingValue: 0,
      ImodValue: 0,
      ClickFunc: true, //freqData.ToggleAssignment,
      BalloonText: freqData.BalloonText
    };

    var GetGraphValue = function (val) {
      if (val > 0 && val < AssignFrequenciesConst.MinGraphHeight) return AssignFrequenciesConst.MinGraphHeight;
      if (val > AssignFrequenciesConst.MaxGraphHeight) return AssignFrequenciesConst.MaxGraphHeight;
      return val;
    };

    if (freqData.OtherEquipmentUses.length > 0) {
      // has something for current equipment
      graphItem.OwnValue = graphItem.MaxVal;
      graphItem.SBValue = graphItem.OwnValue;
      graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha * 0.5;

      switch (freqData.OtherEquipmentUses[0].FrequencyUseConflictTypeID) {
        case FrequencyUseConflictType.AssignedCenter:
          graphItem.OwnColor =
            freqData.CurrentEquipmentAssigned && freqData.CurrentEquipmentAssigned.DisplayColor
              ? freqData.CurrentEquipmentAssigned.DisplayColor
              : FrequencyChartColors.ColorTaken;
          graphItem.OwnBorder = FrequencyChartColors.StrokeAssigned;
          graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha;
          graphItem.SBColor = graphItem.OwnColor;
          break;
        case FrequencyUseConflictType.AssignedBandwidth:
          graphItem.OwnColor = FrequencyChartColors.ColorOverlap;
          graphItem.OwnBorder = FrequencyChartColors.StrokeAssigned;
          graphItem.SBColor = graphItem.OwnColor;
          break;
        case FrequencyUseConflictType.Spacing:
        case FrequencyUseConflictType.Intermod:
          graphItem.OwnColor = FrequencyChartColors.ColorOverlap;
          graphItem.OwnBorder = FrequencyChartColors.StrokeWithin;
          graphItem.SBColor = graphItem.OwnColor;
          break;
        case FrequencyUseConflictType.Pending:
        case FrequencyUseConflictType.Other:
          graphItem.OwnColor = FrequencyChartColors.ColorOpen;
          graphItem.OwnBorder = FrequencyChartColors.StrokePending;
          graphItem.SBColor = '';
          graphItem.SBValue = 0;
          break;
        default:
          graphItem.OwnValue = 0;
          graphItem.OwnColor = '';
          graphItem.OwnBorder = '';
          graphItem.SBColor = '';
          graphItem.SBValue = 0;
          break;
      }
      if (freqData.ShowIModInGraph) {
        graphItem.ImodValue = '30';
        //graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha * 1;
        graphItem.OwnBorder = FrequencyChartColors.StrokeWithin;
        graphItem.SBValue = '30';
        graphItem.SBColor = FrequencyChartColors.ColorImodInRange;
      }
    } else if (freqData.ImodActive.length) {
      graphItem.OwnValue = graphItem.MaxVal;
      graphItem.SBValue = graphItem.OwnValue;
      graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha * 0.5;
      graphItem.OwnColor = FrequencyChartColors.ColorOverlap;
      graphItem.OwnBorder = FrequencyChartColors.StrokeWithin;
      graphItem.SBColor = graphItem.OwnColor;
    } else if (freqData.ShowIModInGraph && freqData.OtherEquipmentUses.length == 0) {
      graphItem.ImodValue = '30';
      graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha * 1;
      graphItem.OwnColor = FrequencyChartColors.ColorImodInRange;
      graphItem.OwnBorder = '';
      graphItem.SBValue = '';
      graphItem.SBColor = '';
    } else {
      // nothing for current equipment
      graphItem.OwnValue = 0;
      graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha * 0.5;
      graphItem.OwnColor = '';
      graphItem.OwnBorder = '';
      graphItem.SBColor = '';
      graphItem.SBValue = 0;
    }

    var otherPower = 0,
      TVPower = 0,
      otherVal = 0,
      TVVal = 0,
      otherColor = '',
      TVColor = '';
    if (freqData.ActiveTVUses.length > 0) {
      TVPower =
        freqData.ActiveTVUses.map(function (x) {
          return x.Power;
        }).reduce(function (max, cur) {
          return Math.max(max, cur);
        }, 1) * AssignFrequenciesConst.TVPowerGraphFactor;
      TVVal = GetGraphValue(TVPower);

      if (freqData.ActiveTVUses[0].DisplayColor) TVColor = freqData.ActiveTVUses[0].DisplayColor;
      else if (freqData.ActiveTVUses[0].IsHighPower) TVColor = FrequencyChartColors.ColorTVHigh;
      else TVColor = FrequencyChartColors.ColorTVLow;
    }

    if (freqData.OtherEquipmentActive.length > 0) {
      otherPower =
        freqData.OtherEquipmentActive.map(function (x) {
          return x.Power;
        }).reduce(function (max, cur) {
          return Math.max(max, cur);
        }, 1) * AssignFrequenciesConst.EquipPowerGraphFactor;
      otherVal = GetGraphValue(otherPower);

      if (freqData.OtherEquipmentThisAssigned.length > 0)
        otherColor = freqData.OtherEquipmentThisAssigned[0].DisplayColor
          ? freqData.OtherEquipmentThisAssigned[0].DisplayColor
          : FrequencyChartColors.ColorTaken;
      else otherColor = FrequencyChartColors.ColorOverlap;
    }

    if (otherPower > 0 && TVPower > 0 && graphItem.OwnValue == 0) {
      // "borrow" own equipment graph to show both
      graphItem.OwnValue = TVVal;
      graphItem.OwnAlpha = FrequencyChartColors.ItemBaseAlpha * 2;
      graphItem.OwnColor = TVColor;
      graphItem.OwnBorder = '';

      graphItem.Value = otherVal;
      graphItem.Color = otherColor;

      if (TVPower > otherPower) {
        graphItem.SBColor = TVColor;
        graphItem.SBValue = TVVal;
      } else {
        graphItem.SBColor = otherColor;
        graphItem.SBValue = otherVal;
      }
    } else if (TVPower > otherPower) {
      // show TV if stronger than other equip
      graphItem.Value = TVVal;
      graphItem.Color = TVColor;
    } else if (otherPower > 0) {
      // show other equipment if TV not stronger
      graphItem.Value = otherVal;
      graphItem.Color = otherColor;
    } else {
      // nothing to show
      graphItem.Value = 0;
      graphItem.Color = FrequencyChartColors.ColorOpen;
    }

    if (graphItem.SBValue == 0) {
      graphItem.SBValue = graphItem.Value;
      graphItem.SBColor = graphItem.Color;
    }

    graphItem.PendingValue = graphItem.Value == 0 ? freqData.OtherEquipmentPending.length * AssignFrequenciesConst.PendingCountGraphFactor : 0;

    return graphItem;
  };
};

var EventFrequencyBlockSelector = function (getDataCallback) {
  var self = this;

  if (typeof getDataCallback === 'function') self.GetDataCallback = getDataCallback;
  else self.GetDataCallback = function () {};

  self.AvailFreqBlockSegments = ko.observableArray([]);
  self.FrequenciesBySegment = [];

  self.SelectedFreqBlockSegment = ko.observable(-1);
  self.LastSelectedFreqBlockSegment = self.SelectedFreqBlockSegment();

  self.FreqBlockSegmentsSelectable = ko.pureComputed(function () {
    return self.AvailFreqBlockSegments().length > 1;
  });
  self.LoadingEquipment = ko.observable(true); // subscriptions disabled until something loaded

  self.SelectedFreqBlockSegment.subscribe(function (newVal) {
    if (self.LoadingEquipment()) return; // || newVal == -1
    self.GetDataCallback();
  });

  self.LoadBlockSegments = function (segmentList, currSegmentNum, resetSelectedFreqBlockSegment) {
    if (!segmentList) return;
    self.LoadingEquipment(true);

    self.AvailFreqBlockSegments(segmentList.AvailableSegments);
    if (self.LastSelectedFreqBlockSegment != -1) {
      Common.LogConsole('Restoring SelectedFreqBlockSegment to ' + self.LastSelectedFreqBlockSegment);
      self.SelectedFreqBlockSegment(self.LastSelectedFreqBlockSegment); //restore selected value
    }

    self.FrequenciesBySegment = segmentList.FrequenciesBySegment;

    if (resetSelectedFreqBlockSegment) {
      Common.LogConsole('LoadBlockSegments->Set SelectedFreqBlockSegment');
      self.SelectedFreqBlockSegment(currSegmentNum && currSegmentNum >= 0 ? currSegmentNum : 0);
    }

    self.LoadingEquipment(false);
  };

  self.FindSegmentNum = function (frequency) {
    var seg = self.FrequenciesBySegment.find(function (f) {
      return f.Key == frequency;
    });
    return seg ? seg.Value : -1;
  };

  self.ShowFrequency = function (frequency, chartToScroll, callback, callbackParam2, callbackParam3) {
    var segmentNum = self.FindSegmentNum(frequency);
    if (segmentNum >= 0) {
      self.LoadingEquipment(true);

      Common.LogConsole('ShowFrequency->Set SelectedFreqBlockSegment to ' + segmentNum);
      self.SelectedFreqBlockSegment(segmentNum);

      self.LoadingEquipment(false);
      self.GetDataCallback(
        null,
        chartToScroll
          ? function () {
              chartToScroll.ScrollToValue(frequency);
            }
          : function () {}
      );
      return true;
    }
    return false;
  };

  self.ViewFrequencyBlock = function () {
    if (self.SelectedFrequencyBlock()) DialogMgr.ShowFrequencyListForBlock(self.SelectedFrequencyBlock());
  };
};

var EventFrequencyChartVM = function (dataFunc, infoFreqFunc, locationIDs, scanColors) {
  var self = this;

  Common.LogConsole('EventFrequencyChartVM');

  self.DataFunc = dataFunc;
  self.InfoFrequencyFunc = infoFreqFunc;
  self.catBalloonText = '';
  self.graphsAdded = false;

  self.Refresh = function () {
    if (!self.graphsAdded) {
      $(locationIDs).each(function (o, id) {
        self.chart.graphs.push({
          id: 'location_' + id,
          type: 'line',
          lineColor: scanColors ? scanColors[id] : '#6a6b6d',
          lineThickness: 1,
          showBalloon: false,
          valueField: 'SignalStrength_' + id
        });
      });
      self.graphsAdded = true;
    }

    self.chart.dataProvider = self.DataFunc();
    self.chart.validateData();
  };

  self.chart = AmCharts.makeChart('chartdiv', {
    type: 'serial',
    categoryField: 'Frequency',
    columnSpacing: 0,
    columnWidth: 1,
    minMarginLeft: 30,
    minMarginRight: 20,
    marginBottom: 0,
    startDuration: 0, // 0.5,
    backgroundColor: '#fff',
    backgroundAlpha: 1,
    classNamePrefix: 'frequencyChart',
    mouseWheelScrollEnabled: true,
    zoomOutOnDataUpdate: false,
    addClassNames: true,
    categoryAxis: {
      gridPosition: 'middle',
      position: 'top',
      axisAlpha: 1,
      axisColor: '#999999',
      color: '#999999',
      axisThickness: 0,
      labelFrequency: 1,
      gridAlpha: 0,
      offset: 1,
      title: '',
      titleColor: '',
      minHorizontalGap: 120,
      autoGridCount: true
    },
    chartCursor: {
      enabled: true,
      animationDuration: 0.2,
      cursorPosition: 'middle',
      tabIndex: 0,
      categoryBalloonAlpha: 0.6,
      categoryBalloonFunction: function (freq) {
        return self.catBalloonText;
      }
    },
    chartScrollbar: {
      enabled: true,
      backgroundAlpha: 1,
      backgroundColor: '#fff',
      color: '#FF0000',
      dragIconHeight: 33,
      dragIconWidth: 33,
      graph: 'graphScrollBar',
      graphFillAlpha: FrequencyChartColors.ItemBaseAlpha,
      graphFillColor: '#FF0000',
      graphLineColor: '#FF0000',
      graphType: 'column',
      gridAlpha: 0,
      gridColor: '#FF8000',
      offset: 27,
      oppositeAxis: false,
      scrollbarHeight: 60,
      selectedBackgroundAlpha: 0,
      selectedGraphFillAlpha: FrequencyChartColors.ItemBaseAlpha * 2,
      updateOnReleaseOnly: true
    },
    trendLines: [],
    graphs: [
      {
        id: 'graphIntermod',
        clustered: false,
        backgroundColor: '#ff0000',
        columnWidth: 1,
        colorField: 'OwnColor',
        alphaField: 'OwnAlpha',
        //"fillAlphas": 0.5,
        lineAlpha: FrequencyChartColors.ItemBaseAlpha,
        lineThickness: 1,
        lineColorField: 'OwnBorder',
        //"negativeFillAlphas": 0,
        type: 'column',
        valueField: 'ImodValue',
        visibleInLegend: false,
        showBalloon: false
      },
      {
        id: 'graphFrequencies',
        clustered: false,
        backgroundColor: '#ff0000',
        colorField: 'Color',
        fillAlphas: FrequencyChartColors.ItemBaseAlpha * 2,
        lineAlpha: FrequencyChartColors.ItemBaseAlpha,
        lineThickness: 0,
        lineColorField: 'Color',
        negativeFillAlphas: 0,
        type: 'column',
        valueField: 'Value',
        visibleInLegend: false,
        showBalloon: false
      },
      {
        id: 'graphOwnFrequencies',
        clustered: false,
        backgroundColor: '#ff0000',
        columnWidth: 1,
        colorField: 'OwnColor',
        alphaField: 'OwnAlpha',
        //"fillAlphas": 0.5,
        lineAlpha: FrequencyChartColors.ItemBaseAlpha,
        lineThickness: 1,
        lineColorField: 'OwnBorder',
        //"negativeFillAlphas": 0,
        type: 'column',
        valueField: 'OwnValue',
        visibleInLegend: false,
        showBalloon: false
      },
      {
        id: 'graphScrollBar',
        colorField: 'SBColor',
        lineColorField: 'OwnBorder',
        lineThickness: 1,
        valueField: 'SBValue',
        backgroundColor: '#ff0000',
        type: 'column',
        showBalloon: false
      },
      {
        id: 'graphPending',
        clustered: false,
        fillColors: FrequencyChartColors.PendingCount,
        valueField: 'PendingValue',
        fillAlphas: FrequencyChartColors.ItemBaseAlpha,
        type: 'column',
        lineThickness: 0,
        showBalloon: false
      },
      {
        id: 'graphClicker',
        showBalloon: false,
        clustered: false,
        color: '#ff0000',
        valueField: 'MaxVal',
        fillAlphas: 0,
        type: 'column',
        lineThickness: 0,
        lineAlpha: 0,
        lineColor: '#ff0000',
        backgroundColor: '#ff0000'
      }
    ],
    guides: [],
    valueAxes: [
      {
        includeAllValues: true,
        axisFrequency: 0,
        axisTitleOffset: 0,
        id: 'ValueAxis-1',
        maximum: 100,
        precision: 0,
        synchronizationMultiplier: 0,
        autoGridCount: false,
        autoRotateAngle: -90,
        autoRotateCount: 0,
        axisAlpha: 0,
        axisColor: '',
        axisThickness: 0,
        boldPeriodBeginning: false,
        centerLabelOnFullPeriod: false,
        color: 'undefined',
        fontSize: 0,
        gridAlpha: 0,
        inside: true,
        markPeriodChange: false,
        minorGridAlpha: 0,
        tickLength: 0,
        title: '',
        titleColor: '#FFFFFF',
        titleFontSize: 0,
        titleRotation: 0
      }
    ],
    allLabels: [
      {
        text: '-20',
        color: '#999999',
        bold: false,
        x: 1,
        y: 80
      },
      {
        text: '-120',
        color: '#999999',
        bold: false,
        x: 1,
        y: 230
      }
    ],
    balloon: {
      animationDuration: 0.2,
      fadeOutDuration: 0.2,
      fixedPosition: false,
      fillAlpha: 1,
      fillColor: '#EEEEEE',
      pointerOrientation: 'right',
      pointerWidth: 30,
      shadowAlpha: 0,
      verticalPadding: 8,
      horizontalPadding: 8,
      maxWidth: 500
    },
    titles: [],
    dataProvider: self.DataFunc(),
    listeners: [
      {
        event: 'init',
        method: function (e) {
          var chart = e.chart;
          var screenX;
          self.freqTime = null;

          //capture screenX for changed event
          window.cursPos = false;
          $(chart.chartDiv).mousemove(function (event) {
            screenX = event.screenX;
            //console.log(event.pageX > $(window).width() / 2 + 25);
            if (event.pageX > $(window).width() / 2 + 25) {
              $('#conflictInfo').addClass('leftAlign');
              window.cursPos = true;
            } else if (event.pageX <= $(window).width() / 2 - 25) window.cursPos = false;
          });

          //add listner to chartCusor in init to ensure that the
          //object is created and accessible
          chart.chartCursor.addListener('changed', function (event) {
            if (event.index === undefined) {
              return;
            }
            var dataContext = event.chart.dataProvider[event.index];
            self.catBalloonText = dataContext.BalloonText;

            if (dataContext.ClickFunc) {
              // sets the present position of the cursor to the hovered frequency
              self.currentPos = dataContext.Frequency;
              // sets the variable 'x' to a setTimeout that we'll pass the parameters of the function call to
              self.freqTime = setTimeout(
                function (z, a, b) {
                  // z = the frequency passed to the call
                  // we're comparing it against what the actual currently-hovered frequency is
                  // think of z as the 'time capsule' of the freq hovered at the time of the timeout call
                  if (z == self.currentPos) self.InfoFrequencyFunc(z, a, b);
                },
                500,
                dataContext.Frequency,
                screenX,
                false
              );
            } else self.InfoFrequencyFunc();
          });

          //chart.addListener("rollOverGraphItem", function (event) {
          //   if (event.index === undefined) {
          //      return;
          //   }
          //   var dataContext = event.chart.dataProvider[event.index];
          //   if (dataContext.ClickFunc) {
          //      // sets the present position of the cursor to the hovered frequency
          //      self.currentPos = dataContext.Frequency;
          //      // sets the variable 'x' to a setTimeout that we'll pass the parameters of the function call to
          //      self.freqTime = setTimeout(function (z, a, b) {
          //         // z = the frequency passed to the call
          //         // we're comparing it against what the actual currently-hovered frequency is
          //         // think of z as the 'time capsule' of the freq hovered at the time of the timeout call
          //         if (z == self.currentPos)
          //            self.InfoFrequencyFunc(z, a, b);
          //      }, 500, dataContext.Frequency, screenX, false);
          //   }
          //   else
          //      self.InfoFrequencyFunc();
          //});

          $(chart.chartDiv).mouseout(function () {
            if (self.freqTime) clearTimeout(self.freqTime);
          });
        }
      }
    ]
  });

  self.zoomChart = function () {
    Common.LogConsole('zoomChart self.chart.dataProvider.length=' + self.chart.dataProvider.length);

    //var range = Math.round(self.chart.dataProvider.length * 0.2);
    //if (range < 20) range = Math.min(20, self.chart.dataProvider.length);
    //if (range < 50) range = Math.min(50, self.chart.dataProvider.length);
    //self.chart.zoomToIndexes(0, range);

    //self.chart.zoomToIndexes(0, self.chart.dataProvider.length);

    //self.chart.zoomToCategoryValues(519.55000, 530.95000);

    var start = 0;
    var end = 0;

    if (!isNaN(self.lastZoomStartPct) && isFinite(self.lastZoomStartPct) && self.lastZoomStartPct != -1) {
      //restore zoom
      start = Math.round(self.chart.dataProvider.length * self.lastZoomStartPct);
      end = Math.round(self.chart.dataProvider.length * self.lastZoomEndPct);
    } else {
      //use default zoom
      start = Math.round(self.chart.dataProvider.length * 0.25);
      end = Math.round(self.chart.dataProvider.length * 0.75);
      //if (start < 20) start = Math.min(20, self.chart.dataProvider.length);
      //if (end < 40) end = Math.min(40, self.chart.dataProvider.length);
    }

    Common.LogConsole('zoomChart to [' + start + ',' + end + ']');
    self.chart.zoomToIndexes(start, end);
  };

  self.ScrollToValue = function (value) {
    Common.LogConsole('ScrollToValue');

    var currZoomWidth = self.chart.endIndex - self.chart.startIndex;
    var newStart = self.chart.getCategoryIndexByValue(parseFloat(value)) - Math.floor(currZoomWidth / 2);

    if (newStart < 0) newStart = 0;

    if (newStart + currZoomWidth > self.chart.chartData.length - 1) newStart = self.chart.chartData.length - currZoomWidth - 1;

    self.chart.zoomToIndexes(newStart, newStart + currZoomWidth);

    // TODO: Some sort of animation/highlight for selected item?
  };

  self.chart.addListener('rendered', self.zoomChart);

  //self.chart.addListener('clickGraphItem', function (event) {
  //   if (event.item.dataContext.ClickFunc) event.item.dataContext.ClickFunc();
  //});

  // Added a timeout to the FrequencyChartVM object in order to enable timeout canceling
  self.freqTime = null;

  self.chart.addListener('rollOutGraph', function (event) {
    self.InfoFrequencyFunc();
  });

  self.chart.addListener('rightClickGraphItem', function (event) {
    //if (event.item.dataContext.ClickFunc)
    self.InfoFrequencyFunc(event.item.dataContext.Frequency, event.event.screenX, true);
    //else
    //   self.InfoFrequencyFunc();

    event.event.preventDefault();
    event.event.stopPropagation();
  });

  self.lastZoomStartPct = -1;
  self.lastZoomEndPct = -1;

  self.saveZoom = function () {
    Common.LogConsole('saveZoom');

    var start = self.chart.startIndex / self.chart.dataProvider.length;
    var end = self.chart.endIndex / self.chart.dataProvider.length;

    if (!isNaN(start) && isFinite(start) && !isNaN(end) && isFinite(end)) {
      self.lastZoomStartPct = start.toFixed(2);
      self.lastZoomEndPct = end.toFixed(2);

      Common.LogConsole('saveZoom lastZoomStartPct=' + self.lastZoomStartPct);
      Common.LogConsole('saveZoom lastZoomEndPct=' + self.lastZoomEndPct);
    }
  };

  self.chart.addListener('zoomed', function (event) {
    Common.LogConsole('zoomed to [' + event.startIndex + ',' + event.endIndex + ']');

    //{endDate:Date, endIndex:Number, endValue:String, startDate:Date, startIndex:Number, startValue:String, chart:AmChart}
    //self.lastZoomStartPct = (event.startIndex / self.chart.dataProvider.length).toFixed(2);
    //self.lastZoomEndPct = (event.endIndex / self.chart.dataProvider.length).toFixed(2);

    //Common.LogConsole('lastZoomStartPct=' + self.lastZoomStartPct);
    //Common.LogConsole('lastZoomEndPct=' + self.lastZoomEndPct);
  });

  self.zoomChart();
};

export default EventFrequenciesVM;
