import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var FrequencyBlockEditVM = function () {
  var self = this;
  if (RazorShared.isDebug) console.log('FrequencyBlockEditVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};

  self.FrequencyBlock = null;
  self.CantEdit = false;
  self.AddEditText = '';

  self.AdvancedMode = ko.observable(false);
  self.ToggleMode = function () {
    if (self.CanToggleMode()) self.AdvancedMode(!self.AdvancedMode());
  };

  self.CanToggleMode = ko.pureComputed(function () {
    return !self.FrequencyBlock.AnyAdvancedItems();
  });

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) {
      self.okCallback = params.okCallback;
    }

    self.FrequencyBlock = new FrequencyBlockVM(FrequencyBlockEditVM.jsonData.FrequencyBlock);

    self.AddEditText = params.clone
      ? 'Clone Frequency Block: '
      : self.FrequencyBlock.FrequencyBlockID
      ? 'Edit Frequency Block: '
      : 'Add Frequency Block';

    if (FrequencyBlockEditVM.jsonData.SimpleViewOnly) {
      self.CanToggleMode = ko.observable(false); // can't toggle - kill the observable function.
      if (self.FrequencyBlock.AnyAdvancedItems()) self.CantEdit = true;
    } else {
      if (self.FrequencyBlock.AnyAdvancedItems()) self.AdvancedMode(true);
    }

    ko.applyBindings(self, self.$container[0]);

    if (window.FormData == undefined) {
      Common.Dialog('WARNING', 'This browser does not support HTML5 file uploads. You may have errors when attempting to save.');
    }

    self.$form.on('paste', 'input.trapPaste', self.PastedFrequencyData);
  };

  self.Save = function () {
    if (!self.$form.validate().form()) return;

    var frequenciesValid = true;
    if (self.FrequencyBlock.DetailItems() && self.FrequencyBlock.DetailItems().length > 0) {
      self.FrequencyBlock.DetailItems().forEach(function (fb) {
        if (fb.StartGreaterThanEnd()) frequenciesValid = false;
      });
    }
    if (!frequenciesValid) return;

    var postData = new FormData();
    var prefix = 'FrequencyBlock.';
    var prefix2 = prefix + 'DetailItems';

    self.FrequencyBlock.BuildBlockData(function (key, value, detailIdx) {
      if (value === null || value === undefined) return;
      if (detailIdx < 0) {
        // main item
        postData.append(prefix + key, value);
      } else {
        postData.append(prefix2 + '[' + detailIdx + '][' + key + ']', value);
      }
    });

    if (self.FrequencyBlock.DocToUpload()) postData.append('uplFile', self.FrequencyBlock.DocToUpload());

    Common.BlockUI();
    $.post({
      url: RazorShared.baseUrl + 'FrequencyBlock/SaveFrequencyBlock',
      data: postData,
      processData: false,
      contentType: false
    })
      .done(function (data) {
        $.unblockUI();
        if (data.success) {
          self.$container.modal('hide');
          self.okCallback({
            ID: data.FrequencyBlock.FrequencyBlockID,
            Name: data.FrequencyBlock.Name
          });
        } else {
          Common.Dialog('Error saving Frequency Block', null, data.error);
        }
      })
      .fail(function (XMLHttpRequest, textStatus, errorThrown) {
        $.unblockUI();
        Common.Dialog('Save Frequency Block', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
      });
  };

  self.PastedFrequencyData = function (e) {
    var pasted = e.originalEvent.clipboardData.getData('text');
    var rowsRaw = pasted.split('\n');
    var rows = rowsRaw.map(function (r) {
      return r.split('\t');
    });

    if (rows.length == 0 || (rows.length == 1 && rows[0].length < 2)) return true; // not pasting a grid, so allow default behavior

    if (!e.currentTarget.value)
      // if the cell already has content, ignore the grid paste
      self.FrequencyBlock.AddGridData(rows);

    e.stopPropagation();
    e.preventDefault();
    return false;
  };
};

var FrequencyBlockVM = function (jsonData) {
  var self = this;

  self.FrequencyBlockID = jsonData.FrequencyBlockID || 0;
  self.Name = ko.observable(jsonData.Name || '');
  self.Notes = ko.observable(jsonData.Notes || '');
  self.ItemStatusID = ko.observable(jsonData.ItemStatusID || 0);
  self.FrequencyBlockCustomTypeID = ko.observable(jsonData.FrequencyBlockCustomTypeID || 0);
  self.DocumentLocation = ko.observable(jsonData.DocumentLocation || '');

  self.DetailItems = ko.observableArray(
    jsonData.DetailItems.map(function (i) {
      return new FrequencyBlockDetailVM(i);
    }) || []
  );

  if (self.DetailItems().length == 0) self.DetailItems.push(new FrequencyBlockDetailVM());

  self.DocToUpload = ko.observable(null);
  self.DocumentStored = ko.pureComputed(function () {
    return !self.DocToUpload() && self.DocumentLocation();
  });
  self.NoDocument = ko.pureComputed(function () {
    return !self.DocToUpload() && !self.DocumentLocation();
  });

  self.DocumentDisplayName = ko.pureComputed(function () {
    if (self.DocToUpload()) return self.ExtractFilename(self.DocToUpload().name);
    else if (self.DocumentLocation()) return self.ExtractFilename(self.DocumentLocation());
    else return '';
  });

  self.AnyAdvancedItems = ko.pureComputed(function () {
    return self.DetailItems().find(function (i) {
      return i.HasAdvancedSettings();
    });
  });

  self.GetDocumentLink = function () {
    const params = $.param({
      FrequencyBlockID: self.FrequencyBlockID,
      FileName: self.DocumentLocation()
    });
    return 'FrequencyBlock/GetDocument?' + params;
  };

  self.RemoveDocument = function () {
    self.DocumentLocation('');
    self.DocToUpload(null);
  };

  self.AddDetailItem = function () {
    self.DetailItems.push(new FrequencyBlockDetailVM());
  };

  self.RemoveDetailItem = function (item) {
    self.DetailItems.remove(item);
  };

  self.AddGridData = function (data) {
    if (!Array.isArray(data)) return;

    data.forEach(function (row) {
      var freq = Array.isArray(row) && row.length > 0 ? parseFloat(row[0]) : parseFloat(row);
      if (!isNaN(freq) && freq > 0) {
        var settings = Array.isArray(row) && row.length > 1 ? row[1] : '';

        var detailItem = self.DetailItems().find(function (di) {
          return !di.StartFrequency();
        });
        if (!detailItem) {
          detailItem = new FrequencyBlockDetailVM();
          self.DetailItems.push(detailItem);
        }
        detailItem.StartFrequency(freq);
        detailItem.SettingsInfoPreToken(settings);
      }
    });
  };

  self.BuildBlockData = function (addItemCallback) {
    var idx = 0;

    addItemCallback('FrequencyBlockID', self.FrequencyBlockID, -1);
    addItemCallback('Name', self.Name(), -1);
    addItemCallback('Notes', self.Notes(), -1);
    addItemCallback('ItemStatusID', self.ItemStatusID(), -1);
    addItemCallback('FrequencyBlockCustomTypeID', self.FrequencyBlockCustomTypeID(), -1);
    addItemCallback('DocumentLocation', self.DocumentLocation(), -1);

    var deadDetailItems = [];
    self.DetailItems.sort(function (a, b) {
      return a.StartFrequency() == b.StartFrequency() ? 0 : a.StartFrequency() < b.StartFrequency() ? -1 : 1;
    })().forEach(function (det) {
      if (det.StartFrequency()) {
        det.ItemNumber = idx;
        addItemCallback('FrequencyBlockDetailID', det.FrequencyBlockDetailID, idx);
        addItemCallback('FrequencyBlockID', det.FrequencyBlockID, idx);
        addItemCallback('ItemNumber', det.ItemNumber, idx);
        addItemCallback('StartFrequency', det.StartFrequency() || 0.0, idx);
        addItemCallback('EndFrequency', det.EndFrequency(), idx);
        addItemCallback('Step', det.Step(), idx);
        addItemCallback('Noncontinuous', det.Noncontinuous(), idx);
        addItemCallback('SettingsInfo', det.SettingsInfo(), idx);
        addItemCallback('SwitchStart', det.SwitchStart(), idx);
        addItemCallback('SwitchStep', det.SwitchStep(), idx);
        addItemCallback('SwitchShowAsHex', det.SwitchShowAsHex(), idx);
        addItemCallback('Notes', det.Notes(), idx);
        idx++;
      } else {
        deadDetailItems.push(det);
      }
    });

    deadDetailItems.forEach(function (item) {
      self.DetailItems.remove(item);
    });
  };

  self.PreviewFrequencies = function () {
    var data = new Object();
    data.DetailItems = new Array();
    self.BuildBlockData(function (key, value, detailIdx) {
      if (value === null || value === undefined) return;
      if (detailIdx < 0) {
        // main item
        data[key] = value;
      } else {
        while (data.DetailItems.length < detailIdx + 1) data.DetailItems.push(new Object());

        data.DetailItems[detailIdx][key] = value;
      }
    });

    DialogMgr.ShowFrequencyListForData(data);
  };

  self.ExtractFilename = function (path) {
    if (!path) return '';
    var x;
    x = path.lastIndexOf('/');
    if (x >= 0)
      // Unix/Web-based path
      return path.substr(x + 1);
    x = path.lastIndexOf('\\');
    if (x >= 0)
      // Windows-based path
      return path.substr(x + 1);
    return path; // just the file name
  };
};

var FrequencyBlockDetailVM = function (jsonData) {
  var self = this;

  if (!jsonData) jsonData = '';

  self.FrequencyBlockDetailID = jsonData.FrequencyBlockDetailID || 0;
  self.FrequencyBlockID = jsonData.FrequencyBlockID || 0;
  self.ItemNumber = jsonData.ItemNumber || 0;
  self.StartFrequency = ko.observable(jsonData.StartFrequency || null);
  self.EndFrequency = ko.observable(jsonData.EndFrequency || null);
  self.Step = ko.observable(jsonData.Step || null);
  self.Noncontinuous = ko.observable(jsonData.Noncontinuous || false);
  self.SwitchStart = ko.observable(jsonData.SwitchStart === undefined ? null : jsonData.SwitchStart);
  self.SwitchStep = ko.observable(jsonData.SwitchStep || null);
  self.SwitchShowAsHex = ko.observable(jsonData.SwitchShowAsHex || false);
  self.Notes = ko.observable(jsonData.Notes || '');

  self.SettingsInfoPreToken = ko.observable('');
  self.SettingsInfoPostToken = ko.observable('');
  if (jsonData.SettingsInfo) {
    var idx = jsonData.SettingsInfo.indexOf('{0}');
    if (idx >= 0) {
      self.SettingsInfoPreToken(jsonData.SettingsInfo.substr(0, idx).trim()); // up to token pos
      self.SettingsInfoPostToken(jsonData.SettingsInfo.substr(idx + 3).trim()); // after token, to end
    } else {
      self.SettingsInfoPreToken(jsonData.SettingsInfo);
    }
  }

  self.HasStep = ko.pureComputed(function () {
    return self.Step() || self.SwitchStep();
  });
  self.SettingsInfo = ko.pureComputed(function () {
    return self.SettingsInfoPreToken() + (self.HasStep() ? ' {0} ' + self.SettingsInfoPostToken() : '');
  });
  self.HasAdvancedSettings = ko.pureComputed(function () {
    return (
      self.EndFrequency() || self.HasStep() || self.Noncontinuous() || self.SwitchStart() || self.SwitchShowAsHex() || self.SettingsInfoPostToken()
    );
  });

  self.StartGreaterThanEnd = ko.pureComputed(function () {
    return parseFloat(self.StartFrequency()) > parseFloat(self.EndFrequency());
  });
};

export default FrequencyBlockEditVM;
