import Cookies from 'js-cookie';
import moment from 'moment/min/moment-with-locales';
import { JSPost } from './Common.js';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var EventMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 50;
  self.loadedCount = 0;
  self.cookieName = 'efcEventSearch';
  var searchCookie = Cookies.getJSON(self.cookieName);
  self.EventSearch = new EventSearch(searchCookie || jsonData.EventSearch, self.defaultListCount);
  self.EventSearchHold = new EventSearch(searchCookie || jsonData.EventSearch, self.defaultListCount);

  self.Areas = ko.observableArray(jsonData.Areas.sort(Common.StringSortFunc('Name')) || []);
  self.Statuses = ko.observableArray(jsonData.Statuses || []);

  self.LoadList = function (callback) {
    $('#loadMessage').show();
    $('#sectEventList').load(RazorShared.baseUrl + 'LocationMgmt/GetEventList', ko.toJS(self.EventSearch), function (response, status, xhr) {
      if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
      else self.loadedCount = $('#numLoaded').val();
      $('#loadMessage').hide();
    });
    Cookies.set(self.cookieName, ko.toJS(self.EventSearch), { expires: 30 });
  };

  self.ApplyFilters = function () {
    var temp = self.EventSearch;
    self.EventSearch = new EventSearch(ko.toJS(self.EventSearchHold), self.defaultListCount);
    if (self.EventSearchHold.StartEventDate() == null) self.EventSearch.StartEventDate(null);
    self.EventSearch.SortBy = temp.SortBy;
    self.EventSearch.SortDesc = temp.SortDesc;
    self.LoadList();
  };

  self.ListMore = function () {
    if (self.EventSearch.RetrieveCount > self.loadedCount)
      // already loaded everything
      return;
    self.EventSearch.RetrieveCount += self.defaultListCount;
    self.LoadList();
  };

  self.SetSort = function (sort) {
    if (self.EventSearch.SortBy == sort) {
      self.EventSearch.SortDesc = !self.EventSearch.SortDesc;
    } else {
      self.EventSearch.SortBy = sort;
      self.EventSearch.SortDesc = false;
    }
    self.LoadList();
  };

  self.ClearSearch = function () {
    self.EventSearch.ClearSearch(self.defaultListCount);
    self.EventSearchHold.ClearSearch(self.defaultListCount);
    self.LoadList();
  };

  self.EditEvent = function (eventID, name) {
    DialogMgr.EventAddEdit(eventID, self.LoadList);
  };

  self.AddEvent = function () {
    DialogMgr.EventAddEdit(0, self.LoadList);
  };

  self.ActivateEvent = function (id) {
    $.post(RazorShared.baseUrl + 'LocationMgmt/ActivateEvent/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error activating Event', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Activate Event', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.DeactivateEvent = function (id) {
    $.post(RazorShared.baseUrl + 'LocationMgmt/DeactivateEvent/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error deactivating Event', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Deactivate Event', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.AssignFrequencies = function (eventID) {
    var params = { eventID: eventID };
    JSPost(RazorShared.baseUrl + 'AssignWalkIns/', params);
  };

  self.setupSearchToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayToggled';
    var cookie = Cookies.get(toggleCookieName);
    if (cookie) $fld.click();

    $fld.on('displayToggled', function () {
      if ($(this).hasClass('toggled')) Cookies.set(toggleCookieName, 'toggled');
      else Cookies.remove(toggleCookieName);
    });
  };

  self.ShowEventFrequencyReportOptions = function (id) {
    DialogMgr.ShowFrequencyReportOptions(id, 'Frequency Report Options');
  };

  self.AddWalkInEquipment = function (eventID, eventName) {
    DialogMgr.AddWalkInEquipment(eventID);
  };

  //self.setCountryChangeTrigger = function ($fld) {
  //   self.LocationSearch.CountryIDs.subscribe(function (newValues) {
  //      if (newValues.length == 0) $fld.val(null);
  //      else if (newValues.indexOf(RazorShared.CountryID_USA) >= 0) $fld.val(RazorShared.CountryID_USA);
  //      else $fld.val(1);

  //      $fld.change();
  //   });
  //}
};

var EventSearch = function (jsonData, defaultCount) {
  var self = this;

  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
  self.RetrieveCount = defaultCount;
  self.RetrieveStartAt = jsonData.RetrieveStartAt || null;

  self.AreaIDs = ko.observableArray(jsonData.AreaIDs || []);
  self.StartEventDate = ko.observable(jsonData.StartEventDate || moment().format());
  self.EndEventDate = ko.observable(jsonData.EndEventDate || null);
  self.Statuses = ko.observableArray(jsonData.Statuses || [1]);

  self.ClearSearch = function (defaultCount) {
    if (defaultCount == undefined) return;

    self.AreaIDs([]);
    self.Statuses([1]);
    self.StartEventDate(null);
    self.EndEventDate(null);
    self.RetrieveCount = defaultCount;
    self.RetrieveStartAt = null;
  };
};

export default EventMgmtVM;
