import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Common } from './Common.js';

var NavigationVM = function () {
  var self = this;
  self.defaultCountryCode = ko.observable('US');

  self.SaveMyAccount = function () {
    var validMobile = true;
    var validPhone = true;
    var nonUSMobile = $('#NonUSMobile')[0].checked;
    var nonUSPhone = $('#NonUSPhone')[0].checked;
    var mobileNum = $('#Mobile').val();
    var phoneNum = $('#Phone').val();

    if (!nonUSMobile && mobileNum && mobileNum.length > 0) {
      var parseMobile = parsePhoneNumberFromString(mobileNum, self.defaultCountryCode());
      validMobile = parseMobile && parseMobile.isValid();
      if (!validMobile) {
        console.log('invalid');
        $('#mobileInvalid').show();
        $('#mobileRow').addClass('invRow');
      } else {
        $('#mobileInvalid').hide();
        $('#mobileRow').removeClass('invRow');
      }
    }

    if (!nonUSPhone && phoneNum && phoneNum.length > 0) {
      var parsePhone = parsePhoneNumberFromString(phoneNum, self.defaultCountryCode());
      validPhone = parsePhone && parsePhone.isValid();
      if (!validPhone) {
        console.log('invalid');
        $('#phoneInvalid').show();
        $('#phoneRow').addClass('invRow');
      } else {
        $('#phoneInvalid').hide();
        $('#phoneRow').removeClass('invRow');
      }
    }

    if (!$('#MyAccountForm').validate().form() || !validMobile || !validPhone) return;

    $.post(RazorShared.baseUrl + 'UserMgmt/SaveMyAccount', $('#MyAccountForm').serialize(), function (data) {
      if (!data.success) {
        Common.Dialog('Error updating My Account', null, data.error);
      } else {
        $('#divMyAccount').modal('hide');
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save My Account', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

export default NavigationVM;
