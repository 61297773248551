import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var StaticFrequencyAddEditVM = function () {
  var self = this;
  if (RazorShared.isDebug) console.log('StaticFrequencyAddEditVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};

  self.StaticFrequency = ko.observable(null);
  self.AvailManufacturers = ko.observableArray([]);
  self.AvailModels = ko.observableArray([]);
  self.AvailFrequencyBlocks = ko.observableArray([]);
  self.AvailCompanies = ko.observableArray([]);
  self.AvailContacts = ko.observableArray([]);
  self.AddAnother = ko.observable(false);
  self.CustomFrequencyBlock = ko.observable(null);
  self.ModelRepeaterPair = ko.observable(false);
  self.ModelRepeaterSplit = ko.observable(null);
  self.DisplayedModelSecurityMessage = ko.observable(false);

  self.FreqBlockBalloonText = ko.pureComputed(function () {
    return (self.CustomFrequencyBlock() ? 'Edit the' : 'Add a') + ' custom list of tunable frequencies for this equipment.';
  });

  self.FreqBlockButtonText = ko.pureComputed(function () {
    return (self.CustomFrequencyBlock() ? 'Edit' : 'Add') + ' Custom';
  });

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) {
      self.okCallback = params.okCallback;
    }
    if (params.addAnother) self.AddAnother(params.addAnother);

    self.load(StaticFrequencyAddEditVM.jsonData);
  };

  self.load = function (jsonData) {
    self.AvailManufacturers((jsonData.AvailManufacturers || []).sort(Common.StringSortFunc('Name')));
    self.AvailModels((jsonData.AvailModels || []).sort(Common.StringSortFunc('Name')));
    self.AvailCompanies(jsonData.AvailCompanies || []);
    self.AvailContacts(jsonData.AvailContacts || []);
    self.AvailContacts((jsonData.AvailContacts || []).sort(Common.StringSortFunc('Name')));
    self.CustomFrequencyBlock(jsonData.CustomFrequencyBlock || null);

    self.StaticFrequency = new StaticFrequencyVM(jsonData.StaticFrequency);

    // Get repeater info and set token field first before setting model info
    // Setting model info first is causing tokenfield to initialize twice and addtional pair freqs are added
    self.SetModelInfo(self.findModel(self.StaticFrequency.ModelID()), true);
    self.setupFrequencyTokenField($('#RequestedSFFrequencies'));
    ko.applyBindings(self, self.$container[0]);

    self.StaticFrequency.ManufacturerID.subscribe(function (manufID) {
      self.reloadAvailModels(manufID);
    });

    self.StaticFrequency.ModelID.subscribe(function (modelID) {
      var model = self.findModel(modelID);
      self.SetModelInfo(model);
    });
  };

  self.SetRepeaterPairInfo = function (id) {
    var m = self.findModel(self.StaticFrequency.ModelID());
    if (m) {
      self.ModelRepeaterPair(m.RepeaterPair);
      self.ModelRepeaterSplit(m.RepeaterSplit);
    }
  };

  self.reloadAvailModels = function (manufID) {
    self.AvailModels.removeAll();
    self.StaticFrequency.ModelID(null);

    if (!manufID) {
      return;
    }

    $.get(RazorShared.baseUrl + 'EquipmentModel/GetModelsForManufacturer', { manufID: manufID }, function (data) {
      if (data.success) {
        self.AvailModels(data.AvailModels.sort(Common.StringSortFunc('Name')));
      } else {
        Common.Dialog('Error finding models', null, data.error.split('|').join('</br>'));
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Models', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.SetModelInfo = function (model, initialLoad) {
    if (model) {
      self.AvailFrequencyBlocks(model.FrequencyBlocks);
      self.ModelRepeaterPair(model.RepeaterPair);
      self.ModelRepeaterSplit(model.RepeaterSplit);

      // Update equipment's secured frequency type based on model
      if (self.StaticFrequency.StaticFrequencyID == 0) self.StaticFrequency.SecureFrequencyTypeID(model.SecureFrequencyTypeID);
      else if (!initialLoad) {
        if (self.StaticFrequency.SecureFrequencyTypeID() != model.SecureFrequencyTypeID && !self.DisplayedModelSecurityMessage()) {
          Common.DialogWithCallback(
            'Update Static Equipment Security',
            'Changing the Model for this Static Frequency will also change its Secure Frequency Type. You may update the Secure Frequency Type manually if needed.',
            null,
            function () {
              self.StaticFrequency.SecureFrequencyTypeID(model.SecureFrequencyTypeID);
            }
          );
          self.DisplayedModelSecurityMessage(true);
        } else self.StaticFrequency.SecureFrequencyTypeID(model.SecureFrequencyTypeID);
      }

      if (model.CanShowDetail && !initialLoad) {
        self.StaticFrequency.Power(model.Power ? model.Power.toFixed(0) : '');
        self.StaticFrequency.Bandwidth(model.Bandwidth ? model.Bandwidth.toFixed(6) : '');
      }
    } else {
      self.StaticFrequency.FrequencyBlockID(null);
      self.AvailFrequencyBlocks([]);
    }

    if (self.CustomFrequencyBlock() && !self.findFreqBlock(self.CustomFrequencyBlock().ID))
      self.AvailFrequencyBlocks.push(self.CustomFrequencyBlock());
    if (self.AvailFrequencyBlocks().length == 1) self.StaticFrequency.FrequencyBlockID(self.AvailFrequencyBlocks()[0].ID);
  };

  self.AddNewUser = function (data, event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.UserAddEdit(0, null, self.NewUserAdded);
  };

  self.NewUserAdded = function (newUser) {
    if (newUser) {
      self.AvailContacts.push({
        ID: newUser.AppUserID,
        Name: newUser.FullName + '( ' + newUser.Company + ')'
      });
      self.AvailContacts().sort(Common.StringSortFunc('Name'));
      self.StaticFrequency.AppUserID(newUser.AppUserID);
    }
  };

  self.AddNewManufacturer = function (data, event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.ManufacturerAddEdit(0, self.NewManufacturerAdded);
  };

  self.NewManufacturerAdded = function (newManuf) {
    if (self.$focusOnReturn) self.$focusOnReturn = $('#ModelID').parent().find('button.dropdown-toggle');
    self.SetNewManufacturer(newManuf.ManufacturerID, newManuf.Name);
  };

  self.SetNewManufacturer = function (id, name) {
    if (!self.findManufacturer(id)) self.AvailManufacturers.push({ ID: id, Name: name });
    self.StaticFrequency.ManufacturerID(id);
  };

  self.findManufacturer = function (manufID) {
    if (!manufID) return null;
    return self.AvailManufacturers().find(function (x) {
      return x.ID == manufID;
    });
  };

  self.AddNewModel = function (data, event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.ModelAdd(self.NewModelAdded, self.StaticFrequency.ManufacturerID());
  };

  self.NewModelAdded = function (newModel) {
    self.SetNewManufacturer(newModel.ManufacturerID, newModel.ManufacturerName);
    if (self.$focusOnReturn) self.$focusOnReturn = $('#Frequency');

    if (!self.findModel(newModel.ModelID)) self.AvailModels.push(newModel);
    self.StaticFrequency.ModelID(newModel.ModelID);
  };

  self.findModel = function (modelID) {
    if (!modelID) return null;
    return self.AvailModels().find(function (x) {
      return x.ModelID == modelID;
    });
  };

  self.findFreqBlock = function (freqBlockID) {
    if (!freqBlockID) return null;
    return self.AvailFrequencyBlocks().find(function (x) {
      return x.ID == freqBlockID;
    });
  };

  self.Save = function () {
    let invalidFrequencies = $('div#equipFrequencies').find('div.token.invalid');

    if (invalidFrequencies.length > 0 || !self.$form.validate().form()) return;

    $.post(RazorShared.baseUrl + 'StaticFrequency/SaveStaticFrequency', ko.toJS(self.StaticFrequency), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving frequency', null, data.error);
      } else {
        $('#RequestedSFFrequencies').tokenfield('destroy');
        self.$container.modal('hide');
        self.okCallback();

        if (self.AddAnother() && data.StaticFreq.StaticFrequencyID) {
          self.$container.on('hidden.bs.modal', function (e) {
            DialogMgr.StaticFrequencyDuplicate(data.StaticFreq.StaticFrequencyID, self.okCallback);
          });
        }
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Static Frequency', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.ViewFrequencyBlock = function () {
    if (self.StaticFrequency.FrequencyBlockID()) DialogMgr.ShowFrequencyListForBlock(self.StaticFrequency.FrequencyBlockID());
  };

  self.AddEditFrequencyBlock = function (data, event) {
    self.$focusOnReturn = $(event.target);
    if (self.CustomFrequencyBlock()) DialogMgr.FrequencyBlockEdit(self.CustomFrequencyBlock().ID, self.FrequencyBlockAdded);
    else DialogMgr.FrequencyBlockAdd(self.FrequencyBlockAdded, 'R', '');
  };

  self.FrequencyBlockAdded = function (freqBlk) {
    if (self.$focusOnReturn) self.$focusOnReturn = $('#Fixed');

    if (self.CustomFrequencyBlock()) {
      self.AvailFrequencyBlocks.remove(self.CustomFrequencyBlock());
    }
    self.CustomFrequencyBlock(freqBlk);
    self.AvailFrequencyBlocks.push(freqBlk);
    self.StaticFrequency.FrequencyBlockID(freqBlk.ID);
  };

  self.frequencyInvalid = ko.observable(false);

  self.setupFrequencyTokenField = function ($fld) {
    var reString = '^\\s*0*(?=.*[1-9])\\d{1,7}(?:\\.\\d{0,6})?\\s*$'; //"^\d{1,7}(\.\d{0,5})?$";
    var re = new RegExp(reString);

    $fld.on('tokenfield:createtoken tokenfield:edittoken', function (event) {
      if (!$.isNumeric(event.attrs.value)) {
        event.preventDefault();
        return false;
      }

      event.attrs.label = parseFloat(event.attrs.value).toFixed(5);

      // check for duplicate
      if (
        $(this)
          .tokenfield('getTokens')
          .find(function (token) {
            return token.label == event.attrs.label;
          })
      ) {
        event.preventDefault();
        return false;
      }

      return true;
    });

    $fld.on('tokenfield:createdtoken', function (e) {
      var dataVal = Number(e.attrs.value);
      $(e.relatedTarget).attr('data-val', isNaN(dataVal) ? '' : dataVal);

      self.frequencyInvalid(false);
      if (!re.test(e.attrs.value)) {
        $(e.relatedTarget).addClass('invalid');
        self.frequencyInvalid(true);
      }

      self.StaticFrequency.AddFrequency(e.attrs.value, e.attrs.pairForFreq || null);

      if (
        !e.attrs.initToken &&
        !e.attrs.isRepeaterPair &&
        self.ModelRepeaterPair() &&
        self.ModelRepeaterSplit() &&
        self.StaticFrequency.FrequencyBlockID()
      ) {
        if (Number(e.attrs.value) && !isNaN(e.attrs.value)) {
          self.addFrequnecyPair($fld, self.StaticFrequency.ModelID(), self.StaticFrequency.FrequencyBlockID(), e.attrs.value);
        }
      }
    });

    $fld.on('tokenfield:removedtoken', function (e) {
      var pairFreq = self.StaticFrequency.RemoveFrequency(e.attrs.value);
      if (pairFreq) self.removeFrequencyPair($fld, pairFreq);
    });

    $fld.tokenfield({
      createTokensOnBlur: true,
      delimiter: [',', ' ']
    });

    $fld.tokenfield(
      'setTokens',
      self.StaticFrequency.Frequencies().map(function (f) {
        return { value: f.FrequencyDisplay(), initToken: true };
      })
    );
  };

  self.returnFocus = function () {
    if (self.$focusOnReturn) self.$focusOnReturn.focus();
    else self.$container.find('.modal-content').focus();
    self.$focusOnReturn = null;
  };

  self.addFrequnecyPair = function ($fld, modelID, frequencyBlockID, freq) {
    if (modelID != self.StaticFrequency.ModelID() || frequencyBlockID != self.StaticFrequency.FrequencyBlockID()) return;

    var params = {
      modelID: self.StaticFrequency.ModelID(),
      frequencyBlockID: self.StaticFrequency.FrequencyBlockID(),
      freq: freq
    };

    $.post(RazorShared.baseUrl + 'Request/GetFrequencyPairToAdd', params, function (data) {
      if (data.success && data.freqToAdd) {
        $fld.tokenfield('createToken', {
          value: data.freqToAdd.toString(),
          isRepeaterPair: true,
          pairForFreq: freq
        });
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Add Repeater Pair Frequency', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.removeFrequencyPair = function ($fld, pairFreq) {
    var pairToken = $("div.token[data-val='" + pairFreq + "']");

    if (pairToken && pairToken.length > 0 && $(pairToken).find('a')) $(pairToken).find('a').trigger('click');
  };
};

var StaticFrequencyVM = function (jsonData) {
  var self = this;

  self.StaticFrequencyID = jsonData.StaticFrequencyID || 0;
  self.AppUserID = ko.observable(jsonData.AppUserID || 0);
  self.StaticFrequencySetID = ko.observable(jsonData.StaticFrequencySetID || 0);

  self.Description = ko.observable(jsonData.Description || '');
  self.Notes = ko.observable(jsonData.Notes || '');

  self.ModelID = ko.observable(jsonData.ModelID || 0);
  self.ManufacturerID = ko.observable(jsonData.ManufacturerID || 0);
  self.FrequencyBlockID = ko.observable(jsonData.FrequencyBlockID || null);
  self.Fixed = ko.observable(jsonData.Fixed || false);

  self.Frequency = ko.observable(jsonData.Frequency || '');
  self.Frequencies = ko.observableArray(
    (jsonData.Frequencies || []).map(function (f) {
      return new StaticFrequencyFrequencyVM(f);
    })
  );
  self.PriorityBandwidth = ko.observable(jsonData.PriorityBandwidth || '');
  self.Power = ko.observable(jsonData.Power || '');
  self.Bandwidth = ko.observable(jsonData.Bandwidth || '');
  self.SignalTypeID = ko.observable(jsonData.SignalTypeID || 0);

  self.RequiredUseID = ko.observable(jsonData.RequiredUseID || 0);
  self.SignalTypeID = ko.observable(jsonData.SignalTypeID || 0);
  self.EquipmentTypeID = ko.observable(jsonData.EquipmentTypeID || 0);
  self.HowUsed = ko.observable(jsonData.HowUsed || '');
  self.SecureFrequencyTypeID = ko.observable(jsonData.SecureFrequencyTypeID || 0);

  self.StaticFrequencyContactID = ko.observable(jsonData.StaticFrequencyContactID || 0);
  self.ItemStatusID = ko.observable(jsonData.ItemStatusID || 0);

  self.AddEditText = jsonData.StaticFrequencyID ? 'Edit' : 'Add';

  self.AddFrequency = function (freq, freqPairValue) {
    freq = Number(freq);
    if (isNaN(freq)) return;
    if (
      !self.Frequencies().find(function (f) {
        return f.Frequency() == freq;
      })
    ) {
      var newFreq = new StaticFrequencyFrequencyVM(freq);
      self.Frequencies.push(newFreq);

      if (!freqPairValue) freqPairValue = Number(freqPairValue);

      if (freqPairValue && freqPairValue > 0) {
        var pairFreq = self.Frequencies().find(function (f) {
          return f.Frequency() == freqPairValue;
        });
        if (pairFreq) {
          newFreq.FrequencyPairWith = freqPairValue;
          pairFreq.FrequencyPairWith = freq;
        }
      }
    }
  };

  self.RemoveFrequency = function (freq) {
    var pairFreq;

    freq = Number(freq);
    if (isNaN(freq)) return;
    var exist = self.Frequencies().find(function (f) {
      return f.Frequency() == freq;
    });

    if (exist) {
      pairFreq = Number(exist.FrequencyPairWith);
      self.Frequencies.remove(exist);
    }

    return pairFreq;
  };
};

var StaticFrequencyFrequencyVM = function (data) {
  var self = this;
  if (typeof data === 'number') {
    self.StaticFrequencyFrequencyID = 0;
    self.StaticFrequencyID = 0;
    self.Frequency = ko.observable(data);
    self.FrequencyStatusID = ko.observable(0); // pending
    self.IsHidden = false;
    self.Notes = ko.observable('');
    self.FrequencyPairWith = null;
  } else {
    self.StaticFrequencyFrequencyID = data.StaticFrequencyFrequencyID || 0;
    self.StaticFrequencyID = data.StaticFrequencyID || 0;
    self.Frequency = ko.observable(data.Frequency || null);
    self.FrequencyStatusID = ko.observable(data.FrequencyStatusID || 0);
    self.IsHidden = data.IsHidden || false;
    self.Notes = ko.observable(data.Notes || '');
    self.FrequencyPairWith = data.FrequencyPairWith;
  }

  self.FrequencyDisplay = ko.pureComputed(function () {
    if (self.IsHidden) {
      return '*****';
    } else if (self.Frequency()) {
      return self.Frequency().toFixed(5);
    } else {
      return '';
    }
  });

  self.DisplayClass = ko.pureComputed(function () {
    switch (self.FrequencyStatusID()) {
      case 0:
        return 'pending';
      case 1:
        return 'approved';
      case 2:
        return 'assigned';
      case 3:
        return 'rejected';
      case 4:
        return 'partapproved';
      default:
        return '';
    }
  });

  self.IsAssigned = ko.pureComputed(function () {
    return [1, 2, 4].indexOf(self.FrequencyStatusID()) > -1;
  });
  self.IsPending = ko.pureComputed(function () {
    return self.FrequencyStatusID() == 0;
  });
  self.IsRejected = ko.pureComputed(function () {
    return self.FrequencyStatusID() == 3;
  });
  self.CanAddNote = ko.pureComputed(function () {
    return !self.Notes() && self.IsAssigned();
  });
};

export default StaticFrequencyAddEditVM;
