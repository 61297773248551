import moment from 'moment/min/moment-with-locales';
import { Common } from './Common.js';
import { ActivityVM } from './EventAddEdit.js';

var ActivityAddEditVM = function () {
  var self = this;
  if (RazorShared.isDebug) console.log('ActivityAddEditVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};

  self.Activity = ko.observable(null);
  self.AvailLocations = ko.observableArray([]);
  self.ItemStatuses = ko.observableArray([]);
  self.AddEditText = '';

  self.StartGameClockEnabled = ko.observable(true);
  self.EndGameClockEnabled = ko.observable(true);

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }
    if (params.okCallback) self.okCallback = params.okCallback;

    if (params.jsonInitData) self.load(ActivityAddEditVM.jsonData, params.jsonInitData);
    else self.load(ActivityAddEditVM.jsonData);
  };

  self.load = function (mvcData, jsData) {
    self.AddEditText = jsData && jsData.ActivityID ? 'Edit Activity: ' + jsData.Name : 'Add Activity';

    self.AvailLocations((mvcData.AvailLocations || []).sort(Common.StringSortFunc('Name')));
    self.ItemStatuses(mvcData.ItemStatuses || []);

    self.Activity = new ActivityVM(jsData);

    if (!jsData.ItemStatusDescription) self.updateStatusDescription(self.Activity.ItemStatusID());

    ko.applyBindings(self, self.$container[0]);

    self.checkIfStartClockIsPostGame(self.Activity.StartQtrID());
    self.checkIfEndClockIsPostGame(self.Activity.EndQtrID());

    self.Activity.LocationID.subscribe(function (locationID) {
      self.updateLocationName(locationID);
    });
    self.Activity.ItemStatusID.subscribe(function (itemStatusID) {
      self.updateStatusDescription(itemStatusID);
    });
    self.Activity.StartQtrID.subscribe(function (ID) {
      self.checkIfStartClockIsPostGame(ID);
    });
    self.Activity.EndQtrID.subscribe(function (ID) {
      self.checkIfEndClockIsPostGame(ID);
    });
  };

  self.updateStatusDescription = function (itemStatusID) {
    if (itemStatusID && self.ItemStatuses) {
      const selectedItemStatus = self.ItemStatuses().find(function (s) {
        return s.ID == itemStatusID;
      });

      if (selectedItemStatus) self.Activity.ItemStatusDescription = selectedItemStatus.Name;
    }
  };

  self.updateLocationName = function (locationID) {
    if (!locationID) return;

    var selectedLocation = self.AvailLocations().find(function (l) {
      return l.ID == locationID;
    });
    if (selectedLocation) self.Activity.LocationName(selectedLocation.Name);
  };

  self.Save = function () {
    if (!self.$form.validate().form()) return;

    self.$container.modal('hide');
    self.okCallback(ko.toJS(self.Activity));
  };

  self.AvailQuarters = ko.pureComputed(function () {
    return [
      {
        ID: '',
        Name: 'Select'
      },
      {
        ID: 14,
        Name: 'Q1'
      },
      {
        ID: 13,
        Name: 'Q2'
      },
      {
        ID: 12,
        Name: 'Halftime'
      },
      {
        ID: 11,
        Name: 'Q3'
      },
      {
        ID: 10,
        Name: 'Q4'
      },
      {
        ID: 1,
        Name: 'Post'
      }
    ];
  });

  self.AvailMinutes = ko.pureComputed(function () {
    return [
      {
        time: '0',
        disp: 'Select'
      },
      {
        time: '00:15:00',
        disp: '15:00'
      },
      {
        time: '00:14:00',
        disp: '14:00'
      },
      {
        time: '00:13:00',
        disp: '13:00'
      },
      {
        time: '00:12:00',
        disp: '12:00'
      },
      {
        time: '00:11:00',
        disp: '11:00'
      },
      {
        time: '00:10:00',
        disp: '10:00'
      },
      {
        time: '00:09:00',
        disp: '9:00'
      },
      {
        time: '00:08:00',
        disp: '8:00'
      },
      {
        time: '00:07:00',
        disp: '7:00'
      },
      {
        time: '00:06:00',
        disp: '6:00'
      },
      {
        time: '00:05:00',
        disp: '5:00'
      },
      {
        time: '00:04:00',
        disp: '4:00'
      },
      {
        time: '00:03:00',
        disp: '3:00'
      },
      {
        time: '00:02:00',
        disp: '2:00'
      },
      {
        time: '00:01:00',
        disp: '1:00'
      },
      {
        time: '00:00:00',
        disp: '0:00'
      }
    ];
  });

  self.StartGameTimeDisplay = ko.pureComputed(function () {
    return self.FormatDate(self.Activity.StartGameClock(), self.Activity.StartQtrID());
  });

  self.EndGameTimeDisplay = ko.pureComputed(function () {
    return self.FormatDate(self.Activity.EndGameClock(), self.Activity.EndQtrID());
  });

  self.checkIfStartClockIsPostGame = function (id) {
    var v = self.$container.find('#StartGameClock').parent().find('button.dropdown-toggle')[0];
    v.disabled = id == 1;
    self.StartGameClockEnabled = id != 1;
    if (id == 1) {
      self.$container.find('#StartGameClock').selectpicker('val', '00:00:00');
      self.Activity.StartGameClock('00:00:00');
    }
  };

  self.checkIfEndClockIsPostGame = function (id) {
    var v = self.$container.find('#EndGameClock').parent().find('button.dropdown-toggle')[0];
    v.disabled = id == 1;
    self.EndGameClockEnabled = id != 1;
    if (id == 1) {
      self.$container.find('#EndGameClock').selectpicker('val', '00:00:00');
      self.Activity.EndGameClock('00:00:00');
    }
  };

  self.FormatDate = function (clock, gameQtr) {
    if (!clock || !gameQtr) return '';
    var qtr = self.AvailQuarters().find(function (q) {
      return q.ID == gameQtr;
    });
    if (qtr) return qtr.Name + ' ' + moment().startOf('day').add(moment.duration(clock)).format('mm:ss');
    else return 'Not specified';
  };
};

export default ActivityAddEditVM;
