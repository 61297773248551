import Cookies from 'js-cookie';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var ContactMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 50;
  self.loadedCount = 0;
  self.cookieName = 'efcOnsiteContactSearch';
  var searchCookie = Cookies.getJSON(self.cookieName);
  self.ContactSearch = new ContactSearch(searchCookie || jsonData.ContactSearch, jsonData.AppUserID || -1, self.defaultListCount);

  self.Companies = ko.observableArray(jsonData.Companies);

  self.LoadList = function () {
    $('#loadMessage').show();
    $('#sectContactList').load(RazorShared.baseUrl + 'OnsiteContacts/GetContacts', ko.toJS(self.ContactSearch), function (response, status, xhr) {
      if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
      else self.loadedCount = $('#numLoaded').val();
      $('#loadMessage').hide();
    });
    Cookies.set(self.cookieName, ko.toJS(self.ContactSearch), { expires: 30 });
  };

  self.ListMore = function () {
    if (self.ContactSearch.RetrieveCount > self.loadedCount)
      // already loaded everything
      return;
    self.ContactSearch.RetrieveCount += self.defaultListCount;
    self.LoadList();
  };

  self.ClearSearch = function () {
    self.ContactSearch.ClearSearch(self.defaultListCount);
    self.LoadList();
  };

  self.SetSort = function (sort) {
    if (self.ContactSearch.SortBy == sort) {
      self.ContactSearch.SortDesc = !self.ContactSearch.SortDesc;
    } else {
      self.ContactSearch.SortBy = sort;
      self.ContactSearch.SortDesc = false;
    }
    self.LoadList();
  };

  self.EditContact = function (onsiteContactID, fullName) {
    DialogMgr.OnsiteContactEdit(onsiteContactID, 'Edit Contact: ' + fullName, self.LoadList);
  };

  self.AddContact = function () {
    DialogMgr.OnsiteContactAdd(self.ContactSearch.AppUserID, 'Add Contact', self.LoadList);
  };

  self.ActivateContact = function (onsiteContactID) {
    $.post(RazorShared.baseUrl + 'OnsiteContacts/ActivateContact/' + onsiteContactID, function (data) {
      if (!data.success) {
        Common.Dialog('Error activating contact', data.error);
      } else {
        self.LoadList();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Edit Contact', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.DeactivateContact = function (onsiteContactID) {
    $.post(RazorShared.baseUrl + 'OnsiteContacts/DeactivateContact/' + onsiteContactID, function (data) {
      if (!data.success) {
        Common.Dialog('Error deactivating contact', data.error);
      } else {
        self.LoadList();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Deactivate Contact', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.setupSearchToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayToggled';
    var cookie = Cookies.get(toggleCookieName);
    if (cookie) $fld.click();

    $fld.on('displayToggled', function () {
      if ($(this).hasClass('toggled')) Cookies.set(toggleCookieName, 'toggled');
      else Cookies.remove(toggleCookieName);
    });
  };

  self.UpdateAllSelecListSelectedItemSort = function () {
    Common.ShowSelectedItemsOnTop('#searchCompanyID');
  };
};

var ContactSearch = function (jsonData, appUserID, defaultCount) {
  var self = this;

  self.AppUserID = appUserID;

  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
  self.RetrieveCount = defaultCount;
  self.RetrieveStartAt = jsonData.RetrieveStartAt || null;

  self.NameLike = ko.observable(jsonData.NameLike || null);
  self.CompanyIDs = ko.observableArray(jsonData.CompanyIDs || []);
  self.IncludeInactive = ko.observable(jsonData.IncludeInactive || false);

  self.ClearSearch = function (defaultCount) {
    if (defaultCount == undefined) return;

    self.NameLike(null);
    self.CompanyIDs([]);
    self.IncludeInactive(false);

    self.RetrieveCount = defaultCount;
    self.RetrieveStartAt = null;
  };
};

export default ContactMgmtVM;
