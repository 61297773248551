import { Common } from './Common.js';

var FrequencyChartColors = {
  ColorOpen: '#f3f3f3',
  ColorTVHigh: '#3acaee',
  ColorTVLow: '#a6e5f5',
  ColorOverlap: '#f5d6a6',
  ColorTaken: '#ef8b8b',
  ColorImodInRange: '#505151',

  StrokeWithin: '#a752ff',
  StrokeAssigned: '#52d4ff',
  StrokePending: '#d18700',

  PendingCount: '#52ff56',

  ContinuityDivider: '#000000',

  ItemBaseAlpha: 0.4 // not more than 0.5, since it gets doubled in some places
};

var FrequencyChartVM = function (dataFunc, infoFreqFunc, locationIDs, scanColors) {
  var self = this;

  Common.LogConsole('FrequencyChartVM');

  self.DataFunc = dataFunc;
  self.InfoFrequencyFunc = infoFreqFunc;
  self.catBalloonText = '';
  self.graphsAdded = false;

  self.Refresh = function () {
    if (!self.graphsAdded) {
      $(locationIDs).each(function (o, id) {
        self.chart.graphs.push({
          id: 'location_' + id,
          type: 'line',
          lineColor: scanColors ? scanColors[id] : '#6a6b6d',
          lineThickness: 1,
          showBalloon: false,
          valueField: 'SignalStrength_' + id
        });
      });
      self.graphsAdded = true;
    }

    self.chart.dataProvider = self.DataFunc();
    self.chart.validateData();
  };

  self.chart = AmCharts.makeChart('chartdiv', {
    type: 'serial',
    categoryField: 'Frequency',
    columnSpacing: 0,
    columnWidth: 1,
    minMarginLeft: 30,
    minMarginRight: 20,
    marginBottom: 0,
    startDuration: 0, // 0.5,
    backgroundColor: '#fff',
    backgroundAlpha: 1,
    classNamePrefix: 'frequencyChart',
    mouseWheelScrollEnabled: true,
    zoomOutOnDataUpdate: false,
    addClassNames: true,
    categoryAxis: {
      gridPosition: 'middle',
      position: 'top',
      axisAlpha: 1,
      axisColor: '#999999',
      color: '#999999',
      axisThickness: 0,
      labelFrequency: 1,
      gridAlpha: 0,
      offset: 1,
      title: '',
      titleColor: '',
      minHorizontalGap: 120,
      autoGridCount: true
    },
    chartCursor: {
      enabled: true,
      animationDuration: 0.2,
      cursorPosition: 'middle',
      tabIndex: 0,
      categoryBalloonAlpha: 0.6,
      categoryBalloonFunction: function (freq) {
        return self.catBalloonText;
      }
    },
    chartScrollbar: {
      enabled: true,
      backgroundAlpha: 1,
      backgroundColor: '#fff',
      color: '#FF0000',
      dragIconHeight: 33,
      dragIconWidth: 33,
      graph: 'graphScrollBar',
      graphFillAlpha: FrequencyChartColors.ItemBaseAlpha,
      graphFillColor: '#FF0000',
      graphLineColor: '#FF0000',
      graphType: 'column',
      gridAlpha: 0,
      gridColor: '#FF8000',
      offset: 27,
      oppositeAxis: false,
      scrollbarHeight: 60,
      selectedBackgroundAlpha: 0,
      selectedGraphFillAlpha: FrequencyChartColors.ItemBaseAlpha * 2,
      updateOnReleaseOnly: true
    },
    trendLines: [],
    graphs: [
      {
        id: 'graphIntermod',
        clustered: false,
        backgroundColor: '#ff0000',
        columnWidth: 1,
        colorField: 'OwnColor',
        alphaField: 'OwnAlpha',
        //"fillAlphas": 0.5,
        lineAlpha: FrequencyChartColors.ItemBaseAlpha,
        lineThickness: 1,
        lineColorField: 'OwnBorder',
        //"negativeFillAlphas": 0,
        type: 'column',
        valueField: 'ImodValue',
        visibleInLegend: false,
        showBalloon: false
      },
      {
        id: 'graphFrequencies',
        clustered: false,
        backgroundColor: '#ff0000',
        colorField: 'Color',
        fillAlphas: FrequencyChartColors.ItemBaseAlpha * 2,
        lineAlpha: FrequencyChartColors.ItemBaseAlpha,
        lineThickness: 0,
        lineColorField: 'Color',
        negativeFillAlphas: 0,
        type: 'column',
        valueField: 'Value',
        visibleInLegend: false,
        showBalloon: false
      },
      {
        id: 'graphOwnFrequencies',
        clustered: false,
        backgroundColor: '#ff0000',
        columnWidth: 1,
        colorField: 'OwnColor',
        alphaField: 'OwnAlpha',
        //"fillAlphas": 0.5,
        lineAlpha: FrequencyChartColors.ItemBaseAlpha,
        lineThickness: 1,
        lineColorField: 'OwnBorder',
        //"negativeFillAlphas": 0,
        type: 'column',
        valueField: 'OwnValue',
        visibleInLegend: false,
        showBalloon: false
      },
      {
        id: 'graphScrollBar',
        colorField: 'SBColor',
        lineColorField: 'OwnBorder',
        lineThickness: 1,
        valueField: 'SBValue',
        backgroundColor: '#ff0000',
        type: 'column',
        showBalloon: false
      },
      {
        id: 'graphPending',
        clustered: false,
        fillColors: FrequencyChartColors.PendingCount,
        valueField: 'PendingValue',
        fillAlphas: FrequencyChartColors.ItemBaseAlpha,
        type: 'column',
        lineThickness: 0,
        showBalloon: false
      },
      {
        id: 'graphClicker',
        showBalloon: false,
        clustered: false,
        color: '#ff0000',
        valueField: 'MaxVal',
        fillAlphas: 0,
        type: 'column',
        lineThickness: 0,
        lineAlpha: 0,
        lineColor: '#ff0000',
        backgroundColor: '#ff0000'
      }
    ],
    guides: [],
    valueAxes: [
      {
        includeAllValues: true,
        axisFrequency: 0,
        axisTitleOffset: 0,
        id: 'ValueAxis-1',
        maximum: 100,
        precision: 0,
        synchronizationMultiplier: 0,
        autoGridCount: false,
        autoRotateAngle: -90,
        autoRotateCount: 0,
        axisAlpha: 0,
        axisColor: '',
        axisThickness: 0,
        boldPeriodBeginning: false,
        centerLabelOnFullPeriod: false,
        color: 'undefined',
        fontSize: 0,
        gridAlpha: 0,
        inside: true,
        markPeriodChange: false,
        minorGridAlpha: 0,
        tickLength: 0,
        title: '',
        titleColor: '#FFFFFF',
        titleFontSize: 0,
        titleRotation: 0
      }
    ],
    allLabels: [
      {
        text: '-20',
        color: '#999999',
        bold: false,
        x: 1,
        y: 80
      },
      {
        text: '-120',
        color: '#999999',
        bold: false,
        x: 1,
        y: 230
      }
    ],
    balloon: {
      animationDuration: 0.2,
      fadeOutDuration: 0.2,
      fixedPosition: false,
      fillAlpha: 1,
      fillColor: '#EEEEEE',
      pointerOrientation: 'right',
      pointerWidth: 30,
      shadowAlpha: 0,
      verticalPadding: 8,
      horizontalPadding: 8,
      maxWidth: 500
    },
    titles: [],
    dataProvider: self.DataFunc(),
    listeners: [
      {
        event: 'init',
        method: function (e) {
          var chart = e.chart;
          var screenX;
          self.freqTime = null;

          //capture screenX for changed event
          window.cursPos = false;
          $(chart.chartDiv).mousemove(function (event) {
            screenX = event.screenX;
          });

          //add listner to chartCusor in init to ensure that the
          //object is created and accessible
          chart.chartCursor.addListener('changed', function (event) {
            if (event.index === undefined) {
              return;
            }
            var dataContext = event.chart.dataProvider[event.index];
            self.catBalloonText = dataContext.BalloonText;

            if (dataContext.ClickFunc) {
              // sets the present position of the cursor to the hovered frequency
              self.currentPos = dataContext.Frequency;
              // sets the variable 'x' to a setTimeout that we'll pass the parameters of the function call to
              self.freqTime = setTimeout(
                function (z, a, b) {
                  // z = the frequency passed to the call
                  // we're comparing it against what the actual currently-hovered frequency is
                  // think of z as the 'time capsule' of the freq hovered at the time of the timeout call
                  if (z == self.currentPos) self.InfoFrequencyFunc(z, a, b);
                },
                500,
                dataContext.Frequency,
                screenX,
                false
              );
            } else self.InfoFrequencyFunc();
          });

          //chart.chartCursor.addListener("changed", function (event) {
          //   if (event.index === undefined) {
          //      return;
          //   }
          //   var dataContext = event.chart.dataProvider[event.index];
          //   self.catBalloonText = dataContext.BalloonText;
          //});

          //chart.addListener("rollOverGraphItem", function (event) {
          //   if (event.index === undefined) {
          //      return;
          //   }
          //   var dataContext = event.chart.dataProvider[event.index];
          //   if (dataContext.ClickFunc) {
          //      // sets the present position of the cursor to the hovered frequency
          //      self.currentPos = dataContext.Frequency;
          //      // sets the variable 'x' to a setTimeout that we'll pass the parameters of the function call to
          //      self.freqTime = setTimeout(function (z, a, b) {
          //         // z = the frequency passed to the call
          //         // we're comparing it against what the actual currently-hovered frequency is
          //         // think of z as the 'time capsule' of the freq hovered at the time of the timeout call
          //         if(z == self.currentPos)
          //            self.InfoFrequencyFunc(z,a,b);
          //      }, 500, dataContext.Frequency, screenX, false);
          //   }
          //   else
          //      self.InfoFrequencyFunc();
          //});

          $(chart.chartDiv).mouseout(function () {
            if (self.freqTime) clearTimeout(self.freqTime);
          });
        }
      }
    ]
  });

  self.zoomChart = function () {
    Common.LogConsole('zoomChart self.chart.dataProvider.length=' + self.chart.dataProvider.length);

    //var range = Math.round(self.chart.dataProvider.length * 0.2);
    //if (range < 20) range = Math.min(20, self.chart.dataProvider.length);
    //if (range < 50) range = Math.min(50, self.chart.dataProvider.length);
    //self.chart.zoomToIndexes(0, range);

    //self.chart.zoomToIndexes(0, self.chart.dataProvider.length);

    //self.chart.zoomToCategoryValues(519.55000, 530.95000);

    var start = 0;
    var end = 0;

    if (!isNaN(self.lastZoomStartPct) && isFinite(self.lastZoomStartPct) && self.lastZoomStartPct != -1) {
      //restore zoom
      start = Math.round(self.chart.dataProvider.length * self.lastZoomStartPct);
      end = Math.round(self.chart.dataProvider.length * self.lastZoomEndPct);
    } else {
      //use default zoom
      start = Math.round(self.chart.dataProvider.length * 0.25);
      end = Math.round(self.chart.dataProvider.length * 0.75);
      //if (start < 20) start = Math.min(20, self.chart.dataProvider.length);
      //if (end < 40) end = Math.min(40, self.chart.dataProvider.length);
    }

    Common.LogConsole('zoomChart to [' + start + ',' + end + ']');
    self.chart.zoomToIndexes(start, end);
  };

  self.ScrollToValue = function (value) {
    Common.LogConsole('ScrollToValue');

    var currZoomWidth = self.chart.endIndex - self.chart.startIndex;
    var newStart = self.chart.getCategoryIndexByValue(parseFloat(value)) - Math.floor(currZoomWidth / 2);

    if (newStart < 0) newStart = 0;

    if (newStart + currZoomWidth > self.chart.chartData.length - 1) newStart = self.chart.chartData.length - currZoomWidth - 1;

    self.chart.zoomToIndexes(newStart, newStart + currZoomWidth);

    // TODO: Some sort of animation/highlight for selected item?
  };

  self.chart.addListener('rendered', self.zoomChart);

  self.chart.addListener('clickGraphItem', function (event) {
    if (event.item.dataContext.ClickFunc) event.item.dataContext.ClickFunc();
  });

  // Added a timeout to the FrequencyChartVM object in order to enable timeout canceling
  self.freqTime = null;

  self.chart.addListener('rollOutGraph', function (event) {
    self.InfoFrequencyFunc();
  });

  self.chart.addListener('rightClickGraphItem', function (event) {
    if (event.item.dataContext.ClickFunc) self.InfoFrequencyFunc(event.item.dataContext.Frequency, event.event.screenX, true);
    else self.InfoFrequencyFunc();

    event.event.preventDefault();
    event.event.stopPropagation();
  });

  self.lastZoomStartPct = -1;
  self.lastZoomEndPct = -1;

  self.saveZoom = function () {
    Common.LogConsole('saveZoom');

    var start = self.chart.startIndex / self.chart.dataProvider.length;
    var end = self.chart.endIndex / self.chart.dataProvider.length;

    if (!isNaN(start) && isFinite(start) && !isNaN(end) && isFinite(end)) {
      self.lastZoomStartPct = start.toFixed(2);
      self.lastZoomEndPct = end.toFixed(2);

      Common.LogConsole('saveZoom lastZoomStartPct=' + self.lastZoomStartPct);
      Common.LogConsole('saveZoom lastZoomEndPct=' + self.lastZoomEndPct);
    }
  };

  self.chart.addListener('zoomed', function (event) {
    Common.LogConsole('zoomed to [' + event.startIndex + ',' + event.endIndex + ']');

    //{endDate:Date, endIndex:Number, endValue:String, startDate:Date, startIndex:Number, startValue:String, chart:AmChart}
    //self.lastZoomStartPct = (event.startIndex / self.chart.dataProvider.length).toFixed(2);
    //self.lastZoomEndPct = (event.endIndex / self.chart.dataProvider.length).toFixed(2);

    //Common.LogConsole('lastZoomStartPct=' + self.lastZoomStartPct);
    //Common.LogConsole('lastZoomEndPct=' + self.lastZoomEndPct);
  });

  self.zoomChart();
};

export { FrequencyChartColors, FrequencyChartVM };
