import autosize from 'autosize';
import moment from 'moment/min/moment-with-locales';
import { DecodeHTML } from './Common.js';
import { RequestStatus, EquipmentStatus } from './AssignFrequenciesEntities.js';

var RequestVM = function (data, forDashboard) {
  var self = this;
  self.RequestID = data.RequestID || 0;
  self.AppUserID = ko.observable(data.AppUserID || 0);

  self.RequestStatusID = ko.observable(data.RequestStatusID || 0);
  self.RequestStatusDescr = ko.observable(data.RequestStatusDescr || '');

  self.AreaID = ko.observable(data.AreaID || 0);
  self.VenueID = ko.observable(data.VenueID || 0);
  self.EventID = ko.observable(data.EventID || 0);
  self.CompanyID = ko.observable(data.CompanyID || 0);

  self.AppUserName = ko.observable(data.AppUserName || '');
  self.CompanyName = ko.observable(data.CompanyName || '');
  self.EventName = ko.observable(data.EventName || '');
  self.VenueName = ko.observable(data.VenueName || '');
  self.EventDate = ko.observable(data.EventDate || null);
  self.RequestDate = ko.observable(data.RequestDate || null);
  self.EventDateDisplay = self.EventDate() != null ? moment(self.EventDate()).format('M/D/YYYY') : '';
  self.RequestDateDisplay = self.RequestDate() != null ? moment(self.RequestDate()).format('M/D/YYYY') : '';

  self.RequestEquipments = ko.observableArray(
    data.RequestEquipments.map(function (e, idx) {
      return new RequestEquipmentVM(e, idx);
    }) || []
  );
  self.RequestNotes = ko.observableArray(
    data.RequestNotes.map(function (n) {
      return new RequestNoteVM(n);
    }) || []
  );
  self.RequestActivities = ko.observableArray(
    data.RequestActivities.map(function (ra) {
      return new RequestActivityVM(ra);
    }) || []
  );
  self.RequestOnsiteContacts = ko.observableArray(
    data.RequestOnsiteContacts.map(function (c) {
      return new RequestOnsiteContactVM(c);
    }) || []
  );
  self.CurrUserCanCreateEventInPast = data.CanCreateEventInPast;
  self.SavingSubmittedRequest = ko.observable(false);

  // For Dashboard
  if (forDashboard) {
    self.ReqeustFromStatic = data.ReqeustFromStatic;
    self.StaticSetName = data.StaticSetName;
    self.AppUserCompany = data.AppUserCompany;
    self.AppUserEmail = data.AppUserEmail;
    self.AppUserMobile = data.AppUserMobile;
    self.ConflictProcessed = ko.observable(data.ApprovedFrequenciesCount == 0 ? true : false);
    self.FilteredEquipments = ko.observableArray(
      data.FilteredEquipments.map(function (e, idx) {
        return new RequestEquipmentVM(e, idx);
      }) || []
    );

    self.OtherEquipmentsCount = ko.pureComputed(function () {
      if (self.RequestEquipments().length > self.FilteredEquipments().length)
        return self.RequestEquipments().length - self.FilteredEquipments().length;
      else return 0;
    });

    self.CanDeleteCompletedRequest = data.CanDeleteCompletedRequest;
    self.CanEditRequestOnsiteContacts = data.CanEditRequestOnsiteContacts;

    self.HideRequestContactInfo = ko.pureComputed(function () {
      return self.RequestEquipments().some(function (e) {
        return e.CanShowDetails == false;
      });
    });

    self.totalRequestNotes = ko.pureComputed(function () {
      var totalNotes = self.RequestNotes().length;
      self.RequestEquipments().forEach(function (e) {
        totalNotes += e.RequestNotes().length;
      });
      return totalNotes;
    });

    self.requestStatusClass = ko.pureComputed(function () {
      return self.RequestStatusDescr();
    });

    self.isStatusDraft = ko.pureComputed(function () {
      return self.RequestStatusID() == RequestStatus.Draft;
    });
    self.isStatusSubmitted = ko.pureComputed(function () {
      return self.RequestStatusID() == RequestStatus.Submitted;
    });
    self.isStatusInProcess = ko.pureComputed(function () {
      return self.RequestStatusID() == RequestStatus.InProcess;
    });
    self.isStatusCompleted = ko.pureComputed(function () {
      return self.RequestStatusID() == RequestStatus.Completed;
    });
    self.isStatusCheckedIn = ko.pureComputed(function () {
      return self.RequestStatusID() == RequestStatus.CheckedIn;
    });
    self.isStatusCancelled = ko.pureComputed(function () {
      return self.RequestStatusID() == RequestStatus.Cancelled;
    });
  } // End for Dashboard
};

var RequestNoteVM = function (data, _tracker, isNew, saveFunc) {
  var self = this;
  self.EditMode = ko.observable(false);
  self.OrigText = '';
  self.SaveFunc = saveFunc;
  self.UpdatedEquipmentFirst = false;
  isNew = isNew || false;

  self.RequestID = data.RequestID || 0;
  self.RequestEquipmentID = ko.observable(data.RequestEquipmentID || null);
  self._equipmentTrack = ko.observable(-1);
  if (_tracker != undefined && !isNew) {
    self._equipmentTrack(_tracker);
  }
  if (self._equipmentTrack() == -1 && data._equipmentTrack != undefined && !isNew) {
    self._equipmentTrack(data._equipmentTrack);
  }
  self.SelectedRequestEquipment = ko.observable();

  self.DecodeHTML = function (noteText) {
    return DecodeHTML(noteText);
  };
  self.Note = ko.observable(DecodeHTML(data.Note) || '');

  // RazorShared.currUserID and RazorShared.currUserFullName are global js vars set within _Layout.cshtml
  if (isNew) {
    self.RequestNoteID = 0;
    self.NoteDate = ko.observable(null);
    self.NoteUserName = RazorShared.currUserFullName || '';
    self.NoteUserID = RazorShared.currUserID || 0;
    self.IsCurrentUser = true;
  } else {
    self.RequestNoteID = data.RequestNoteID || 0;
    self.NoteDate = ko.observable(data.NoteCreateDate || data.NoteDate || null);
    self.NoteUserName = data.NoteUserName || '';
    self.NoteUserID = data.NoteUserID || 0;
    self.IsCurrentUser = self.NoteUserID == RazorShared.currUserID;
  }

  self.SetSaveFunc = function (saveFunc) {
    self.SaveFunc = saveFunc;
  };

  self.TemplateType = ko.pureComputed(function () {
    if (self.EditMode()) return 'editNote';
    if (self.IsCurrentUser) return 'ownNote';
    return 'otherNote';
  });

  self.SetEdit = function (a, b, c) {
    self.OrigText = self.Note();
    self.EditMode(true);
    self.SelectedRequestEquipment(a);
  };

  self.SaveEdit = function (showEquipment, note) {
    if (note != undefined && note.Note().trim().length == 0) {
      return false;
    }

    if (note != undefined && showEquipment) {
      if (typeof self.SaveFunc == 'function' && self.UpdatedEquipmentFirst) {
        self.SaveFunc(note, self.SelectedRequestEquipment());
      }

      if (self.SelectedRequestEquipment() != undefined) {
        self.RequestEquipmentID(self.SelectedRequestEquipment().ID);
        self._equipmentTrack(self.SelectedRequestEquipment()._equipmentTrack);
      } else {
        self.RequestEquipmentID(null);
        self._equipmentTrack(-1);
      }

      if (typeof self.SaveFunc == 'function' && !self.UpdatedEquipmentFirst) {
        self.SaveFunc(note);
      }
    }
    self.EditMode(false);
  };

  self.CancelEdit = function () {
    self.Note(self.OrigText);
    self.EditMode(false);
  };

  self.NoteDateDisplay = ko.pureComputed(function () {
    return self.NoteDate() ? moment(self.NoteDate()).format('llll') : 'New';
  });
};

var RequestNoteListVM = function (createFunc, saveFunc, removeFunc) {
  var self = this;
  if (RazorShared.isDebug) console.log('RequestNoteListVM locator');
  self.CreateFunc = createFunc;
  self.SaveFunc = saveFunc;
  self.RemoveFunc = removeFunc;
  self.ReadOnly = false;
  self.EquipmentList = ko.observableArray([]);
  self.NotesShowEquip = ko.pureComputed(function () {
    return self.EquipmentList && self.EquipmentList().length > 0;
  });
  self.ShowAllEquipmentNotes = ko.observable(true);
  self.NotesForEquipmentID = ko.observable(null);
  self.FilteredEquipments = ko.observableArray([]);
  self.RequestConfirmationView = ko.observable(false);

  self.PendingNote = ko.observable(null);
  self.RequestNotes = ko.observableArray([]);
  self.RemovedNoteIDs = [];

  self.NoEquipMessage = '(No Specific Equipment)';

  self.SelectedRequestEquipment = ko.observable();

  self.LoadEquipment = function (equipList) {
    self.EquipmentList([]);
    equipList.forEach(function (n) {
      self.EquipmentList.push(n);
    });
    //self.NotesShowEquip = (equipList && equipList.length > 0);
  };

  self.FilteredEquipment = ko.pureComputed(function () {
    if (!self.NotesForEquipmentID() || self.NotesForEquipmentID() < 1) return [];

    return self.EquipmentList().filter(function (e) {
      return e.ID == self.NotesForEquipmentID();
    });
  });

  self.EquipmentName = function (equipID) {
    var equip = null;
    var _trackEquipID;
    var checkForTrack = self.EquipmentList().length > 0 && self.EquipmentList()[0]['_equipmentTrack'] != undefined && arguments.length > 1;

    if (checkForTrack) _trackEquipID = arguments[1];

    if (equipID != 0)
      equip = self.EquipmentList().find(function (x) {
        return x.ID == equipID;
      });

    if (!equip && checkForTrack && _trackEquipID != -1) {
      equip = self.EquipmentList().find(function (x) {
        return x._equipmentTrack == _trackEquipID;
      });
    }

    if (!equip) return self.NoEquipMessage;
    return equip.Name;
  };

  self.LoadList = function (list, readOnly) {
    self.ReadOnly = readOnly;
    // observableArray or Array
    if (ko.isObservable(list)) self.RequestNotes(list());
    //  self.RequestNotes = list;    -- can't do this since it will unbind
    else self.RequestNotes(list);

    if (!readOnly) self.NewNote();

    setTimeout(self.checkNoteHeight, 700);
    setTimeout(self.scrollNote, 700);
    // alert('test');
  };

  self.NewNote = function () {
    self.PendingNote(self.CreateFunc());
  };

  self.ShowEquipmentNote = function (equipmentID) {
    if (self.ShowAllEquipmentNotes() || !equipmentID) return true;
    else return self.NotesForEquipmentID() == equipmentID;
  };

  self.GetSelectedRequestEquipment = function (equipid, trackid) {
    var equipment;
    equipment = self.EquipmentList().find(function (e) {
      return e.ID == equipid;
    });
    if ((equipment == undefined || equipment == null) && trackid > -1)
      equipment = self.EquipmentList().find(function (e) {
        return e._equipmentTrack == trackid;
      });
    return equipment;
  };

  self.NewNoteKeyup = function (data, event) {
    self.checkNoteHeight();

    if (event.which == 13 && self.PendingNote().Note().trim().length != 0) {
      self.PendingNote().Note(self.PendingNote().Note().trim());
      self.SaveAddNote();
      self.scrollNote();
      return false;
    }
    if (event.which == 13 && self.PendingNote().Note().trim().length == 0) {
      return false;
    }
    if (event.which == 27) {
      self.CancelAddNote();
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    return true;
  };

  self.EditNoteKeydown = function (data, event) {
    self.checkNoteHeight();
    if (event.which == 13 && data.Note().trim().length != 0) {
      data.Note(data.Note().trim());
      data.SaveEdit(self.NotesShowEquip, data);
      return false;
    }
    if (event.which == 13 && data.Note().trim().length == 0) {
      return false;
    }
    if (event.which == 27) {
      data.CancelEdit();
      return false;
    }
    return true;
  };

  self.checkNoteHeight = function () {
    var noteHeight = $('.noteHeight').height();
    var messageHeight = $('.messageArea').height();
    //$('.noteContainer').css('min-height', noteHeight - messageHeight + 'px');
    //$('.noteArea').css('min-height', noteHeight - messageHeight - 31 + 'px');

    //DJM Added the IF clause 3-22 to allow Notes to be as tall as necessary in AssignFreqs
    if ($('#freqAssign').length == 0) {
      $('.noteArea').css('min-height', 'inherit');
      $('.noteArea').css('max-height', noteHeight - messageHeight - 31 + 'px');
    }
  };

  self.scrollNote = function () {
    var allMessagesHeight = 0;

    $('.noteArea li')
      .nextUntil('')
      .each(function () {
        allMessagesHeight += $(this).height();
      });

    $('.noteArea').animate(
      {
        scrollTop: allMessagesHeight
      },
      'fast'
    );
  };

  self.SaveAddNote = function () {
    if (!self.PendingNote()) return false;

    for (let i = 0; i < self.RequestNotes().length; i++) {
      if (self.RequestNotes()[i].EditMode && self.RequestNotes()[i].Note().trim().length == 0) return false;
    }

    if (self.PendingNote().Note().trim().length > 0) {
      if (self.SelectedRequestEquipment() != undefined) {
        self.PendingNote().RequestEquipmentID(self.SelectedRequestEquipment().ID);
        self.PendingNote()._equipmentTrack(self.SelectedRequestEquipment()._equipmentTrack);
      }

      //DJM Addition for EFC-881
      if (self.RequestNotes().length === 0) {
        document.getElementById('noteArea0').style['maxHeight'] = 'unset';
      }
      self.RequestNotes.push(self.PendingNote());

      if (typeof self.SaveFunc == 'function') self.SaveFunc(self.PendingNote());

      self.PendingNote(null);
      self.NewNote();
      self.SelectedRequestEquipment(null);
    }
    return true;
  };

  self.CancelAddNote = function () {
    self.PendingNote(null);
    self.NewNote();
    if (self.NotesShowEquip) self.SelectedRequestEquipment(null);
  };

  self.RemoveNote = function (note) {
    self.RequestNotes.remove(note);
    if (note.RequestNoteID) {
      self.RemovedNoteIDs.push(note.RequestNoteID);
      if (typeof self.RemoveFunc == 'function') self.RemoveFunc(note.RequestNoteID, note.RequestEquipmentID() || 0);
    }
  };

  self.TemplateAfterRender = function (elements, note) {
    var $ctrl = $(elements).find('textarea');
    if ($ctrl.length > 0) $ctrl.first()[0].focus();

    $ctrl.closest('.messageInput').each(function () {
      autosize(this);
    });
    self.checkNoteHeight();
    //console.log('Called TemplateAfterRender');
    setTipsters();
    replaceOnClick();
  };
};

function setTipsters() {
  $('.tipster').tooltipster();
  console.log('assigned to ', $('.tipster'));
}

function replaceOnClick() {
  var parentNode = document.getElementById('testID1') ? document.getElementById('testID1') : null;
  if (parentNode !== null) {
    var dropdown = parentNode.getElementsByClassName('bootstrap-select').length > 0 ? parentNode.getElementsByClassName('bootstrap-select')[0] : null;
    if (dropdown === null) return;
    for (let m = 0; m < dropdown.attributes.length; m++) {
      if (dropdown.attributes[m].name === 'onClick') var z = true;
    }
    if (!z) dropdown.setAttribute('onclick', 'setCoords(this)');
  }
}

var RequestActivityVM = function (data, activity) {
  var self = this;

  if (data) {
    self.RequestActivityID = data.RequestActivityID;
    self.RequestID = data.RequestID;
    self.EventID = data.EventID;
    self.EventName = data.EventName;
    self.MainEventDate = data.MainEventDate;
    self.ActivityID = data.ActivityID;
    self.ActivityName = data.ActivityName;
    self.ActivityDescription = data.ActivityDescription;
    self.AllowPartTime = data.AllowPartTime;
    self.GameDrivenTime = data.GameDrivenTime;
    self.ActivityStartDateTime = data.ActivityStartDateTime;
    self.ActivityEndDateTime = data.ActivityEndDateTime;
    self.ActivitySingleDate = data.ActivitySingleDate;
    self.ActivityStartQtrID = data.ActivityStartQtrID;
    self.ActivityEndQtrID = data.ActivityEndQtrID;
    self.ActivityStartGameClock = data.ActivityStartGameClock;
    self.ActivityEndGameClock = data.ActivityEndGameClock;
    self.LocationID = data.LocationID;
    self.LocationName = data.LocationName;

    self.StartTime = ko.observable(data.StartTime);
    self.EndTime = ko.observable(data.EndTime);
    self.StartQtrID = ko.observable(data.StartQtrID);
    self.EndQtrID = ko.observable(data.EndQtrID);
    self.StartGameClock = ko.observable(data.StartGameClock);
    self.EndGameClock = ko.observable(data.EndGameClock);
  } else if (activity) {
    self.RequestActivityID = 0;
    self.RequestID = 0;
    self.EventID = activity.EventID;
    self.EventName = activity.EventName;
    self.MainEventDate = activity.MainEventDate;
    self.ActivityID = activity.ActivityID;
    self.ActivityName = activity.Name;
    self.ActivityDescription = activity.Description;
    self.AllowPartTime = activity.AllowPartTime;
    self.GameDrivenTime = activity.GameDrivenTime;
    self.ActivityStartDateTime = activity.StartDateTime;
    self.ActivityEndDateTime = activity.EndDateTime;
    self.ActivitySingleDate = activity.SingleDate;
    self.ActivityStartQtrID = activity.StartQtrID;
    self.ActivityEndQtrID = activity.EndQtrID;
    self.ActivityStartGameClock = activity.StartGameClock;
    self.ActivityEndGameClock = activity.EndGameClock;
    self.LocationID = activity.LocationID;
    self.LocationName = activity.LocationName;

    self.StartTime = ko.observable(activity.StartDateTime);
    self.EndTime = ko.observable(activity.EndDateTime);
    self.StartQtrID = ko.observable(activity.StartQtrID);
    self.EndQtrID = ko.observable(activity.EndQtrID);
    self.StartGameClock = ko.observable(activity.StartGameClock);
    self.EndGameClock = ko.observable(activity.EndGameClock);
  } else {
    self.RequestActivityID = 0;
    self.RequestID = 0;
    self.EventID = 0;
    self.EventName = '';
    self.MainEventDate = null;
    self.ActivityID = 0;
    self.ActivityName = 'Unknown Activity';
    self.ActivityDescription = 'Unknown Activity';
    self.AllowPartTime = false;
    self.GameDrivenTime = false;
    self.ActivityStartDateTime = null;
    self.ActivityEndDateTime = null;
    self.ActivitySingleDate = null;
    self.ActivityStartQtrID = 0;
    self.ActivityEndQtrID = 0;
    self.ActivityStartGameClock = null;
    self.ActivityEndGameClock = null;
    self.LocationID = 0;
    self.LocationName = 'Unknown Location';

    self.StartTime = ko.observable(null);
    self.EndTime = ko.observable(null);
    self.StartQtrID = ko.observable(0);
    self.EndQtrID = ko.observable(0);
    self.StartGameClock = ko.observable(null);
    self.EndGameClock = ko.observable(null);
  }

  self.AvailQuarters = ko.pureComputed(function () {
    return [
      {
        ID: 0,
        Name: 'Select'
      },
      {
        ID: 14,
        Name: 'Q1'
      },
      {
        ID: 13,
        Name: 'Q2'
      },
      {
        ID: 12,
        Name: 'Halftime'
      },
      {
        ID: 11,
        Name: 'Q3'
      },
      {
        ID: 10,
        Name: 'Q4'
      },
      {
        ID: 1,
        Name: 'Post'
      }
    ];
  });

  self.AvailMinutes = ko.pureComputed(function () {
    return [
      {
        time: '00:15:00',
        disp: '15:00'
      },
      {
        time: '00:14:00',
        disp: '14:00'
      },
      {
        time: '00:13:00',
        disp: '13:00'
      },
      {
        time: '00:12:00',
        disp: '12:00'
      },
      {
        time: '00:11:00',
        disp: '11:00'
      },
      {
        time: '00:10:00',
        disp: '10:00'
      },
      {
        time: '00:09:00',
        disp: '9:00'
      },
      {
        time: '00:08:00',
        disp: '8:00'
      },
      {
        time: '00:07:00',
        disp: '7:00'
      },
      {
        time: '00:06:00',
        disp: '6:00'
      },
      {
        time: '00:05:00',
        disp: '5:00'
      },
      {
        time: '00:04:00',
        disp: '4:00'
      },
      {
        time: '00:03:00',
        disp: '3:00'
      },
      {
        time: '00:02:00',
        disp: '2:00'
      },
      {
        time: '00:01:00',
        disp: '1:00'
      },
      {
        time: '00:00:00',
        disp: '0:00'
      }
    ];
  });

  self.StartGameTimeDisplay = ko.pureComputed(function () {
    return self.FormatDate(self.StartGameClock(), self.StartQtrID());
  });

  self.EndGameTimeDisplay = ko.pureComputed(function () {
    return self.FormatDate(self.EndGameClock(), self.EndQtrID());
  });

  self.FormatDate = function (clock, gameQtr) {
    if (!clock || !gameQtr) return '';
    var qtr = self.AvailQuarters().find(function (q) {
      return q.ID == gameQtr;
    });
    if (qtr) return qtr.Name + ' ' + moment().startOf('day').add(moment.duration(clock)).format('mm:ss');
    else return 'Not specified';
  };
};

var RequestEquipmentVM = function (data, _tracker) {
  var self = this;

  self.RequestEquipmentID = data.RequestEquipmentID || 0;
  self.RequestID = data.RequestID || 0;
  self.RequestAppUserID = data.RequestAppUserID || 0;
  self._equipmentTrack = _tracker || data._equipmentTrack || 0;
  self.EquipmentStatus = data.EquipmentStatus || 0;
  self.EquipmentStatusDescr = data.EquipmentStatusDescr || '';

  self.ModelID = ko.observable(data.ModelID || 0);
  self.FrequencyBlockID = ko.observable(data.FrequencyBlockID || null);
  self.NumPrimaryFreq = ko.observable(data.NumPrimaryFreq && data.NumPrimaryFreq > 0 ? data.NumPrimaryFreq : data.NumPrimaryFreq == 0 ? 0 : null);
  self.NumBackupFreq = ko.observable(data.NumBackupFreq || null);
  self.Fixed = ko.observable(data.Fixed || false);
  self.SignalTypeID = ko.observable(data.SignalTypeID || 0);
  self.EquipmentTypeID = ko.observable(data.EquipmentTypeID || 0);
  self.HowUsed = ko.observable(data.HowUsed || '');
  self.RequiredUseID = ko.observable(data.RequiredUseID || 0);
  self.FCC = ko.observable(data.FCC || false);
  self.CallSign = ko.observable(data.CallSign || '');
  self.PriorityBandwidth = ko.observable(data.PriorityBandwidth || null);
  self.AssignmentStarted = ko.observable(data.AssignmentStarted || false);
  self.AssignmentCompleted = ko.observable(data.AssignmentCompleted || false);

  self.ManufacturerID = ko.observable(data.ManufacturerID || 0);
  self.ManufacturerName = ko.observable(data.ManufacturerName || '');
  self.ModelName = ko.observable(data.ModelName || '');
  self.EquipmentTypeName = ko.observable(data.EquipmentTypeName || '');
  self.CanShowDetails = data.CanShowDetails != undefined ? data.CanShowDetails : true;
  self.IsEquipmentFromStatic = data.IsEquipmentFromStatic;
  if (self.IsEquipmentFromStatic) {
    self.Power = data.Power || null;
    self.Bandwidth = data.Bandwidth || null;
  }

  self.isStatusFullApproved = ko.pureComputed(function () {
    return self.EquipmentStatus == EquipmentStatus.FullApproved;
  });
  self.isStatusPartApproved = ko.pureComputed(function () {
    return self.EquipmentStatus == EquipmentStatus.PartApproved;
  });
  self.isStatusRejected = ko.pureComputed(function () {
    return self.EquipmentStatus == EquipmentStatus.Rejected;
  });
  self.isStatusPending = ko.pureComputed(function () {
    return self.EquipmentStatus == EquipmentStatus.Pending;
  });

  // Freq Available Check
  self.containsUnavailableFrequency = ko.observable(data.containsUnavailableFrequency || false);

  //Static Specific
  self.Description = ko.observable(data.Description || '');

  self.Frequencies = ko.observableArray(
    (data.Frequencies || []).map(function (f) {
      return new FrequencyVM(f, f.IsUnavailable);
    })
  );
  self.RequestNotes = ko.observableArray(
    (data.RequestNotes || []).map(function (x) {
      return new RequestNoteVM(x, self._equipmentTrack);
    })
  );

  self.HasUnavailableFrequencies = ko.pureComputed(function () {
    return self.Frequencies().some(function (f) {
      return f.IsUnavailable() == true;
    });
  });

  self.SignalTypeDescr = ko.pureComputed(function () {
    switch (self.SignalTypeID()) {
      case 1:
        return 'Analog';
      case 2:
        return 'Digital';
      case 3:
        return 'Both';
      case 4:
      default:
        return 'Unknown';
    }
  });

  self.EquipmentStatusIconClass = ko.pureComputed(function () {
    switch (self.EquipmentStatus) {
      case 0:
        return 'glyphicon glyphicon-inbox';
      case 1:
      case 2:
        return 'glyphicon glyphicon-ok-sign';
      case 3:
        return 'glyphicon glyphicon-ban-circle';
      default:
        return '';
    }
  });

  self.EquipmentStatusIconColor = ko.pureComputed(function () {
    switch (self.EquipmentStatus) {
      case 0:
        return 'grey';
      case 1:
        return 'green';
      case 2:
        return 'orange';
      case 3:
        return 'red';
      default:
        return 'grey';
    }
  });

  self.EquipmentStatusTooltip = ko.pureComputed(function () {
    switch (self.EquipmentStatus) {
      case 0:
        return 'Equipment Request Pending';
      case 1:
        return 'Equipment Request Coordinated';
      case 2:
        return 'Equipment Request Partially Coordinated';
      case 3:
        return 'Equipment Request Rejected';
      default:
        return '';
    }
  });

  self.AddFrequency = function (freq, freqPairValue, unavailable) {
    freq = Number(freq);
    if (isNaN(freq)) return;
    if (
      !self.Frequencies().find(function (f) {
        return f.Frequency() == freq;
      })
    ) {
      var newFreq = new FrequencyVM(freq, unavailable);
      self.Frequencies.push(newFreq);

      if (!freqPairValue) freqPairValue = Number(freqPairValue);

      if (freqPairValue && freqPairValue > 0) {
        var pairFreq = self.Frequencies().find(function (f) {
          return f.Frequency() == freqPairValue;
        });
        if (pairFreq) {
          newFreq.FrequencyPairWith = freqPairValue;
          pairFreq.FrequencyPairWith = freq;
        }
      }
    }
  };

  self.RemoveFrequency = function (freq) {
    var pairFreq;

    freq = Number(freq);
    if (isNaN(freq)) return;
    var exist = self.Frequencies().find(function (f) {
      return f.Frequency() == freq;
    });

    if (exist) {
      pairFreq = Number(exist.FrequencyPairWith);
      self.Frequencies.remove(exist);
    }
    return pairFreq;
  };
};

var FrequencyVM = function (data, unavailable) {
  var self = this;
  if (typeof data === 'number') {
    self.RequestEquipmentFrequencyID = 0;
    self.RequestEquipmentID = 0;
    self.Frequency = ko.observable(data);
    self.RequestFrequencyStatusID = ko.observable(0); // pending
    self.IsHidden = false;
    self.Notes = ko.observable('');
    self.FrequencyPairWith = null;
  } else {
    self.RequestEquipmentFrequencyID = data.RequestEquipmentFrequencyID || 0;
    self.RequestEquipmentID = data.RequestEquipmentID || 0;
    self.Frequency = ko.observable(data.Frequency || null);
    self.RequestFrequencyStatusID = ko.observable(data.RequestFrequencyStatusID || 0);
    self.IsHidden = data.IsHidden || false;
    self.Notes = ko.observable(data.Notes || '');
    self.FrequencyPairWith = data.FrequencyPairWith;
    self.HasConflict = ko.observable(data.HasConflict || false);
  }

  // Only applies to RequestEquipmentFrequencies
  self.IsUnavailable = ko.observable(unavailable || false);

  self.FrequencyDisplay = ko.pureComputed(function () {
    if (self.IsHidden) {
      return '*****';
    } else if (self.Frequency()) {
      return self.Frequency().toFixed(5);
    } else {
      return '';
    }
  });

  self.DisplayClass = ko.pureComputed(function () {
    switch (self.RequestFrequencyStatusID()) {
      case 0:
        return 'pending';
      case 1:
        return 'approved';
      case 2:
        return 'assigned';
      case 3:
        return 'rejected';
      case 4:
        return 'partapproved';
      case 5:
        return 'rejected';
      default:
        return '';
    }
  });

  self.IsAssigned = ko.pureComputed(function () {
    return [1, 2, 4].indexOf(self.RequestFrequencyStatusID()) > -1;
  });
  self.IsPending = ko.pureComputed(function () {
    return self.RequestFrequencyStatusID() == 0;
  });
  self.IsRejected = ko.pureComputed(function () {
    return self.RequestFrequencyStatusID() == 3;
  });
  self.CanAddNote = ko.pureComputed(function () {
    return !self.Notes() && self.IsAssigned();
  });
};

var OnsiteContactVM = function (data, _tracker) {
  var self = this;

  self.OnsiteContactID = data.OnsiteContactID || 0;
  self.AppUserID = data.AppUserID;
  self._contactTrack = _tracker || data._contactTrack || 0;
  self.CompanyID = ko.observable(data.CompanyID || null);
  self.FirstName = ko.observable(data.FirstName);
  self.LastName = ko.observable(data.LastName);
  self.Phone = ko.observable(data.Phone);
  self.NonUSPhone = ko.observable(data.NonUSPhone || false);
  self.Email = ko.observable(data.Email);
  self.SendEmails = ko.observable(data.SendEmails || false);
  self.Active = ko.observable(data.Active || true);

  self.isEdited = false;

  self.FullName = ko.pureComputed(function () {
    return self.LastName() + ', ' + self.FirstName();
  });
};

var RequestOnsiteContactVM = function (data) {
  var self = this;

  self.RequestOnsiteContactID = data.RequestOnsiteContactID || 0;
  self.RequestID = data.RequestID;
  self.OnsiteContactID = data.OnsiteContactID;
  self.SendEmails = ko.observable(data ? (ko.isObservable(data.SendEmails) ? data.SendEmails() : data.SendEmails) : false);
  self.FirstName = ko.observable(data ? (ko.isObservable(data.FirstName) ? data.FirstName() : data.FirstName) : '');
  self.LastName = ko.observable(data ? (ko.isObservable(data.LastName) ? data.LastName() : data.LastName) : '');
  self.Email = ko.observable(data ? (ko.isObservable(data.Email) ? data.Email() : data.Email) : '');
  self.Phone = ko.observable(data ? (ko.isObservable(data.Phone) ? data.Phone() : data.Phone) : '');

  self.FullName = ko.pureComputed(function () {
    return self.LastName() + ', ' + self.FirstName();
  });
};

var OnsiteContactAndRequestContactVM = function (data, _tracker) {
  var self = this;

  self._contactTrack = _tracker || data._contactTrack || 0;
  self.RequestOnsiteContact = new RequestOnsiteContactVM(data.RequestOnsiteContact);
  self.OnsiteContact = new OnsiteContactVM(data.OnsiteContact, self._contactTrack);
  self.IsSelected = ko.observable(data.IsSelected || false);
};

var RequestEmailVM = function (data) {
  var self = this;

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};
  self.ID = '';
  self.ShowCancelButton = false;
  self.EmailDialog = null;

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;
    if (params.id) self.ID = params.id;
    if (params.showCancelButton) self.ShowCancelButton = params.showCancelButton;

    self.load();
  };

  self.load = function () {
    ko.applyBindings(self, self.$container[0]);
    //$('#emailTo').height($('#emailTo').scrollHeight);
  };

  self.SendEmail = function (event) {
    var message = $('#customMessage').val();
    var footer = $('#customFooter').val();
    if (typeof self.okCallback == 'function') {
      self.okCallback(self.ID, message, footer);
    }
    self.$container.modal('hide');
  };
};

export {
  RequestVM,
  RequestNoteVM,
  RequestNoteListVM,
  RequestActivityVM,
  RequestEquipmentVM,
  RequestOnsiteContactVM,
  RequestEmailVM,
  OnsiteContactVM,
  OnsiteContactAndRequestContactVM
};
