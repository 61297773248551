var EventFrequencyReportVM = function () {
  var self = this;

  if (RazorShared.isDebug) console.log('EventFrequencyReportVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) {
      self.okCallback = params.okCallback;
    }

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);
  };

  self.Print = function () {
    if ($('.reportActivityCB:checked').length == 0) $('#reportActivityIDsErrror').show();
    else $('#reportActivityIDsErrror').hide();

    if (!self.$form.validate().form() || $('.reportActivityCB:checked').length == 0) return;

    var reportFormatID = $('#reportFormat input[type="radio"]:checked').val();

    self.$container.modal('hide');

    if (reportFormatID == 1) window.open(RazorShared.baseUrl + 'Reports/PrintEventFrequncyReport?' + self.$form.serialize(), '_blank');
    else if (reportFormatID == 2) window.location = RazorShared.baseUrl + 'Reports/PrintEventFrequncyReport?' + self.$form.serialize();
  };
};

export default EventFrequencyReportVM;
