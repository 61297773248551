import { DialogManager } from './Common.js';
import { PriorityBandwidthVM } from './AssignFrequencies.js';
import CompanySearchVM from './CompanySearch.js';
import EventFrequencyReportVM from './Report.js';
import { FreqBlockMergeVM } from './FrequencyBlockMgmt.js';
import UserAccountEditVM from './UserAccountEdit.js';
import UserEditVM from './UserEdit.js';
import ActivityAddEditVM from './ActivityAddEdit.js';
import AssignToActivitiesVM from './AssignToActivities.js';
import CompanyEditVM from './CompanyEdit.js';
import ContactEditVM from './OnsiteContactEdit.js';
import EquipmentTypeAddEditVM from './EquipmentTypeAddEdit.js';
import { EventAddEditVM } from './EventAddEdit.js';
import FrequencyBlockEditVM from './FrequencyBlockAddEdit.js';
import LocationAddEditVM from './LocationAddEdit.js';
import AreaAddEditVM from './AreaAddEdit.js';
import ManufacturerEditVM from './ManufacturerAddEdit.js';
import ModelEditVM from './EquipmentModelAddEdit.js';
import RequestEditVM from './RequestAddEdit.js';
import { RequestEmailVM } from './RequestViewModels.js';
import RequestEquipmentEditVM from './RequestEquipmentAddEdit.js';
import RequestNoteListEditVM from './RequestNoteListEdit.js';
import RequestOnsiteContactsDialogVM from './RequestOnsiteContactsDialog.js';
import StaticFrequencyAddEditVM from './StaticFrequencyAddEdit.js';
import StaticFrequencyContactAddEditVM from './StaticFrequencyContactAddEdit.js';
import StaticFrequencySetAddEditVM from './StaticFrequencySetAddEdit.js';
import TVStationEditVM from './TVStationAddEdit.js';
import WalkInEquipmentAddEditVM from './WalkInEquipmentAddEdit.js';
import WirelessSpectrumEditVM from './WirelessSpectrumAddEdit.js';

var DialogMgr = new DialogManager();

DialogMgr.LoadMyAccount = function () {
  DialogMgr.LoadModal(RazorShared.baseUrl + 'UserMgmt/GetMyAccount/', null, UserAccountEditVM, null);
};

DialogMgr.ResetPassword = function () {
  DialogMgr.LoadModal(RazorShared.baseUrl + 'UserMgmt/ForcePasswordReset/', null, UserAccountEditVM, null);
};

DialogMgr.UserAddEdit = function (appUserID, fullName, okCallback) {
  var params = {
    isNew: !appUserID,
    fullName: fullName,
    okCallback: okCallback
  };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'UserMgmt/AddEditUser/' + (appUserID ? appUserID : ''), null, UserEditVM, params);
};

// selectCallback called upon selection with parameters: companyID, addedCompanyName
DialogMgr.CompanySearch = function (selectCallback, returnCompanyObj) {
  var params = {
    returnCompanyObj: returnCompanyObj,
    okCallback: selectCallback
  };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'CompanyMgmt/GetSearch', null, CompanySearchVM, params);
};

// okCallback called on OK with param contact: OnsiteContactViewModel
DialogMgr.OnsiteContactEdit = function (onsiteContactID, title, okCallback) {
  var params = { title: title, okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'OnsiteContacts/EditContact/' + onsiteContactID, null, ContactEditVM, params);
};

// okCallback called on OK with param contact: OnsiteContactViewModel
DialogMgr.OnsiteContactAdd = function (appUserID, title, okCallback) {
  var params = { title: title, okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'OnsiteContacts/AddContact/', { appUserID: appUserID }, ContactEditVM, params);
};

// okCallback called on OK with parameters: companyID, companyName
DialogMgr.CompanyAddEdit = function (companyID, title, okCallback, mergeIDList, returnCompanyObj) {
  var params = {
    companyID: companyID,
    title: title,
    okCallback: okCallback,
    mergeIDList: mergeIDList,
    returnCompanyObj: returnCompanyObj
  };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'CompanyMgmt/AddEditCompanyShell/', null, CompanyEditVM, params);
};

// okCallback called with no params
DialogMgr.RequestEdit = function (requestID, title, okCallback) {
  var params = { title: title, okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'Request/EditRequest/' + requestID, null, RequestEditVM, params);
};

DialogMgr.RequestView = function (requestID, title) {
  var params = { title: title };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'Request/ViewRequest/' + requestID, null, RequestEditVM, params);
};

DialogMgr.RequestAdd = function (appUserID, title, okCallback) {
  DialogMgr._RequestAddBase(appUserID, 0, 0, 0, 0, title, okCallback);
};

DialogMgr.RequestAddAnother = function (appUserID, eventID, venueID, areaID, companyID, title, okCallback) {
  DialogMgr._RequestAddBase(appUserID, eventID, venueID, areaID, companyID, title, okCallback, true);
};

DialogMgr._RequestAddBase = function (appUserID, eventID, venueID, areaID, companyID, title, okCallback, addAnother) {
  var params = { title: title, okCallback: okCallback, addAnother: addAnother };
  DialogMgr.LoadModal(
    RazorShared.baseUrl + 'Request/AddRequest/',
    {
      appUserID: appUserID,
      eventID: eventID,
      venueID: venueID,
      areaID: areaID,
      companyID: companyID
    },
    RequestEditVM,
    params
  );
};

DialogMgr.RequestDuplicate = function (origRequestID, title, okCallback) {
  var params = { title: title, okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'Request/DuplicateRequest/' + origRequestID, null, RequestEditVM, params);
};

// okCallback called with no params
DialogMgr.RequestEquipmentEdit = function (equipID, okCallback, fromAssignFrequency) {
  var params = {
    okCallback: okCallback,
    isEdit: true,
    title: 'Edit Equipment',
    fromAssignFrequency: fromAssignFrequency
  };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'Request/EditRequestEquipment/' + equipID, null, RequestEquipmentEditVM, params);
};

DialogMgr.RequestEquipmentView = function (equipID) {
  var params = { isEdit: true, title: 'View Equipment' };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'Request/ViewRequestEquipment/' + equipID, null, RequestEquipmentEditVM, params);
};

// okCallback called with no params
DialogMgr.RequestEquipmentAdd = function (requestID, okCallback, fromAssignFrequency) {
  var params = {
    okCallback: okCallback,
    isEdit: false,
    title: 'Add Equipment',
    fromAssignFrequency: fromAssignFrequency
  };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'Request/AddRequestEquipment/', { requestID: requestID }, RequestEquipmentEditVM, params);
};

// okCallback on SaveToRequest with parameter RequestEquipmentVM
DialogMgr.ChildRequestEquipmentAddEdit = function (
  title,
  okCallback,
  jsonInitData,
  isEdit,
  requestActivities,
  checkForUnavailableFrequencies,
  disableChildEquipmentFrequencyEdit
) {
  var params = {
    title: title,
    okCallback: okCallback,
    isEdit: isEdit,
    jsonInitData: jsonInitData,
    selectedRequestActivities: requestActivities,
    checkForUnavailableFrequencies: checkForUnavailableFrequencies,
    disableChildEquipmentFrequencyEdit: disableChildEquipmentFrequencyEdit
  };

  DialogMgr.LoadModal(
    RazorShared.baseUrl + 'Request/AddEditChildEquipment/',
    { requestAppUserID: jsonInitData.RequestAppUserID },
    RequestEquipmentEditVM,
    params
  );
};

DialogMgr.RequestNoteList = function (requestID, title, okCallback) {
  var params = { title: title, okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'Request/EditRequestNotes/' + requestID, null, RequestNoteListEditVM, params);
};

DialogMgr.RequestEquipmentNoteList = function (requestEquipID, title, okCallback) {
  var params = { title: title, okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'Request/EditRequestEquipmentNotes/' + requestEquipID, null, RequestNoteListEditVM, params);
};

DialogMgr.AddEditRequestOnsiteContacts = function (requestID, title, okCallback) {
  var params = { title: title, okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'Request/AddEditRequestOnsiteContacts/' + requestID, null, RequestOnsiteContactsDialogVM, params);
};

DialogMgr.ChildAddOnsiteContact = function (title, okCallback, jsonInitData) {
  var params = {
    title: title,
    okCallback: okCallback,
    jsonInitData: jsonInitData
  };

  DialogMgr.LoadModal(RazorShared.baseUrl + 'OnsiteContacts/AddEditChildContact/', { AppUserID: jsonInitData.AppUserID }, ContactEditVM, params);
};

DialogMgr.ChildEditOnsiteContact = function (title, okCallback, jsonInitData) {
  var params = {
    title: title,
    okCallback: okCallback,
    jsonInitData: jsonInitData
  };

  DialogMgr.LoadModal(RazorShared.baseUrl + 'OnsiteContacts/AddEditChildContact/', { id: jsonInitData.OnsiteContactID }, ContactEditVM, params);
};

DialogMgr.ShowEmailMessageDialog = function (id, title, okCallback, showCancelButton) {
  var params = {
    id: id,
    title: title,
    okCallback: okCallback,
    showCancelButton: showCancelButton || false
  };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'AssignFrequencies/ShowEmailMessageDialog/' + id, null, RequestEmailVM, params);
};

// okCallback with param ManufacturerViewModel
DialogMgr.ManufacturerAddEdit = function (manufacturerID, okCallback, requestAppUserID, addAnother) {
  var params = {
    okCallback: okCallback,
    addAnother: addAnother,
    requestAppUserID: requestAppUserID
  };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'EquipmentModel/AddEditManufacturer/' + manufacturerID, null, ManufacturerEditVM, params);
};

DialogMgr.MergeManufacturers = function (title, okCallback, mergeIDList) {
  var params = {
    title: title,
    okCallback: okCallback,
    mergeIDList: mergeIDList
  };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'EquipmentModel/MergeManufacturersShell/', null, ManufacturerEditVM, params);
};

// okCallback with param ModelViewModel
DialogMgr.ModelEdit = function (modelID, okCallback) {
  var params = { okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'EquipmentModel/EditEquipmentModel/' + modelID, null, ModelEditVM, params);
};

DialogMgr.ModelAdd = function (okCallback, manufID, requestAppUserID, addAnother) {
  var params = {
    okCallback: okCallback,
    addAnother: addAnother,
    requestAppUserID: requestAppUserID
  };
  DialogMgr.LoadModal(
    RazorShared.baseUrl + 'EquipmentModel/AddEquipmentModel/',
    { manufID: manufID, requestAppUserID: requestAppUserID },
    ModelEditVM,
    params
  );
};

DialogMgr.ModelDuplicate = function (modelID, title, okCallback) {
  var params = { title: title, okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'EquipmentModel/DuplicateEquipmentModel/' + modelID, null, ModelEditVM, params);
};

DialogMgr.MergeModels = function (okCallback, mergeIDList) {
  var params = { okCallback: okCallback, mergeIDList: mergeIDList };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'EquipmentModel/MergeModelsShell/', null, ModelEditVM, params);
};

DialogMgr.EditPriorityBandwidth = function (procID, equipID, controllerName, defaultBW, okCallback) {
  var params = {
    procID: procID,
    equipID: equipID,
    controllerName: controllerName,
    defaultBW: defaultBW,
    okCallback: okCallback
  };
  var ctrlparams = { equipID: equipID, defaultBW: defaultBW };
  DialogMgr.LoadModal(RazorShared.baseUrl + controllerName + '/EditPriorityBandwidth', ctrlparams, PriorityBandwidthVM, params);
};

DialogMgr.ShowFrequencyListForData = function (freqBlockViewModel) {
  DialogMgr.LoadModalWithoutVM(RazorShared.baseUrl + 'FrequencyBlock/GetFrequencyList', {
    vm: freqBlockViewModel
  });
};

DialogMgr.ShowFrequencyListForBlock = function (frequencyBlockID) {
  DialogMgr.LoadModalWithoutVM(RazorShared.baseUrl + 'FrequencyBlock/GetFrequencyListForBlock', {
    blockID: frequencyBlockID
  });
};

DialogMgr.ShowFrequencyListForBlocks = function (frequencyBlockIDs) {
  DialogMgr.LoadModalWithoutVM(RazorShared.baseUrl + 'FrequencyBlock/GetFrequencyListForBlocks', {
    blockIDs: frequencyBlockIDs
  });
};

DialogMgr.MergeFrequencyBlocks = function (freqBlockIDs, okCallback) {
  DialogMgr.LoadModal(RazorShared.baseUrl + 'FrequencyBlock/GetFrequencyBlockMergeList', { freqBlockIDs: freqBlockIDs }, FreqBlockMergeVM, {
    mergeIDList: freqBlockIDs,
    okCallback: okCallback
  });
};

DialogMgr.FrequencyBlockAdd = function (okCallback, custType, custName) {
  // okCallback with object { ID, Name }
  DialogMgr.LoadModal(RazorShared.baseUrl + 'FrequencyBlock/AddFrequencyBlock', { custType: custType, custName: custName }, FrequencyBlockEditVM, {
    okCallback: okCallback
  });
};

DialogMgr.FrequencyBlockEdit = function (freqBlockID, okCallback) {
  // okCallback with object { ID, Name }
  DialogMgr.LoadModal(RazorShared.baseUrl + 'FrequencyBlock/EditFrequencyBlock/' + freqBlockID, null, FrequencyBlockEditVM, {
    okCallback: okCallback
  });
};

DialogMgr.FrequencyBlockClone = function (freqBlockID, okCallback) {
  // okCallback with object { ID, Name }
  DialogMgr.LoadModal(RazorShared.baseUrl + 'FrequencyBlock/DuplicateFrequencyBlock/' + freqBlockID, null, FrequencyBlockEditVM, {
    clone: true,
    okCallback: okCallback
  });
};

DialogMgr.StaticFrequencyAdd = function (staticFrequencySetID, okCallback) {
  var params = { okCallback: okCallback };
  DialogMgr.LoadModal(
    RazorShared.baseUrl + 'StaticFrequency/AddStaticFrequency/',
    { staticFrequencySetID: staticFrequencySetID },
    StaticFrequencyAddEditVM,
    params
  );
};

DialogMgr.StaticFrequencyDuplicate = function (staticFrequencyID, okCallback) {
  var params = { okCallback: okCallback, addAnother: true };
  DialogMgr.LoadModal(
    RazorShared.baseUrl + 'StaticFrequency/DuplicateStaticFrequency/',
    { staticFrequencyID: staticFrequencyID },
    StaticFrequencyAddEditVM,
    params
  );
};

DialogMgr.StaticFrequencyEdit = function (staticFrequencyID, okCallback) {
  var params = { okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'StaticFrequency/EditStaticFrequency/' + staticFrequencyID, null, StaticFrequencyAddEditVM, params);
};

DialogMgr.LocationAddEdit = function (locationID, okCallback) {
  var params = { locationID: locationID, okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'LocationMgmt/AddEditLocationShell/', null, LocationAddEditVM, params);
};

DialogMgr.EventAddEdit = function (eventID, okCallback) {
  var params = { okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'LocationMgmt/AddEditEvent/' + eventID, null, EventAddEditVM, params);
};

DialogMgr.AddActivity = function (jsonInitData, okCallback) {
  var params = {
    okCallback: okCallback,
    jsonInitData: {
      EventID: jsonInitData.eventID,
      EventDate: jsonInitData.eventDate
    }
  };
  DialogMgr.LoadModal(
    RazorShared.baseUrl + 'LocationMgmt/AddEditActivity/',
    { areaID: jsonInitData.areaID, eventID: jsonInitData.eventID },
    ActivityAddEditVM,
    params
  );
};

DialogMgr.EditActivity = function (areaID, jsonInitData, okCallback) {
  var params = { okCallback: okCallback, jsonInitData: jsonInitData };
  DialogMgr.LoadModal(
    RazorShared.baseUrl + 'LocationMgmt/AddEditActivity/',
    {
      areaID: areaID,
      eventID: jsonInitData.EventID,
      locationID: jsonInitData.LocationID
    },
    ActivityAddEditVM,
    params
  );
};

DialogMgr.DuplicateActivity = function (areaID, title, jsonInitData, okCallback) {
  jsonInitData.ActivityID = 0;
  jsonInitData.Name = '';
  var params = {
    title: title,
    okCallback: okCallback,
    jsonInitData: jsonInitData
  };
  DialogMgr.LoadModal(
    RazorShared.baseUrl + 'LocationMgmt/DuplicateActivity/',
    {
      areaID: areaID,
      eventID: jsonInitData.EventID,
      locationID: jsonInitData.LocationID
    },
    ActivityAddEditVM,
    params
  );
};

DialogMgr.AreaAddEdit = function (areaID, okCallback) {
  var params = { areaID: areaID, okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'LocationMgmt/AddEditArea/' + areaID, null, AreaAddEditVM, params);
};

DialogMgr.AddStaticFrequencyContact = function (title, appUserID, okCallback) {
  var params = { title: title, okCallback: okCallback };
  DialogMgr.LoadModal(
    RazorShared.baseUrl + 'StaticFrequency/AddStaticFrequencyContact/',
    { appUserID: appUserID },
    StaticFrequencyContactAddEditVM,
    params
  );
};

DialogMgr.EditStaticFrequencyContact = function (title, contactID, okCallback) {
  var params = { title: title, okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'StaticFrequency/EditStaticFrequencyContact/' + contactID, null, StaticFrequencyContactAddEditVM, params);
};

DialogMgr.AddEditStaticFrequencySet = function (id, okCallback) {
  var params = { okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'StaticFrequency/AddEditStaticFrequencySet/' + id, null, StaticFrequencySetAddEditVM, params);
};

DialogMgr.DuplicateStaticFrequencySet = function (staticFrequencySetID, okCallback) {
  DialogMgr.LoadModal(
    RazorShared.baseUrl + 'StaticFrequency/DuplicateStaticFrequencySet/' + staticFrequencySetID,
    null,
    StaticFrequencySetAddEditVM,
    { clone: true, okCallback: okCallback }
  );
};

DialogMgr.TVStationAddEdit = function (tvStationID, okCallback) {
  DialogMgr.LoadModal(RazorShared.baseUrl + 'TVWireless/AddEditTVStation/' + tvStationID, null, TVStationEditVM, { okCallback: okCallback });
};

DialogMgr.WirelessSpectrumAddEdit = function (specID, okCallback) {
  DialogMgr.LoadModal(RazorShared.baseUrl + 'TVWireless/AddEditWirelessSpectrum/' + specID, null, WirelessSpectrumEditVM, { okCallback: okCallback });
};

DialogMgr.AddEditEquipmentType = function (id, okCallback) {
  var params = { okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'EquipmentModel/AddEditEquipmentType/' + id, null, EquipmentTypeAddEditVM, params);
};

DialogMgr.ShowFrequencyReportOptions = function (id, title) {
  var params = { title: title };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'Reports/FrequencyReportOptions/' + id, null, EventFrequencyReportVM, params);
};

DialogMgr.AddWalkInEquipment = function (eventID, okCallback, defaultActivityID) {
  var params = { okCallback: okCallback, defaultActivityID: defaultActivityID };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'WalkInEquipment/AddWalkInEquipment/', { eventID: eventID }, WalkInEquipmentAddEditVM, params);
};

DialogMgr.EditWalkInEquipment = function (id, okCallback) {
  var params = { okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'WalkInEquipment/EditWalkInEquipment/' + id, null, WalkInEquipmentAddEditVM, params);
};

DialogMgr.AssignToActivities = function (id, okCallback) {
  var params = { okCallback: okCallback };
  DialogMgr.LoadModal(RazorShared.baseUrl + 'StaticFrequency/AssignToActivities/' + id, null, AssignToActivitiesVM, params);
};

export default DialogMgr;
