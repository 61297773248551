import * as signalR from '@microsoft/signalr';

const connection = new signalR.HubConnectionBuilder().withUrl('/EventFrequencyNotification').withAutomaticReconnect().build();

connection
  .start()
  .then(function () {
    connection.invoke('openEventFrequencyNotification').catch(function (err) {
      return console.error(err.toString());
    });
  })
  .catch(function (err) {
    return console.error(err.toString());
  });

connection.onreconnecting(() => {
  console.log('SignalR reconnecting');
});

connection.on('notifyOpened', function () {
  console.log('opened');
});

connection.on('eventFrequencyUpdated', function (eventID, frequency) {
  if (typeof RazorShared.EventFrequencies != 'undefined') RazorShared.EventFrequencies.eventFrequencyUpdated(eventID, frequency);
});

connection.on('eventFrequencyUpdatedMultiple', function (eventID, frequencies) {
  if (typeof RazorShared.EventFrequencies != 'undefined') RazorShared.EventFrequencies.eventFrequencyUpdatedMultiple(eventID, frequencies);
});

connection.on('spectrumScanUpdated', function (eventID, minFrequency, maxFrequency) {
  if (typeof RazorShared.EventFrequencies != 'undefined') RazorShared.EventFrequencies.spectrumScanUpdated(eventID, minFrequency, maxFrequency);
});

connection.on('spectrumScanRemovedForLocation', function (eventID) {
  if (typeof RazorShared.EventFrequencies != 'undefined') RazorShared.EventFrequencies.spectrumScanRemovedForLocation(eventID);
});
