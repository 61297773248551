import Cookies from 'js-cookie';
import moment from 'moment/min/moment-with-locales';

function detectOS() {
  //
  // DETECTS THE OPERATING SYSTEM AND ADDS A CLASS ACCORDINGLY
  //
  const osPlatform = navigator.platform;
  function platformUpdate(os) {
    $('html').addClass(os);
  }
  if (osPlatform === 'Win32') {
    platformUpdate('windows');
  } else if (osPlatform === 'MacIntel') {
    platformUpdate('apple');
  }

  if (Cookies.get('daylight') === 'true') {
    $('html').addClass('daylight');
  }
}

function scrollFix() {
  //DJM 12.13.17 for EFC-895
  var hasScroll = ko.observable('false');
  setTimeout(function () {
    adjustUISize();
  }, 100);
  $(window).resize(function () {
    adjustUISize();
  });
  function adjustUISize() {
    hasScroll($('#dashSearch').height() > $(document).height() - 90);
  }
  window.hasScroll = hasScroll;

  moment.locale(window.navigator.userLanguage || window.navigator.language);
}

function manufacturerIDSelect() {
  // DJM 10.23.17 - Sort of a dumb fix for EFC-827, but I'm having trouble detecting when Razor actually loads the element
  // DJM 4.08.19 - changed the function here because I thought it might be impacting EFC-1166.
  $('#ManufacturerID').on('rendered.bs.select', function () {
    setTimeout(function () {
      var c = $('#ManufacturerID');
      if (c.parent()) {
        c.parent().children()[0].focus();
      }
    }, 500);
  });
}

function enableDaylight() {
  $('.btn-daylight').click(function () {
    if (Cookies.get('daylight') === 'true') {
      Cookies.set('daylight', 'false');
      $('html').removeClass('daylight');
    } else {
      Cookies.set('daylight', 'true');
      $('html').addClass('daylight');
    }
  });
}

function execute() {
  detectOS();
  scrollFix();
  manufacturerIDSelect();
  enableDaylight();
}

export default execute;
