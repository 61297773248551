import Cookies from 'js-cookie';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var FreqBlockMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 50;
  self.loadedCount = 0;
  self.cookieName = 'efcFreqBlockSearch';
  var searchCookie = Cookies.getJSON(self.cookieName);
  self.FreqBlockSearch = new FreqBlockSearch(searchCookie || jsonData.FrequencyBlockSearch, self.defaultListCount);
  self.FreqBlockSearchHold = new FreqBlockSearch(searchCookie || jsonData.FrequencyBlockSearch, self.defaultListCount);

  self.Statuses = ko.observableArray(jsonData.Statuses || []);
  self.CustomTypes = ko.observableArray(jsonData.CustomTypes || []);
  self.$freqTokens = null;
  self.IncludesFrequencyVal = ko.observable(self.FreqBlockSearchHold.IncludesFrequency().join(', '));

  self.SetSort = function (sort) {
    if (self.FreqBlockSearch.SortBy == sort) {
      self.FreqBlockSearch.SortDesc = !self.FreqBlockSearch.SortDesc;
    } else {
      self.FreqBlockSearch.SortBy = sort;
      self.FreqBlockSearch.SortDesc = false;
    }
    self.LoadList();
  };

  self.ApplyFilters = function () {
    var temp = self.FreqBlockSearch;
    self.FreqBlockSearchHold.IncludesFrequency(self.IncludesFrequencyVal().length == 0 ? [] : self.IncludesFrequencyVal().split(', '));
    self.FreqBlockSearch = new FreqBlockSearch(ko.toJS(self.FreqBlockSearchHold), self.defaultListCount);
    self.FreqBlockSearch.SortBy = temp.SortBy;
    self.FreqBlockSearch.SortDesc = temp.SortDesc;
    self.LoadList();
  };

  self.LoadList = function () {
    $('#loadMessage').show();
    $('#sectFreqBlockList').load(
      RazorShared.baseUrl + 'FrequencyBlock/GetFrequencyBlockList',
      ko.toJS(self.FreqBlockSearch),
      function (response, status, xhr) {
        if (status == 'error')
          Common.Dialog('Frequency Block Management', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
        else self.loadedCount = $('#numLoaded').val();
        $('#loadMessage').hide();
      }
    );
    Cookies.set(self.cookieName, ko.toJS(self.FreqBlockSearch), {
      expires: 30
    });
  };

  self.ListMore = function () {
    if (self.FreqBlockSearch.RetrieveCount > self.loadedCount) return;
    self.FreqBlockSearch.RetrieveCount += self.defaultListCount;
    self.LoadList();
  };

  self.ClearSearch = function () {
    self.FreqBlockSearch.ClearSearch(self.defaultListCount);
    self.FreqBlockSearchHold.ClearSearch(self.defaultListCount);
    self.refreshTokens();
    self.IncludesFrequencyVal('');
    self.LoadList();
  };

  self.AddFreqBlock = function () {
    DialogMgr.FrequencyBlockAdd(self.LoadList);
  };

  self.EditFreqBlock = function (id, name) {
    DialogMgr.FrequencyBlockEdit(id, self.LoadList);
  };

  self.MergeFreqBlocks = function () {
    var selCB = $('#divFreqBlockList').find('input[type=checkbox].merge:checked');
    var freqBlockIDs = $.map(selCB.toArray(), function (c) {
      return c.attributes['data-freqblockid'].value;
    });
    if (freqBlockIDs.length < 2) {
      Common.Dialog('Merge Frequency Blocks', 'Please select 2 or more Frequency Blocks to merge');
      return;
    }
    DialogMgr.MergeFrequencyBlocks(freqBlockIDs, self.LoadList);
  };

  self.ActivateFreqBlock = function (id) {
    $.post(RazorShared.baseUrl + 'FrequencyBlock/ActivateFrequencyBlock/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error activating Frequency Block', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Activate Frequency Block', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.DeactivateFreqBlock = function (id) {
    $.post(RazorShared.baseUrl + 'FrequencyBlock/DeactivateFrequencyBlock/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error deactivating Frequency Block', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Deactivate Frequency Block', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.CloneFreqBlock = function (id, name) {
    DialogMgr.FrequencyBlockClone(id, self.LoadList);
  };

  self.ViewFreqBlock = function (id) {
    DialogMgr.ShowFrequencyListForBlock(id);
  };

  self.SetupFrequencyTokenField = function ($fld, isReadOnly) {
    self.$freqTokens = $fld;

    if (isReadOnly) {
      $fld.on('tokenfield:edittoken tokenfield:removetoken', function (event) {
        event.preventDefault();
        return false;
      });
    } else {
      $fld.on('tokenfield:createtoken tokenfield:edittoken', function (event) {
        if (!$.isNumeric(event.attrs.value)) {
          event.preventDefault();
          return false;
        }

        event.attrs.label = parseFloat(event.attrs.value).toFixed(5);

        // check for duplicate
        if (
          $(this)
            .tokenfield('getTokens')
            .find(function (token) {
              return token.label == event.attrs.label;
            })
        ) {
          event.preventDefault();
          return false;
        }

        return true;
      });

      $fld.on('tokenfield:createdtoken', function (e) {
        self.FreqBlockSearch.IncludesFrequency.push(e.attrs.value);
      });

      $fld.on('tokenfield:removedtoken', function (e) {
        self.FreqBlockSearch.IncludesFrequency.remove(e.attrs.value);
      });
    }

    $fld.tokenfield({
      createTokensOnBlur: true,
      delimiter: [',', ' ']
    });

    self.refreshTokens();
  };

  self.setupSearchToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayToggled';
    var cookie = Cookies.get(toggleCookieName);
    if (cookie) $fld.click();

    $fld.on('displayToggled', function () {
      if ($(this).hasClass('toggled')) Cookies.set(toggleCookieName, 'toggled');
      else Cookies.remove(toggleCookieName);
    });
  };

  self.refreshTokens = function () {
    if (self.$freqTokens)
      self.$freqTokens.tokenfield(
        'setTokens',
        self.FreqBlockSearch.IncludesFrequency().map(function (f) {
          return Number.parseFloat(f).toFixed(5);
        })
      );
  };
};

var FreqBlockSearch = function (jsonData, defaultCount) {
  var self = this;

  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
  self.RetrieveCount = defaultCount;
  self.RetrieveStartAt = jsonData.RetrieveStartAt || null;

  self.FrequencyBlockIDs = ko.observableArray(jsonData.FrequencyBlockIDs || []);
  self.NameLike = ko.observable(jsonData.NameLike || null);
  self.Statuses = ko.observableArray(jsonData.Statuses || [1, 3]);
  self.CustomTypes = ko.observableArray(jsonData.CustomTypes || []);
  self.HasDocument = ko.observable(jsonData.HasDocument || null);
  self.MinFrequencyCount = ko.observable(jsonData.MinFrequencyCount || null);
  self.MaxFrequencyCount = ko.observable(jsonData.MaxFrequencyCount || null);
  self.IncludesFrequency = ko.observableArray(jsonData.IncludesFrequency || []);

  self.ClearSearch = function (defaultCount) {
    if (defaultCount == undefined) return;
    self.FrequencyBlockIDs([]);
    self.NameLike(null);
    self.Statuses([1, 3]);
    self.CustomTypes([]);
    self.HasDocument(null);
    self.MinFrequencyCount(null);
    self.MaxFrequencyCount(null);
    self.IncludesFrequency([]);
    self.RetrieveCount = defaultCount;
    self.RetrieveStartAt = null;
  };
};

var FreqBlockMergeVM = function () {
  var self = this;
  self.$container = $(document);
  self.okCallback = function () {};

  self.SelectedMergeInto = 0;
  self.MergeIDList = [];

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) self.$container = $dlgcontainer;
    if (params.okCallback) self.okCallback = params.okCallback;

    if (params.mergeIDList) self.MergeIDList = params.mergeIDList;
  };

  self.SelectFreqBlock = function (id, control) {
    if (control) {
      self.$container.find('#divMerge article').removeClass('selected');
      if (control) $(control).addClass('selected');
    }
    self.SelectedMergeInto = id;
  };

  self.Merge = function () {
    if (!self.SelectedMergeInto) {
      Common.Dialog('Merge Frequency Blocks', 'Please select a Frequency Block.');
      return;
    }

    Common.DialogConfirmation(
      'Confirmation',
      'Are you sure you want to merge these Frequency Blocks into the selected one?',
      null,
      function () {
        self.ExecuteMerge();
      },
      null
    );
  };

  self.ExecuteMerge = function () {
    $.post(
      RazorShared.baseUrl + 'FrequencyBlock/MergeFrequencyBlocks',
      { mergeIntoID: self.SelectedMergeInto, mergeIDs: self.MergeIDList },
      function (data) {
        if (data.success) {
          self.$container.modal('hide');
          self.okCallback();
        } else {
          Common.Dialog('Error merging Frequency Blocks', null, data.error);
          return;
        }
      }
    ).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Merge Frequency Blocks', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

export { FreqBlockMgmtVM, FreqBlockMergeVM };
