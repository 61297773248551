import * as signalR from '@microsoft/signalr';

var DashboardNotificationHubVM = function () {
  var self = this;
  const connection = new signalR.HubConnectionBuilder().withUrl('/DashboardNotification').withAutomaticReconnect().build();

  connection
    .start()
    .then(function () {
      connection.invoke('openDashboardNotification').catch(function (err) {
        return console.error(err.toString());
      });
    })
    .catch(function (err) {
      return console.error(err.toString());
    });

  connection.onreconnecting(() => {
    console.log('SignalR reconnecting for Dashboard');
  });

  connection.onreconnected(() => {
    console.log('SignalR reconnected for Dashboard');
  });

  connection.on('updateDashboardRequest', function (requestID) {
    if (typeof RazorShared.Dashboard != 'undefined') RazorShared.Dashboard.UpdateRequest(requestID);
  });

  // Messages to the server
  self.updateDashboardRequest = function (requestID) {
    connection.invoke('updateDashboardRequest', requestID, connection.connectionId).catch(function (err) {
      return console.error(err.toString());
    });
  };
};

export default DashboardNotificationHubVM;
